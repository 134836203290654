import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import './i18n';

import loader from './assets/loader.json';
import Lottie from 'lottie-react';
import ScrollToTop from './components/ScrollToTop';

const client = new ApolloClient({
  uri: process.env.REACT_APP_MP_WEB_BFF,
  cache: new InMemoryCache(),
});

const APP = (
  <React.StrictMode>
    <React.Suspense fallback={<Lottie animationData={loader} loop={true} />}>
      <BrowserRouter>
        <ScrollToTop />
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>
);

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

if (rootElement && rootElement.hasChildNodes()) {
  console.log("Hydration Needed");
  
  // root.hydrate(APP, rootElement); // hydrate method was initially added to facilitate react-snap prerendering. But It is still left to generate sitemaps later by manually prerendering with react-snap.
}

root.render(APP);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
