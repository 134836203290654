import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import CattleCard from '../../components/CattleCard';
import loader from '../../assets/loader.json';
import Lottie from 'lottie-react';
import hamburger from '../../assets/hamburger-white.svg';
import DownloadMP360 from '../Home/sections/DownloadMP360';
import CattleFilter from '../../components/CattleFilter';
import GET_CATTLE from '../../gql/gql';
import HubLocations from '../../components/HubLocations';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import EmptyStateCattle from '../../components/EmptyStateCattle';
import CattleMobFilter from '../../components/CattleMobFilter';
import Breadcrumb from '../../components/Breadcrumb';

function BuyCattle() {
  const [filterFormState, setFilterFormState] = useState<any>({});
  const [mobileFilterState, setMobileFilterState] = useState<any>({
    milkFilter: 'milk',
    price: 'price',
    byayi: 'byayi',
    hub: 'hub',
    calving: 0,
  });
  const { t } = useTranslation();
  const [
    getCattle,
    { loading: cattleLoading, error: cattleError, data: cattleData },
  ] = useLazyQuery(GET_CATTLE);

  if (cattleError) {
    console.log(cattleError);
  }
  console.log("cattle data", cattleData)

  const clearFilter = async () => {
    const form = document.getElementById(
      'cattle-filter-form'
    ) as HTMLFormElement;

    form.reset();
    ///mobile
    setFilterFormState({});
    let updatedValue = {};
    updatedValue = {
      milkFilter: 'milk',
      price: 'price',
      calving: 0,
      byayi: 'byayi',
      hub: 'hub',
    };
    setMobileFilterState((mobileFilterState: any) => ({
      ...mobileFilterState,
      ...updatedValue,
    }));

    //getcattle
    getCattle({
      variables: {
        take: 500,
      },
    });
  };

  useEffect(() => {
    getCattle({
      variables: {
        take: 500,
      },
    });
  }, [getCattle]);

  //  const clearCurrentFilter = async () =>{

  //  }
  // Accordian Commented
  // useEffect(() => {
  //   le accordian = Array.from(
  //     document.getElementsByClassName(
  //       'cattle__filter--accordian'
  //     ) as HTMLCollectionOf<HTMLElement>
  //   );
  //   accordian.forEach((accord: any) => {
  //     accord.addEventListener('click', function (this: any) {
  //       this.classList.toggle('active');
  //       var panel = this.nextElementSibling;
  //       if (panel.style.display === 'block') {
  //         panel.style.display = 'none';
  //       } else {
  //         panel.style.display = 'block';
  //       }
  //     });
  //   });
  // }, []);

  const breadcrumbs = [
    { name: 'Home', url: '/' },
    { name: 'Buy Cattle', url: '/buy-cattle' },
  ];

  return (
    <>
    <Breadcrumb breadcrumbs={breadcrumbs} />
      <>
        <Helmet>
          <title>
            High quality buffaloes and cows
          </title>

          <meta
            name="description"
            content="View Many options of cows and buffaloes tested on 75+ parameters according to your preference. Visit the hub for the facility to check 3 times milking record."
          />
          <link rel="canonical" href="https://merapashu360.com/buy-cattle/" />
          <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://merapashu360.com/"
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "Buy Cattle",
              "item": "https://merapashu360.com/buy-cattle"
            }]
          })}
        </script>
        </Helmet>
        <div className="cattle">
          <div className="cattle__breadcrumb">
            <a href="/">{t('home')}</a>
            {' > '}
            <span> {t('buy_cattle')} </span>
          </div>

          <div className="cattle__container">
            <div className="cattle__filter-container">
              <div style={{position: "sticky",top: 0}}>
                <div className="cattle__filter--header">
                  <img src={hamburger} alt="Hamburger" />
                  <p>{t('apply_filter')}</p>
                </div>
                <CattleFilter
                  filterFormState={filterFormState}
                  setFilterFormState={setFilterFormState}
                  query={getCattle}
                />
              </div>
            </div>

            <div className="cattle__list-container">
              <section className="feed__category">
                <div
                  className="cattle__filter--applied"
                  id="cattleFilterLables"
                >
                  {Object.keys(filterFormState).length !== 0 && (
                    <>
                      <button
                        className="cattle__filter--clear"
                        onClick={clearFilter}
                      >
                        {t('clear_filter')}
                      </button>
                      {filterFormState.milkFilter ? (
                        <button
                          onClick={() => {
                            let data = filterFormState;
                            delete data.milkFilter;
                            setFilterFormState(data);
                            getCattle({
                              variables: data,
                            });

                            let updatedMilkValue = {};
                            updatedMilkValue = { milkFilter: 'milk' };
                            setMobileFilterState((mobileFilterState: any) => ({
                              ...mobileFilterState,
                              ...updatedMilkValue,
                            }));
                          }}
                          className="cattle__filter--tags"
                        >
                          {filterFormState.milkFilter?.minMilk} -{' '}
                          {filterFormState.milkFilter?.maxMilk}
                          {' ' + t('litre')}
                        </button>
                      ) : null}

                      {filterFormState.priceFilter ? (
                        <button
                          onClick={() => {
                            let data = filterFormState;
                            delete data.priceFilter;
                            setFilterFormState(data);
                            getCattle({
                              variables: data,
                            });
                            let updatedPriceValue = {};
                            updatedPriceValue = { price: 'price' };
                            setMobileFilterState((mobileFilterState: any) => ({
                              ...mobileFilterState,
                              ...updatedPriceValue,
                            }));
                          }}
                          className="cattle__filter--tags"
                        >
                          {filterFormState.priceFilter?.minPrice}
                          {' ₹'} - {filterFormState.priceFilter?.maxPrice}
                          {' ₹'}
                        </button>
                      ) : null}
                      {filterFormState.parturitionDaysFilter ? (
                        <button
                          onClick={() => {
                            let data = filterFormState;
                            delete data.parturitionDaysFilter;
                            setFilterFormState(data);
                            let updatedPasturitionValue = {};
                            updatedPasturitionValue = { byayi: 'byayi' };
                            setMobileFilterState((mobileFilterState: any) => ({
                              ...mobileFilterState,
                              ...updatedPasturitionValue,
                            }));
                            getCattle({
                              variables: data,
                            });
                          }}
                          className="cattle__filter--tags"
                        >
                          {
                            filterFormState.parturitionDaysFilter
                              ?.minParturitionDays
                          }
                          {' ' + t('day') + ' '}-{' '}
                          {
                            filterFormState.parturitionDaysFilter
                              ?.maxParturitionDays
                          }
                          {' ' + t('day')}
                        </button>
                      ) : null}
                      {filterFormState.hub?.map((_hub: string, index: any) => (
                        <button
                          key={index}
                          onClick={() => {
                            let data = filterFormState;
                            data.hub = data.hub.filter(
                              (e: string) => e !== _hub
                            );
                            setFilterFormState(data);
                            getCattle({
                              variables: data,
                            });
                          }}
                          className="cattle__filter--tags"
                        >
                          {_hub.split('-')[0]}
                        </button>
                      ))}
                      {filterFormState.calving ? (
                        <button
                          onClick={() => {
                            let data = filterFormState;
                            delete data.calving;
                            setFilterFormState(data);
                            let updatedCalvingValue = {};
                            updatedCalvingValue = { calving: 0 };
                            setMobileFilterState((mobileFilterState: any) => ({
                              ...mobileFilterState,
                              ...updatedCalvingValue,
                            }));
                            getCattle({
                              variables: data,
                            });
                          }}
                          className="cattle__filter--tags"
                        >
                          {filterFormState.calving} {' ' + t('calving')}
                        </button>
                      ) : null}
                    </>
                  )}
                </div>

                <div className="feed__mobilefilters">
                  <p className="feed__title"> {t('apply_filter')} </p>
                  <CattleMobFilter
                    filterFormState={filterFormState}
                    setFilterFormState={setFilterFormState}
                    mobileFilterState={mobileFilterState}
                    setMobileFilterState={setMobileFilterState}
                    query={getCattle}
                  />
                </div>
                {cattleLoading ? (
                  <Lottie animationData={loader} loop={true} />
                ) : (
                  <>
                    {Object.keys(filterFormState).length !== 0 &&
                    !cattleLoading &&
                    cattleData.animals.length === 0 ? (
                      <EmptyStateCattle clearFilter={clearFilter} />
                    ) : (
                      <>
                        {Object.keys(filterFormState).length === 0 ? (
                          <p className="feed__title">{t('all_cattle')}</p>
                        ) : null}
                        <div className="feed__list">
                          {!cattleLoading &&
                            cattleData &&
                            cattleData.animals &&
                            cattleData.animals.map((cattle: any) => {
                              return (
                                <CattleCard
                                  key={cattle.id}
                                  id={cattle.id}
                                  animalType={cattle.animalType}
                                  breed={cattle.breed}
                                  mrp={cattle.mrp.amount}
                                  price={cattle.price.amount}
                                  calfGender={
                                    cattle?.accompanyingChildren[0]?.gender
                                  }
                                  tag={cattle.tag}
                                  calving={cattle.pregnancyInfo.calving}
                                  fat={cattle.averageMilkQuality.fat}
                                  averageMilkYield={
                                    cattle.averageMilkYield.weight.value
                                  }
                                  expectedMilkYield={
                                    cattle.expectedMilkYield.weight.value
                                  }
                                  parturitionDate={cattle.parturitionDate}
                                  location={
                                    cattle.currentLocation.address.district
                                  }
                                  imageUrl={cattle.mediaRecords[0].url}
                                />
                              );
                            })}
                        </div>
                      </>
                    )}
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
        <HubLocations />
        <DownloadMP360 />
      </>
      {/* )} */}
    </>
  );
}

export default BuyCattle;
