import { useEffect, useState } from 'react';
import StepOneSYCF from '../components/StepOneSYCF';
import estimateImg from '../assets/estimate-value.svg';
import StepTwoSYCF from '../components/StepTwoSYCF';
import thumb from '../assets/thumbsup-outline.svg';
import cod from '../assets/cod.svg';
import truck from '../assets/truck-icon-v3.svg';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/Breadcrumb';
import { Helmet } from 'react-helmet-async';

function SYCForm() {
  let { t } = useTranslation();
  const [sycFormValues, setSycFormValues] = useState({
    animalType: 'BUFFALO',
    breed: 'Murrah',
    lactationNumber: 1,
    readyMilk: {
      value: '',
      uom: 'litre',
    },
    calfGender: 'MALE',
  });
  const [estimateData, setEstimateData] = useState<any>({});

  const [activeStep, setActiveStep] = useState(1);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // getCaptchaToken();

    setActiveStep(2);
  };

  const handleLoaded = (_: any) => {
    window.grecaptcha.ready((_: any) => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action: 'EstimateCattlePrice',
        })
        .then((token: any) => {});
    });
  };

  const getCaptchaToken = async () => {
    // Retreive Captcha Token
    await window.grecaptcha.ready((_: any) => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action: 'EstimateCattlePrice',
        })
        .then(async (token: any) => {
          await setSycFormValues((state: any) => {
            state['captchaToken'] = token;

            return state;
          });
        });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);


    // if ("geolocation" in navigator) {
    //   console.log("Navigator Available");
    //   navigator.permissions
    //   .query({name: "geolocation"})
    //   .then(function(result){
    //     console.log(result);
    //     if (result.state === "granted") {
    //       console.log("granted");
    //       navigator.geolocation.getCurrentPosition(function (position) {
    //         console.log("Latitude is :", position.coords.latitude);
    //         console.log("Longitude is :", position.coords.longitude);
    //       });
    //     } else if (result.state === "prompt") {
    //       console.log("prompt");
    //     } else if (result.state === "denied") {
    //       console.log("denied");
    //     }
    //   })
    // } else {
    //   console.log("Not Available");
    // }

  }, []);

  const breadcrumbs = [
    { name: 'Home', url: '/' },
    { name: 'Sell Cattle', url: '/sell-cattle' },
  ];

  return (
    <>
    <Breadcrumb breadcrumbs={breadcrumbs} />
    <Helmet>
      <link rel="canonical" href="https://merapashu360.com/sell-cattle/ " />
    </Helmet>
      {/* <Helmet>
        <title>
          अच्छी नस्ल की भैंस खरीदें | Buy & Sell Cattle Online | MeraPashu360
        </title>

        <meta
          name="description"
          content="MeraPashu360: अच्छी नस्ल की भैंस खरीदें ऑनलाइन | The fastest way to buy & sell dairy cattle online at best price. We offer a wide variety of cattle breeds"
        />
      </Helmet> */}
      <section className="sycf">
        <h1 className="sycf__title sycf__title--desktop">{t('syc_title')}</h1>
        <div className="sycf__feats">
          <h1 className="sycf__title">{t('syc_title')}</h1>
          {[
            {
              title: t('sycfeat1'),
              icon: cod,
            },
            {
              icon: thumb,
              title: t('sycfeat2'),
            },
            {
              title: t('sycfeat3'),
              icon: truck,
            },
          ].map((feat: any, index:number) => {
            return (
              <div className="sycf__feat" key={index}>
                <img src={feat.icon} alt="verified" />
                <p>{feat.title}</p>
              </div>
            );
          })}
        </div>

        <div className="sycf__form-container">
          <div className="sycf__steps-container">
            <div className="sycf__steps">
              <h2 className="sycf__steps--title">{t('syc_step_title')}</h2>

              <ul className="sycf__steps--step">
                <li
                  className={
                    activeStep === 1
                      ? 'sycf__step sycf__step--active'
                      : 'sycf__step'
                  }
                >
                  {t('syc_step_one')}
                </li>

                <li
                  className={
                    activeStep === 2 || activeStep === 3
                      ? 'sycf__step sycf__step--active'
                      : 'sycf__step'
                  }
                >
                  {' '}
                  {t('syc_step_two')}
                </li>
              </ul>
            </div>
          </div>
          <div className="sycf__form">
            {activeStep === 1 && (
              <StepOneSYCF
                sycFormValues={sycFormValues}
                setSycFormValues={setSycFormValues}
                setActiveStep={setActiveStep}
                handleSubmit={handleSubmit}
              />
            )}

            {activeStep === 2 && (
              <StepTwoSYCF
                sycFormValues={sycFormValues}
                setSycFormValues={setSycFormValues}
                setActiveStep={setActiveStep}
                setEstimateData={setEstimateData}
                getCaptchaToken={getCaptchaToken}
              />
            )}

            {activeStep === 3 && (
              <>
                <h2 className="sycf__form--title">
                  {t('estimated_price')}
                </h2>
                <div className="sycf__stepTwo">
                  <img
                    src={estimateImg}
                    alt="est icon"
                    className="sycf__estimated-value-img"
                  />

                  <p className="hindi">{t('estimated_price')}</p>

                  {estimateData && estimateData.lowerBound && (
                    <h1>
                      ₹{estimateData.lowerBound.amount} - ₹
                      {estimateData.upperBound.amount}
                    </h1>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default SYCForm;
