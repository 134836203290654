import { useTranslation } from 'react-i18next';
function CattleFilter({
  filterFormState,
  setFilterFormState,
  query,
}: {
  filterFormState: any;
  setFilterFormState: any;
  query: any;
}) {
  let { t } = useTranslation();

  //state
  //constants
  const milkFilterMap = {
    '6-9ltr': '6,9',
    '9-12ltr': '9,12',
    '12-15ltr': '12,15',
    '15-18ltr': '15,18',
    '18-20ltr': '18,20',
  };

  const priceFilterMap = {
    '50-65k': '50000,65000',
    '65-80k': '65000,80000',
    '80-95k': '80000,95000',
    '95-110k': '95000,110000',
    '110-125k': '110000,125000',
    '125-140k': '125000,140000',
  };

  const byayiFilter = {
    '1,15': '1,15',
    '16,25': '16,25',
    '26,45': '26,45',
    '46,75': '46,75',
  };

  const locatoinFilter = {
    gurgaon: 'GGN-HUB'
  };

  const handleFilterFormChange = async (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any;

    // let uiElement = document.getElementById(`${e.target.id}-container`);

    // let replicaElement = uiElement?.cloneNode(true);

    // // if (uiElement !== null) {
    // //   replicaElement?.setAttribute(
    // //     'id',
    // //     `${uiElement.id + '-replica-for-lable'}`
    // //   );
    // // }

    // let filterLabelContainer = document.getElementById('cattleFilterLables');

    // if (replicaElement !== undefined) {
    //   filterLabelContainer?.appendChild(replicaElement);
    // }
    // console.log(uiElement);

    if (name === 'milkFilter') {
      data = {
        minMilk: Number(value.split(',')[0]),
        maxMilk: Number(value.split(',')[1]),
      };
    } else if (name === 'priceFilter') {
      data = {
        minPrice: Number(value.split(',')[0]),
        maxPrice: Number(value.split(',')[1]),
      };
    } else if (name === 'calving') {
      data = Number(value);
    } else if (name === 'parturitionDaysFilter') {
      data = {
        minParturitionDays: Number(value.split(',')[0]),
        maxParturitionDays: Number(value.split(',')[1]),
      };
    } else if (name === 'hub') {
      if (filterFormState['hub'] !== undefined) {
        data = filterFormState.hub;
      } else {
        data = [];
      }

      if (!data.includes(value)) {
        data.push(value);
      } else {
        data = data.filter((e: string) => e !== value);
      }
    }

    let obj: any = {
      ...filterFormState,
    };

    obj[e.target.name] = data;

    setFilterFormState({
      ...filterFormState,
      ...obj,
    });

    window.scrollTo(0, 0);
    await query({
      variables: obj,
    });
  };

  return (
    <form
      id="cattle-filter-form"
      onChange={(e) => {
        handleFilterFormChange(e);
      }}
    >
      <button className="cattle__filter--accordian">{t('milk')}</button>
      <div className="cattle__filter--panel">
        <div className="cattle__filter--row">
          {Object.entries(milkFilterMap).map((item, index) => {
            let [key, value] = item;

            return (
              <div
                key={index}
                className={
                  index === 0
                    ? 'cattle__filter--panel--item '
                    : 'cattle__filter--panel--item'
                }
                id={`${key}-container`}
              >
                <label key={key}>
                  <input
                  onChange={()=>{}}
                    type="radio"
                    name={'milkFilter'}
                    value={value}
                    id={`${key}`}
                    checked={
                      filterFormState?.milkFilter?.minMilk ===
                      parseInt(value.split(',')[0])
                    }
                  />
                  {value.split(',')[0]} - {value.split(',')[1]} {t('litre')}
                </label>
              </div>
            );
          })}

          <div
            className="cattle__filter--panel--item"
            id={'25,100ltr-container'}
          >
            <label>
              <input
              onChange={()=>{}}
                type="radio"
                name="milkFilter"
                value={'25,100'}
                id={'25,100ltr'}
                checked={filterFormState?.milkFilter?.minMilk === 25}
              />
              {t('more_than_20_litre')}
            </label>
          </div>
        </div>
      </div>
      <button className="cattle__filter--accordian">{t('price')} (₹)</button>
      <div className="cattle__filter--panel">
        <div className="cattle__filter--col">
          {Object.entries(priceFilterMap).map((item, index) => {
            let [key, value] = item;

            return (
              <div
                key={index}
                className={
                  index === 0
                    ? 'cattle__filter--panel--item '
                    : 'cattle__filter--panel--item'
                }
                id={`${key}-container`}
              >
                <label key={key}>
                  <input
                  onChange={()=>{}}
                    type="radio"
                    name="priceFilter"
                    value={value}
                    id={`${key}`}
                    checked={
                      filterFormState?.priceFilter?.minPrice ===
                      parseInt(value.split(',')[0])
                    }
                  />
                  ₹{value.split(',')[0]} - ₹{value.split(',')[1]}
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <button className="cattle__filter--accordian">
        {t('byayi')} ({t('day')})
      </button>
      <div className="cattle__filter--panel">
        <div className="cattle__filter--col">
          {Object.entries(byayiFilter).map((item, index) => {
            let [key, value] = item;

            return (
              <div
                key={index}
                className={
                  index === 0
                    ? 'cattle__filter--panel--item '
                    : 'cattle__filter--panel--item'
                }
                id={`${value}-container`}
              >
                <label key={key}>
                  <input
                  onChange={()=>{}}
                    type="radio"
                    name="parturitionDaysFilter"
                    value={value}
                    id={`${value}`}
                    checked={
                      filterFormState?.parturitionDaysFilter
                        ?.minParturitionDays === parseInt(value.split(',')[0])
                    }
                  />
                  {value.split(',')[0]} - {value.split(',')[1]}
                </label>
              </div>
            );
          })}
          <div
            className={'cattle__filter--panel--item'}
            id={'75,1000-container'}
          >
            <label>
              <input
              onChange={()=>{}}
                type="radio"
                name="parturitionDaysFilter"
                value={'75, 1000'}
                id={'75,1000'}
                checked={
                  filterFormState?.parturitionDaysFilter?.minParturitionDays ===
                  75
                }
              />
              75+
            </label>
          </div>
        </div>
      </div>
      <button className="cattle__filter--accordian">{t('hub')}</button>
      <div className="cattle__filter--panel">
        <div className="cattle__filter--row">
          {Object.entries(locatoinFilter).map((item, index) => {
            let [key, value] = item;

            return (
              <div
                key={index}
                className={
                  index === 0
                    ? 'cattle__filter--panel--item '
                    : 'cattle__filter--panel--item'
                }
                id={`${key}-container`}
              >
                <label>
                  <input
                  onChange={()=>{}}
                    type="checkbox"
                    name="hub"
                    value={value}
                    id={`${key}`}
                    checked={filterFormState?.hub?.includes(value)}
                    // onClick={() => {
                    //   if (filterFormState?.hub?.includes(value)) {
                    //     let data = filterFormState;
                    //     data.hub = data.hub.filter((e: string) => e !== value);
                    //     setFilterFormState(data);
                    //     console.log(data);
                    //   }
                    // }}
                  />
                  {t(key)}
                </label>
              </div>
            );
          })}
        </div>
      </div>{' '}
      <button className="cattle__filter--accordian">{t('calving')}</button>
      <div className="cattle__filter--panel">
        <div className="cattle__filter--row">
          {[1, 2, 3, 4].map((item, index) => {
            return (
              <div
                key={index}
                className="cattle__filter--panel--item "
                id={`calving-${item}-container`}
              >
                <label>
                  <input
                  onChange={()=>{}}
                    type="radio"
                    name="calving"
                    value={item}
                    id={`calving-${item}`}
                    checked={filterFormState?.calving === item}
                  />
                  {item}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </form>
  );
}

export default CattleFilter;
