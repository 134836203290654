import './App.css';
import './sass/main.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import RoutesByLanguage from './Routes';

function App() {
  return (
    <HelmetProvider>
    <>
      <Helmet>
        <title>
          Buy Sell Cattle, भैंस ऑनलाइन, Pashu Aahar Online, Vet Services
        </title>

        <meta
          name="description"
          content={
            'Affordable Prices for Nutrient-Rich Premium Quality Cattle Feed and High-Quality Cows/Buffaloes on the App'
          }
        />
      </Helmet>
      <RoutesByLanguage />
    </>
    </HelmetProvider>
  );
}

export default App;