import { Helmet } from 'react-helmet-async';
import { PHONE_NUMBER } from '../constants';

function Cancellation() {
  return (
    <>
      <Helmet>
        <title>Refund and Cancellatin Policy | MeraPashu360</title>

        <meta
          name="description"
          content="MeraPashu 360 REFUND AND CANCELLATION POLICY."
        />
      </Helmet>
      <div className="terms--root">
        <h1 className="template-page--heading" data-item="section-heading">
          REFUND AND CANCELLATION POLICY
        </h1>

        <div
          style={{
            marginTop: '5vh',
          }}
          data-item="rte-content"
        >
          <p>
            1. At MeraPashu360, Our motto is:
            <strong>
              <span>&nbsp;</span>Swastha Pashu, Samruddh Kissan
            </strong>
          </p>
          <p>
            2. In the event, You are displeased with Our products or services,
            We will, at Our sole discretion, refund Your money if the reasons
            provided by You are genuine to Our satisfaction and proven after
            investigation. In case of dissatisfaction with Our services, You
            have the liberty to cancel the orders and request a refund from Us.
            Our Policy for the cancellation and refund will be as follows:
          </p>
          <p>
            2.1. You may cancel Your order free of charge up to 1 (one) day
            before the order is dispatched for delivery. You will be refunded
            the amount paid for such an order, if any. Processing of the refund
            may take up to 14 (fourteen) working days.
          </p>
          <p>
            2.2. Your refund will be made to the same account from which the
            payment was originally made.
          </p>
          <p>
            2.3. For ‘Cash on Delivery’ products, no refund will be provided.
          </p>
          <p>
            2.4 To initiate the refund, please contact our customer care at our
            customer care number
            <strong>
              <span>&nbsp;</span>{PHONE_NUMBER}
            </strong>
          </p>
        </div>
      </div>
    </>
  );
}

export default Cancellation;
