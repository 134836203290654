import Lottie from "lottie-react";
import loader from '../assets/loader.json';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const FullBlogPage = () => {
  const { id } = useParams();
  const [posts, setPosts] = useState<any>();
  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/blogger/v3/blogs/5147180660044942768/posts/${id}?key=${process.env.REACT_APP_BLOG_API_KEY}`
        );
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBlogPost();
  }, [id]);

  return (
    <div>
      {!posts && 
      <Lottie animationData={loader} loop={true} />
      }
      {posts && (
        <>
      <div className="blog__breadcrumb">
        <a href="/blog">{'< Back'}</a>
      </div>
        <section className="full_blog">
          <h1>{posts.title}</h1>
          <p>
            <div className="blog-content" dangerouslySetInnerHTML={{ __html: posts.content }}></div>
          </p>
        </section>
        </>
      )}
    </div>
  );
};

export default FullBlogPage;
