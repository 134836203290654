import React from 'react';
import { useTranslation } from 'react-i18next';
import impact1 from "../assets/impact1.svg";
import impact2 from "../assets/impact2.svg";
import impact3 from "../assets/impact3.svg";
import impact4 from "../assets/impact4.svg";

const impactsData = [
  {
    percentage: "impact_text1",
    text: "impact_sub1",
    image: impact1
  },
  {
    percentage: "impact_text2",
    text: "impact_sub2",
    image: impact2
  },
  {
    percentage: "impact_text3",
    text: "impact_sub3",
    image: impact3
  },
  {
    percentage: "impact_text4",
    text: "impact_sub4",
    image: impact4
  }
];


function ImpactSection() {
  const { t } = useTranslation();

  return (
    <section className="impactLayout">
      <div className="impactPage">
        <div className="impactPage__container">
          <div className="impactPage__content">
            <p className="impactPage__mainSubheading white">{t('impact_heading')}</p>
            <p className="impactPage__heading white">{t('impact_subheading')}</p>
            <div className="impact-cards">
              {impactsData.map((impact, index) => (
                <div className="impact-card" key={index}>
                    
                  <p className="impact-percentage">{t(impact.percentage)}</p>
                  <p className="impact-text" dangerouslySetInnerHTML={{ __html: t(impact.text) as string }}></p>
                  <div className="impact-image-wrapper">
                    <img src={impact.image} alt="impact" />
                  </div>
                  
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ImpactSection;
