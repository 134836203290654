import Button from './Button/Button';

import ggn from '../assets/Gurgaon-Hub.webp';
import { useTranslation } from 'react-i18next';

type HubCardProp = {
  image: string;

  name: string;

  address: string;

  mapLink: string;
};

function HubCard({ image, name, address, mapLink }: HubCardProp) {
  let { t } = useTranslation();
  return (
    <div className="hub__locations--card">
      <img src={image} alt="" className="hub__locations--card--image" />

      <div className="hub__locations--card--details">
        <h1>{name}</h1>
        {/* <div className="hub__locations--card--available">
          32 Murrah Bhains Available
        </div> */}
        <p>{address}</p>
        <div className="hub__locations--card--map-link">
          <a href={mapLink} target="_blank" rel="noreferrer">
            {t('view_on_map')}
          </a>
        </div>
        <Button
          outline
          color="#034EA2"
          text={t('see_all_bhains')}
          to="/buy-cattle"
        />
      </div>
    </div>
  );
}

// export default HubCard;

function HubLocations() {
  let { t } = useTranslation();

  return (
    <>
      <div className="hub__locations">
        <div className="subheading">More</div>
        <div className="heading">Hub Locations</div>
        <div className="hub__locations--cards">
          <HubCard
            name={`${t('gurgaon')}  ${t('hub')}`}
            image={ggn}
            address={t('address')}
            mapLink="https://maps.app.goo.gl/XNrQe4SzvmRipxMi8"
          />
        </div>
      </div>
    </>
  );
}

export default HubLocations;
