import Button from '../../../components/Button/Button';
import kisanServed from '../../../assets/kisanServed.svg';
import pincodeServed from '../../../assets/villDelivered.svg';
import katteSold from '../../../assets/katteSold.svg';
import buffaloSold from '../../../assets/buffaloSold.svg';
import { useTranslation } from 'react-i18next';

type AboutUsProp = {
  sub: string;
  heading: string;
  desc: string;
  watchFilm?: boolean;
  stats: {
    kisanServed: string;
    pincodeServed: string;
    katteSold: string;
    appDownloads: string;
    buffaloSold: string;
  };
};

function AboutUs({ sub, heading, desc, watchFilm, stats }: AboutUsProp) {
  let { t } = useTranslation();
  let statsInfo = [
    {
      id: 'kisanServed',
      iconPath: kisanServed,
      text: 'kisan_served',
    },
    {
      id: 'pincodeServed',
      iconPath: pincodeServed,
      text: 'pin_code_served',
    },
    {
      id: 'katteSold',
      iconPath: katteSold,
      text: 'katte_sold',
    },
    {
      id: 'buffaloSold',
      iconPath: buffaloSold,
      text: 'buffalo_sold',
    },
  ];

  let StatsCard = (data: { id: string; iconPath: string; text: string, key: number }) => {
    return (
      <div className="about_us__card" key={data.key}>
        <img
          src={data.iconPath}
          alt="Download"
          className="about_us__card--icon"
        />
        <p className="about_us__card--text">{t(data.text)}</p>
        <p className="about_us__card--number green">
          {data.id === 'kisanServed' && stats['kisanServed']}
          {data.id === 'katteSold' && stats['katteSold']}
          {data.id === 'buffaloSold' && stats['buffaloSold']}
          {data.id === 'pincodeServed' && stats['pincodeServed']}
        </p>
      </div>
    );
  };

  return (
    <>
      <section className="about_us">
        <div className="about_us__container">
          <div className="about_us__content">
            <p className="subheading white">{sub}</p>
            <p className="heading white">{heading}</p>

            <p className="about_us__para white">{desc}</p>
            {watchFilm ? (
              ''
            ) : (
              <Button
                outline
                text={t('know_more')}
                rightArrow
                color="#ffffff"
                to="/about-us"
              />
            )}
          </div>
          <div className="about_us__media">
            {/* <img src={whoRV} alt="" /> */}

            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/ZQaZuRhPan4"
              title="Mission Video"
            ></iframe>
          </div>
        </div>
      </section>
      <div className="about_us__stats">
        {statsInfo.map((stat: any, index:number) => 
        StatsCard({...stat, key: index})
        )}
      </div>
    </>
  );
}

export default AboutUs;
