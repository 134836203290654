import en from './en';
import hi from './hi';
import pa from './pa';

const languages = {
  en: en,
  hi: hi,
  pa: pa,
};

export default languages;
