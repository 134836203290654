import Button from './Button/Button';
import { useTranslation } from 'react-i18next';

function StepOneSYCF({
  sycFormValues,
  setSycFormValues,
  setActiveStep,
  handleSubmit,
}: {
  sycFormValues: any;
  setSycFormValues: any;
  setActiveStep: any;
  handleSubmit: any;
}) {
  let { t } = useTranslation();
  return (
    <>
      <h2 className="sycf__form--title">{t('syc_basic_detail')}</h2>
      <div className="sycf__stepOne">
        <form aria-label="SYC form one" name="SycForm" onSubmit={handleSubmit}>
          <label htmlFor="animalType">
            {t('syc_type')}
            <label className="sycf__select">
              <input
                type="radio"
                name="animalType"
                value={sycFormValues.animalType}
                checked={sycFormValues.animalType === 'BUFFALO'}
                onChange={(event) =>
                  setSycFormValues({
                    ...sycFormValues,
                    animalType: event.target.value,
                  })
                }
              />
              {t('syc_breed_buffalo')}
            </label>
          </label>

          <div className="sycf__input-flex-container">
            <div className="sycf__label-input">
              <label htmlFor="breed">
                {t('syc_breed')}
                <select
                  name="breed"
                  id="breed"
                  value={sycFormValues.breed}
                  onChange={(event) => {
                    setSycFormValues({
                      ...sycFormValues,
                      breed: event.target.value,
                    });
                  }}
                >
                  {[{ label: 'मुर्रा', value: 'Murrah' }].map((breed: any) => {
                    return (
                      <option key={breed.value} value={breed.value}>
                        {breed.label}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div className="sycf__label-input">
              <label htmlFor="byat">
                {t('syc_byat')}
                <select
                  name="byat"
                  id="byat"
                  required
                  value={sycFormValues.lactationNumber}
                  onChange={(event) => {
                    setSycFormValues({
                      ...sycFormValues,
                      lactationNumber: Number(event.target.value),
                    });
                  }}
                >
                  {[1, 2, 3, 4].map((byat: any) => {
                    return (
                      <option key={byat} value={byat}>
                        {byat}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>

            <div className="sycf__label-input">
              <label htmlFor="milk">
                {t('syc_milk')}
                <input
                  name="milk"
                  placeholder={`00 ${t('litre')}`}
                  required
                  value={sycFormValues.readyMilk.value}
                  onChange={(event) => {
                    // Validate if input is a number and less than 31 litres.
                    if (
                      !isNaN(Number(event.target.value)) &&
                      parseFloat(event.target.value) < 31
                    )
                      setSycFormValues({
                        ...sycFormValues,
                        readyMilk: {
                          ...sycFormValues.readyMilk,
                          value: event.target.value,
                        },
                      });
                    else if (event.target.value === '') {
                      setSycFormValues({
                        ...sycFormValues,
                        readyMilk: {
                          ...sycFormValues.readyMilk,
                          value: '',
                        },
                      });
                    }
                  }}
                />
              </label>
            </div>

            <div className="sycf__label-input">
              <label htmlFor="byayi">
                {t('syc_byayi')}
                <input
                  name="byayi"
                  id="byayi"
                  required
                  placeholder={`00 ${t('day')}`}
                  value={sycFormValues.daysSinceLastParturition}
                  onChange={(event) => {
                    let daysValue = event.target.value.replace(/[^0-9]/g, '');
                    if (!isNaN(Number(daysValue)) && Number(daysValue) < 101 && Number(daysValue) > 0){
                      setSycFormValues({
                        ...sycFormValues,
                        daysSinceLastParturition: Number(daysValue),
                      });
                    } else if (daysValue === '') {
                        setSycFormValues({
                          ...sycFormValues,
                          daysSinceLastParturition: '',
                        });
                      }
                  }}
                />
              </label>
            </div>
          </div>
          <label htmlFor="animalType">
            {t('syc_child')}
            <div className="sycf__gender">
              {[
                { label: t('katda'), value: 'MALE' },
                {
                  label: t('katdi'),
                  value: 'FEMALE',
                },
                {
                  label: t('none'),
                  value: 'na',
                },
              ].map((gender: any, index: number) => {
                return (
                  <label className="sycf__select" key={index}>
                    <input
                      type="radio"
                      name="childGender"
                      value={gender.value}
                      required
                      onChange={(event) => {
                        if (event.target.value !== 'na')
                          setSycFormValues({
                            ...sycFormValues,
                            calfGender: event.target.value,
                          });
                        else {
                          delete sycFormValues.calfGender;

                          setSycFormValues({
                            ...sycFormValues,
                          });
                        }
                      }}
                    />
                    {gender.label}
                  </label>
                );
              })}
            </div>
          </label>

          <div className="sycf__label-input">
            <label htmlFor="height">
              {t('syc_height')}
              <input
                name="height"
                id="height"
                value={sycFormValues.heightInInches}
                onChange={(event) => { 
                  let heightValue = event.target.value.replace(/[^0-9]/g, '');
                  // validate if Input is a valid number and is less than 64.
                  if (!isNaN(Number(heightValue)) && parseFloat(heightValue) < 64)
                    setSycFormValues({
                      ...sycFormValues,
                      heightInInches: Number(heightValue),
                    });
                  else if (heightValue === '') {
                    setSycFormValues({
                      ...sycFormValues,
                      heightInInches: '',
                    });
                  }
                }}
                placeholder={`00 ${t('inch')}`}
              />
            </label>
                        <label htmlFor="pincode">
              {t('syc_pincode')}
              <input
                name="pincode"
                id="pincode"
                minLength={6}
                min={10000}
                required
                value={sycFormValues.pincode}
                onChange={(event) => {
                  let pincodeValue = event.target.value.replace(/[^0-9]/g, '');
                  if ( !isNaN(Number(pincodeValue)) && Number(pincodeValue) < 999999 && Number(pincodeValue) > 0 ){
                    setSycFormValues({
                      ...sycFormValues,
                      pincode: Number(pincodeValue),
                    });
                  }else if (pincodeValue === '') {
                    setSycFormValues({
                      ...sycFormValues,
                      pincode: '',
                    });
                  }
                }}
                placeholder="122010"
              />
            </label>
          </div>

          <div className="sycf__submit-button">
            <Button primary rightArrow text={t('next')} />
            {/* <button onClick={() => setActiveStep(2)}>Next</button> */}
          </div>
        </form>
      </div>
    </>
  );
}

export default StepOneSYCF;
