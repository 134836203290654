import G_PLAY_ICON from '../../assets/Google_Play_Icon.svg';
import rightArrowIcon from '../../assets/right_arrow.svg';
import rightArrowIconBlue from '../../assets/right_arrow_blue.svg';
import playGreenIcon from '../../assets/play_green.svg';

type ButtonProp = {
  text: string;
  playStore?: boolean;
  rightArrow?: boolean;
  secondary?: boolean;
  className?: string;
  outline?: boolean;
  primary?: boolean;
  color?: string;
  to?: string;
  blueArrow?: boolean;
  disabled?: boolean;
};

function Button({
  text,
  playStore = false,
  rightArrow = false,
  secondary = false,
  className,
  outline,
  primary,
  color,
  to,
  blueArrow,
  disabled
}: ButtonProp) {
  return (
    <>
      <a style={{width: '-webkit-fill-available'}}  href={playStore ? (to ? to : 'https://mp360.app/web') : to} target={ playStore ? "_blank" : "_self" } rel="noopener noreferrer">
        <button
          className={className ? 'button ' + className : 'button'}
          disabled={disabled}
        >
          {primary && (
            <div
              className={
                playStore
                  ? 'button__primary button__primary--play'
                  : 'button__primary'
              }
            >
              {playStore && (
                <img
                  src={G_PLAY_ICON}
                  alt="Google Play Icon"
                  className="button__icon"
                />
              )}

              <p className="button__text">{text}</p>

              {rightArrow && (
                <img
                  src={rightArrowIcon}
                  alt="Right Arrow Icon"
                  className="button__icon--right"
                />
              )}
            </div>
          )}

          {outline && (
            <div
              className="button__outline"
              style={{
                border: `2px solid ${color}`,
              }}
            >
              <p
                className="button__text"
                style={{
                  color: color,
                }}
              >
                {text}
              </p>
              {rightArrow && (
                <img
                  src={blueArrow ? rightArrowIconBlue : rightArrowIcon}
                  alt="Right Arrow Icon"
                  className="button__icon--right"
                />
              )}
            </div>
          )}

          {secondary && (
            <div className="button__secondary">
              <p className="button__secondary--text">{text}</p>

              <img
                src={playGreenIcon}
                alt="Play Green Icon"
                className="button__secondary--icon"
              />
            </div>
          )}
        </button>
      </a>
    </>
  );
}

export default Button;
