function TermsReferral() {
  return (
    <div className="terms--root">
      <h1 className="template-page--heading" data-item="section-heading">
        MP360 Referral - Terms and Conditions
      </h1>

      <div
        data-item="rte-content"
        style={{
          marginTop: '2rem',
        }}
      >
        <h2 style={{ textAlign: 'left' }}>English</h2>
        <p>
          1. The phone number you refer must be a valid Indian phone number.
        </p>
        <p>2. The phone number you refer must have a valid WhatsApp account.</p>

        <p>
          3. By referring your friend - you give us consent that we can contact
          you by means of call, message and whatsapp message for verification.
        </p>

        <p>
          4. Referral reward will be credited to your wallet only after
          verification of the phone number.
        </p>

        <p>
          5. In case of any dispute, the decision taken by MeraPashu360 will be
          final.
        </p>

        <p>
          6. In case a phone number cannot be verified, we reserve the right to
          cancel the referral amount and reverse the transaction.
        </p>

        <p>
          7. In case of fake numbers / fake referrals, the company reserves the
          right to cancel the sales order or even block the account permanently.
        </p>

        <p>
          8. A referral will be counted as a valid referral only if the referee
          installs and uses the MeraPashu360 app for atleast one day.
        </p>

        <p>
          9. The referrals points shall be rewarded only to those users who
          install the latest version of app.
        </p>

        <p>
          10. Users whose referral customers place order with us, will get
          higher referral bonus reward.
        </p>

        <p>
          11. Users whose referral customer do not place order, or uninstall
          within one day will get less referral bonus reward.
        </p>

        <p>
          12. If a referral customer places order, then referral order bonus
          will be awarded fully.
        </p>

        <p>
          13. Users whose referrals do not place any orders will be marked as
          spammers.
        </p>

        <p>
          14. The company reserves the right to block or reward less points to
          spam users.
        </p>

        <p>
          15. The company reserves the right to change the referral amount
          without prior notice.
        </p>

        <h2 style={{ textAlign: 'left', marginTop: '3rem' }}>Hindi</h2>
        <p className="hindi">
          1. आपके द्वारा भेजा गया फ़ोन नंबर एक मान्य भारतीय फ़ोन नंबर होना
          चाहिए।
        </p>
        <p className="hindi">
          2. आप जिस फ़ोन नंबर का जिक्र कर रहे हैं उसका एक मान्य व्हाट्सएप खाता
          होना चाहिए।
        </p>
        <p className="hindi">
          3. अपने मित्र को रेफर करके - आप हमें सहमति देते हैं कि हम पुष्टि के
          लिए कॉल, संदेश और व्हाट्सएप संदेश के माध्यम से आपसे संपर्क कर सकते
          हैं।
        </p>
        <p className="hindi">
          4. फोन नंबर की पुष्टि के बाद ही रेफरल इनाम आपके वॉलेट में जमा किया
          जाएगा।
        </p>
        <p className="hindi">
          5. किसी भी मतभेद की स्थिति में मेरापशु360 द्वारा लिया गया निर्णय अंतिम
          होगा।
        </p>
        <p className="hindi">
          6. अगर फोन नंबर की पुष्टि नहीं होती है , तो हम रेफरल राशि को रद्द करने
          और लेनदेन को उलटने का अधिकार सुरक्षित रखते हैं।
        </p>
        <p className="hindi">
          7. फर्जी नंबर/फर्जी रेफरल के मामले में, कंपनी सेल्स ऑर्डर को रद्द करने
          या यहां तक कि खाते को स्थायी रूप से ब्लॉक करने का अधिकार सुरक्षित रखती
          है।
        </p>
        <p className="hindi">
          8. एक रेफरल को एक वैध रेफरल के रूप में तभी शामिल माना जाएगा जब रेफरी
          कम से कम एक दिन के लिए 'मेरापशु360' ऐप को इंस्टॉल और उपयोग करता है।
        </p>
        <p className="hindi">
          9. रेफ़रल पॉइंट केवल उन उपयोगकर्ताओं को पुरस्कृत किए जाएंगे जिन्होंने
          ऐप के न्यू वर्जन को इनस्टॉल किया हैI
        </p>
        <p className="hindi">
          10 . जिन उपयोगकर्ताओं के रेफरल ग्राहक हमारे साथ ऑर्डर करते हैं, उन्हें
          ज्यादा रेफरल बोनस इनाम मिलेगा।
        </p>
        <p className="hindi">
          11. जिन उपयोगकर्ताओं के रेफरल से ग्राहक ऑर्डर नहीं देते, या एक दिन के
          अंदर ऐप अनइंस्टॉल करते हैं, उन्हें कम रेफरल बोनस इनाम मिलेगा।
        </p>
        <p className="hindi">
          12. अगर किसी रेफरल पर ग्राहक ऑर्डर करता है, तो रेफरल ऑर्डर बोनस पूरी
          तरह से दिया जाएगा।
        </p>

        <p className="hindi">
          13. जिन उपयोगकर्ताओं के रेफरल कोई ऑर्डर नहीं देते हैं, उन्हें स्पैमर
          के रूप में चिह्नित किया जाएगा।
        </p>
        <p className="hindi">
          14. कंपनी स्पैम उपयोगकर्ताओं को कम अंक ब्लॉक करने या पुरस्कृत करने का
          अधिकार सुरक्षित रखती है।
        </p>

        <p className="hindi">
          15. कंपनी बिना किसी सूचना के पहले रेफरल राशि को बदलने का अधिकार
          सुरक्षित रखती है।
        </p>
      </div>
    </div>
  );
}

export default TermsReferral;
