import verifiedThumbs from '../assets/verified-thumbs.svg';
import truckIcon from '../assets/truck-icon-v2.svg';
import milkCan from '../assets/milkCan.svg';
import milkCanGrow from '../assets/milkCanGrow.svg';
import baffaloIcon from '../assets/baffalo-icon.svg';
import ruppeeIcon from '../assets/ruppee-icon.svg';
import locationIcon from '../assets/location-icon.svg';
import percentWhite from '../assets/percent-white.svg';
import Button from './Button/Button';
import { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';

type CattleCardProp = {
  id: string;
  animalType: string;
  breed: string;
  mrp: number;
  price: number;
  calfGender: string;
  tag: string;
  calving: string;
  fat: string;
  averageMilkYield: string;
  expectedMilkYield: string;
  parturitionDate: string;
  location: string;
  imageUrl: string;
};

function CattleCard({
  id,
  animalType,
  breed,
  mrp,
  price,
  calfGender,
  tag,
  calving,
  fat,
  averageMilkYield,
  expectedMilkYield,
  parturitionDate,
  location,
  imageUrl,
}: CattleCardProp) {
  const [isAppInstalled, setIsAppInstalled] = useState<Boolean>(false);
  useEffect(() => {
    const targetAppPackageName = 'app.merapashu360.buyerapp';
    const userAgent = navigator.userAgent || navigator.vendor;
    if (userAgent.match(new RegExp(targetAppPackageName, 'i'))) {
      setIsAppInstalled(true);
    } else {
      setIsAppInstalled(false);
    }
    // console.log("isAppInstalled",isAppInstalled);
  },[id])

  const showBreed = breed !== 'OTHER';

  function days_between(date1: any, date2: any) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  }
  return (
    <div className="cattleC">
      <div className="cattleC__thumbnail">
        <img src={imageUrl} alt="Bhains" />

        <div className="cattleC__thumbnail--lables">   
            <div className="cattleC__thumbnail--lable hindi white">
              {/* TO DO -- check if calfgender comes out to be NA */}
             { calfGender &&(<>
              साथ में - {calfGender === 'MALE' ? 'कटड़ा' : 'कटडी'}
             </>
             )}
            </div>

          <div className="cattleC__thumbnail--lable hindi white">
            <img src={percentWhite} alt="percent" />₹{(mrp - price).toFixed(0)}{' '}
            की बचत
          </div>
        </div>
      </div>

      <div className="cattleC__check">
        <img src={verifiedThumbs} alt="verified icon" />
        <p>पशु चिकित्सक द्वारा चेक किया गया</p>
      </div>

      <div className="cattleC__heading"> 
      {showBreed ? breed : animalType} {tag}</div>
      <div className="cattleC__sub">
        <div className="cattleC__sub--item hindi">ब्यात - {calving}</div>
        <div className="cattleC__sub--item--line"></div>
        <div className="cattleC__sub--item hindi">फैट - {fat}</div>
        {/* <div className="cattleC__sub--item--line"></div> */}
        {/* <div className="cattleC__sub--item hindi">
          <img src={truckIcon} alt="truck icon" />
          फ्री होम डिलीवरी
        </div> */}
      </div>

      <div className="cattleC__feats">
        <div className="cattleC__feat">
          <img src={milkCan} alt="Milk Can" />
          <p className="cattleC__feat--lable hindi">तैयार दूध</p>
          <p className="cattleC__feat--number">{averageMilkYield} ltr</p>
        </div>

        <div className="cattleC__feat">
          <img src={milkCanGrow} alt="Milk Capacity" />
          <p className="cattleC__feat--lable hindi">दूध की क्षमता</p>
          <p className="cattleC__feat--number">{expectedMilkYield} ltr</p>
        </div>

        <div className="cattleC__feat">
          <img src={baffaloIcon} alt="Baffalo Icon" />
          <p className="cattleC__feat--lable hindi">बयाई हुई</p>
          <p className="cattleC__feat--number hindi">
            {days_between(
              new Date().getTime(),
              new Date(parturitionDate).getTime()
            )}{' '}
            दिन
          </p>
        </div>
      </div>

      <div className="cattleC__price">
        <div className="cattleC__price--lable">
          <img src={ruppeeIcon} alt="Ruppee Icon" />
          <p className="hindi">कीमत</p>
        </div>
        <div className="cattleC__price--amount">
          <p className="cattleC__price--original">₹{mrp.toFixed(0)} </p>
          <p className="cattleC__price--discounted">₹{price.toFixed(0)}</p>
        </div>
      </div>

      <div className="cattleC__location">
        <img src={locationIcon} alt="location icon" />

        <p className="hindi">{location}</p>
      </div>

      <div className="cattleC__ctas">
        <Button
        primary
        text="जानकारी देखें"
        color="#2CAC4C"
        to= "https://mp360.app/buffalo"
        className='customWidth'
        ></Button>
      </div>
    </div>
  );
}

export default CattleCard;
