import Button from './Button/Button';
import blurredImg from '../assets/blurred-estimate.svg';
import loader from '../assets/loader.json';
import Lottie from 'lottie-react';
import { useLazyQuery } from '@apollo/client';
import { GET_ESTIMATED_SYC_PRICE } from '../gql/gql';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function StepTwoSYCF({
  sycFormValues,
  setSycFormValues,
  setActiveStep,
  setEstimateData,
  getCaptchaToken,
}: // handleSubmit,
// priceLoading,
{
  sycFormValues: any;
  setSycFormValues: any;
  setActiveStep: any;
  setEstimateData: any;
  getCaptchaToken: any;
}) {
  let { t } = useTranslation();
  const [getEstimatedPrice, { loading: priceLoading, error: priceError }] =
    useLazyQuery(GET_ESTIMATED_SYC_PRICE);

  useEffect(() => {
    getCaptchaToken();
  }, [getCaptchaToken]);

  const handleSubmitStepTwo = async (event: any) => {
    event.preventDefault();

    await window.grecaptcha.ready((_: any) => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action: 'EstimateCattlePrice',
        })
        .then(async (token: any) => {
          await setSycFormValues((state: any) => {
            state['captchaToken'] = token;

            return state;
          });
        });
    });

    try {
      const res = await getEstimatedPrice({
        variables: {
          ...sycFormValues,
          readyMilk: {
            value: parseFloat(sycFormValues.readyMilk.value),
            uom: 'litre',
          },
        },
      });
      if (res && res.data && res.data.getSycEstimate) {
        setActiveStep(3);
        setEstimateData(res.data.getSycEstimate);
      } else {
        console.log('error screen');
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  if (priceError) {
    console.log(priceError);
  }

  return (
    <>
      {priceLoading ? (
        <Lottie animationData={loader} loop={true} />
      ) : (
        <>
          {/* <h2 className="sycf__form--title">
            Enter some more details to see the price
          </h2> */}

          <div className="sycf__form--blurred">
            <p className="hindi"> {t('estimated_price')}</p>
            <img src={blurredImg} alt="blurred estimate" />
          </div>
          <div className="sycf__stepOne">
            <form onSubmit={handleSubmitStepTwo}>
              <div className="sycf__label-input sycf__label-input--step2">
                <label htmlFor="phoneNumber">
                  {t('enter_mobile')}
                  <input
                    name="phoneNumber"
                    placeholder=""
                    maxLength={10}
                    required
                    value={sycFormValues.phoneNumber}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = value.replace(/[^0-9]/g,'');
                      if (/^[6-9]/.test(value)) {
                        setSycFormValues({
                          ...sycFormValues,
                          phoneNumber: value,
                        });
                      } else {
                        setSycFormValues({
                          ...sycFormValues,
                          phoneNumber: '',
                        });
                      }
                    }}
                  />
                </label>
              </div>

              <div className="sycf__label-input sycf__label-input--step2">
                <label htmlFor="other">
                  {t('other_comments')}
                  <textarea
                    name="other"
                    placeholder=""
                    value={sycFormValues.otherComments}
                    onChange={(event) => {
                      setSycFormValues({
                        ...sycFormValues,
                        otherComments: event.target.value,
                      });
                    }}
                    cols={24}
                    rows={5}
                  />
                </label>
              </div>

              <div className="sycf__submit-button">
                <Button primary rightArrow text={t('get_estimated_price')} />
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default StepTwoSYCF;
