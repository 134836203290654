import { useState } from "react";
import { useTranslation } from "react-i18next";

function FAQ() {
  const { t } = useTranslation();
  const faqHashMap: any = t('faq_list');
  const [selectedQuestion, setSelectedQuestion] = useState<string | null>(Object.keys(faqHashMap)[0]);

  const toggleQuestion = (key: string) => {
    setSelectedQuestion((prev) => (prev === key ? null : key));
  };

 

  return (
    <div className="faq" id="faq">
      <div className="faq__subheading subheading">KNOW MORE</div>
      <div className="faq__heading heading">
        FREQUENTLY ASKED <span className="green">QUESTIONS</span>
      </div>

      <div className="faq__container">
        <div className="faq__questions">
          {Object.keys(faqHashMap).map((key: any) => (
            <div key={key}>
              <div
                className={`faq__question ${key === selectedQuestion ? 'faq__question--active' : ''}`}
                onClick={() => { toggleQuestion(key) }}
              >
                <div className="faq__question--circle"></div>
                <p>{key}</p>
                <div className="faq__question--right-arrow faq--down-arrow"></div>
              </div>

              {key === selectedQuestion && (
                <div className="faq__answer">
                  <div className="faq__answer--heading">{selectedQuestion as string}</div>
                  <div className="faq__answer--content">
                    {faqHashMap[selectedQuestion as string].map((answer_list: string) => (
                      <div key={answer_list} className="faq__answer--content-item">{answer_list}</div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Render answer alongside question list only on larger screens */}
        <div>
          {selectedQuestion && (
            <div className="faq_ansDesktop">
              <div className="faq_ansDesktop--heading">{selectedQuestion as string}</div>
              <div className="faq_ansDesktop--content">
                {faqHashMap[selectedQuestion as string].map((answer_list: string) => (
                  <div key={answer_list} className="faq_ansDesktop--content-item">{answer_list}</div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FAQ;



