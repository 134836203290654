import { useTranslation } from 'react-i18next';
function CattleMobFilter({
  filterFormState,
  setFilterFormState,
  mobileFilterState,
  setMobileFilterState,
  query,
}: {
  filterFormState: any;
  setFilterFormState: any;
  mobileFilterState: any;
  setMobileFilterState: any;
  query: any;
}) {
  let { t } = useTranslation();

  //state

  //constants
  const milkFilterMap = {
    '6-9ltr': '6,9',
    '9-12ltr': '9,12',
    '12-15ltr': '12,15',
    '15-18ltr': '15,18',
    '18-20ltr': '18,20',
  };

  const priceFilterMap = {
    '50-65k': '50000,65000',
    '65-80k': '65000,80000',
    '80-95k': '80000,95000',
    '95-110k': '95000,110000',
    '110-125k': '110000,125000',
    '125-140k': '125000,140000',
  };

  const byayiFilter = {
    '1,15': '1,15',
    '16,25': '16,25',
    '26,45': '26,45',
    '46,75': '46,75',
  };

  const locatoinFilter = {
    gurgaon: 'GGN-HUB',
    jind: 'JIND-HUB',
    ganganagar: 'SGN-HUB',
    sirsa: 'SIRSA-HUB',
  };

  const handleFormChange = async (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    let data: any;
    let updatedValue = {};
    if (name === 'milkFilter') {
      data = {
        minMilk: Number(value.split(',')[0]),
        maxMilk: Number(value.split(',')[1]),
      };
      //exp mobile filter state

      updatedValue = { milkFilter: value };
    } else if (name === 'priceFilter') {
      data = {
        minPrice: Number(value.split(',')[0]),
        maxPrice: Number(value.split(',')[1]),
      };
      updatedValue = { price: value };
    } else if (name === 'calving') {
      data = Number(value);
      updatedValue = { calving: value };
    } else if (name === 'parturitionDaysFilter') {
      data = {
        minParturitionDays: Number(value.split(',')[0]),
        maxParturitionDays: Number(value.split(',')[1]),
      };
      updatedValue = { byayi: value };
    } else if (name === 'hub') {
      if (filterFormState['hub'] !== undefined) {
        data = filterFormState.hub;
      } else {
        data = [];
      }

      if (!data.includes(value)) {
        data.push(value);
      } else {
        data = data.filter((e: string) => e !== value);
      }

      ///mobile
    }

    let obj: any = {
      ...filterFormState,
    };

    obj[e.target.name] = data;

    setFilterFormState({
      ...filterFormState,
      ...obj,
    });
    setMobileFilterState((mobileFilterState: any) => ({
      ...mobileFilterState,
      ...updatedValue,
    }));
    window.scrollTo(0, 0);
    await query({
      variables: obj,
    });
  };

  return (
    <div className="feed__mobilefilters-container">
      <select
        onChange={handleFormChange}
        id={`milkFilterMobile`}
        name={'milkFilter'}
        value={mobileFilterState?.milkFilter}
      >
        <option hidden value="milk">
          {t('milk')}
        </option>
        {Object.entries(milkFilterMap).map((item, index) => {
          let [key, value] = item;

          return (
              <option key={index} id={`${key}`} value={value}>
                {value.split(',')[0]} - {value.split(',')[1]} {t('litre')}
              </option>
          );
        })}
        <option value={'25,100'} id={'25,100ltr'}>
          {t('more_than_20_litre')}
        </option>
      </select>
      {/* price */}
      <select
        onChange={handleFormChange}
        name={'priceFilter'}
        value={mobileFilterState?.price}
      >
        <option hidden value="price">
          {t('price')}
        </option>
        {Object.entries(priceFilterMap).map((item, index) => {
          let [key, value] = item;

          return (
              <option key={index} id={`${key}`} value={value}>
                ₹{value.split(',')[0]} - ₹{value.split(',')[1]}
              </option>
          );
        })}
      </select>
      {/* byayi */}
      <select
        onChange={handleFormChange}
        name={'parturitionDaysFilter'}
        value={mobileFilterState?.byayi}
      >
        <option hidden value="byayi">
          {t('byayi')} ({t('day')})
        </option>
        {Object.entries(byayiFilter).map((item, index) => {
          let [key, value] = item;

          return (
              <option key={index} id={`${key}`} value={value}>
                {value.split(',')[0]} - {value.split(',')[1]}
              </option>
          );
        })}
        <option value={'75, 1000'} id={'75,1000'}>
          75+
        </option>
      </select>
      {/* hub */}
      <select
        onChange={handleFormChange}
        name={'hub'}
        value={mobileFilterState?.hub}
      >
        <option hidden value="hub">
          {t('hub')}
        </option>
        {Object.entries(locatoinFilter).map((item, index) => {
          let [key, value] = item;

          return (
              <option key={index} id={`${key}-containermob`} value={value}>
                {t(key)}
              </option>
          );
        })}
      </select>

      <select
        onChange={handleFormChange}
        name={'calving'}
        value={mobileFilterState?.calving}
      >
        <option hidden value={0}>
          {t('calving')}
        </option>
        {[1, 2, 3, 4].map((item, index) => {
          return (
              <option key={index} id={`calvingmob-${item}`} value={item}>
                {item}
              </option>
          );
        })}
        <option value={'25,100'} id={'25,100ltr'}>
          {t('more_than_20_litre')}
        </option>
      </select>
    </div>
  );
}

export default CattleMobFilter;
