import AboutUs from './sections/AboutUs';
// import FAQ from './sections/FAQ';
import Hero from './sections/Hero';
import Support from './sections/Support';
import SYC from './sections/SYC';
import WhatWeOffer from './sections/WhatWeOffer';
import Youtube from './sections/Youtube';
import DownloadMP360 from './sections/DownloadMP360';
import Testimonials from './sections/Testimonials';
import { useQuery } from '@apollo/client';
import loader from '../../assets/loader.json';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import { GET_DATA } from '../../gql/gql';
import FAQ from './sections/FAQ';

function Home() {

  const {
    loading: dataLoading,
    error: dataError,
    data: staticData,
  } = useQuery(GET_DATA, {
    fetchPolicy: 'cache-first',
  });

  if (dataError) {
    console.log(dataError);
  }

  function getRandomInt() {
    return Math.floor(Math.random() * 5);
  }

  const { t } = useTranslation();

  return (
    <>
      {dataLoading ? (
        <Lottie animationData={loader} loop={true} />
      ) : (
        <div>
          <Hero />
          <WhatWeOffer data={staticData.siteData.weOffer} />
          <SYC />
          <Support />
          <Youtube data={staticData.siteData.youtubeVideos} />
          <Testimonials
            data={staticData.siteData.testimonials[getRandomInt()]}
          />
          <FAQ />
          <AboutUs
            sub={t('who_are_we')}
            heading={t('know_us')}
            desc={t('about_us_desc')}
            stats={staticData.siteData.stats}
          />
          <DownloadMP360 />
        </div>
      )}
    </>
  );
}

export default Home;
