import { Helmet } from 'react-helmet-async';
import { PHONE_NUMBER } from '../constants';

function Terms() {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | MeraPashu360</title>

        <meta
          name="description"
          content="MeraPashu 360 Terms & Conditions outlines the rules to use our website & services. By using our website, you accept & agree to comply with these Terms & Conditions."
        />
        <link rel="canonical" href="https://merapashu360.com/terms-of-service/" />
      </Helmet>
      <div className="terms--root">
        <h1 className="template-page--heading" data-item="section-heading">
          Terms of Service
        </h1>

        <div data-item="rte-content">
          <p>
            <strong>A. INTRODUCTION</strong>
          </p>
          <p>
            1. MeraPashu360 Private Limited on behalf of itself and its
            affiliates / group companies under the brand ‘MeraPashu360’,
            (hereinafter referred to as “MeraPashu360”, “Us” or “We” or “Our” or
            “Company”), is the author and publisher of the website -
            <a href='https://merapashu360.com'> https://merapashu360.com</a> and the mobile application ‘MeraPashu360’
            (together the “Site”). MeraPashu360 owns and operates the services
            provided through the Site.
          </p>
          <p>
            <strong>B. Terms of Agreement</strong>
          </p>
          <p>
            1. These terms and conditions (“Terms and Conditions”) along with
            the privacy policy available at -
            <a href="https://merapashu360.com/privacy-policies"> https://merapashu360.com/privacy-policies</a> (the “Privacy Policy”)
            constitutes a legally binding agreement made between You, whether
            personally or on behalf of an entity that you represent (“You” or
            “Your” or “Yourself” or “User”), and MeraPashu360, concerning Your
            access to and use of the Site and the services.
          </p>
          <p>
            2. If You continue to browse and use the Site or avail the services,
            You irrevocably consent to and accept all the conditions stipulated
            in these Terms of Conditions and the Privacy Policy and agree to
            abide by them. You agree that by accessing the Site and / or using
            Our services, You have read, understood, and agree to be bound by
            all of the Terms and Conditions. If You do not agree with all of the
            Terms and Conditions, then You are prohibited from using the Site
            and services and You should exit the Site and discontinue the use of
            Our services immediately.
          </p>
          <p>
            3. The Company reserves the right at all times to discontinue or
            modify the Terms and Conditions and Privacy Policy as We deem
            necessary or desirable without any prior notification. Such changes
            may include, among other things, adding and / or modifying prices,
            fees and/or charges. Any such modifications would be effective
            immediately, as soon as they are posted on the Site. We suggest that
            You read the Terms and Conditions, Privacy Policy and other changes
            in Our products, services and fees, from time to time to stay
            informed and Your continued use of the Site, after the changes are
            effective, will constitute Your acknowledgement and acceptance.
          </p>
          <p>
            4. In the event of any discrepancy between the Terms and Conditions
            and any other policies with respect to the Site, the provisions of
            the Terms and Conditions shall prevail. 5. These Terms and
            Conditions constitute an electronic record in accordance with the
            provisions of the Information Technology Act, 2000.
          </p>
          <p>
            <strong>C. ELIGIBILITY</strong>
          </p>
          <p>
            1. Use of the Site is available only to persons who can form legally
            binding contracts under applicable law. Persons who are ‘incompetent
            to contract’ within the meaning of the applicable laws including
            un-discharged insolvents, and persons of unsound mind are not
            eligible to use the Site. You may use the Site only if Your age is
            over 18 years and You are capable of forming binding contracts under
            applicable laws and You are not prohibited from entering into a
            legally binding contract as per applicable laws.
          </p>
          <p>
            2. In case the Site is to be accessed or used by a minor (i.e. a
            person less than 18 years of age) or by a person incompetent to
            contract under applicable law, then only the legal guardian /
            representative of such person shall register himself / herself as a
            User and create / add the profile of the minor. This shall not
            discharge the legal guardian / representative, who is an adult and
            of sound mind, from its obligations and liability towards the
            Company or its contractors or agents and the Company shall have the
            right to proceed against the legal guardian / representative and
            User, either jointly or severally, for their acts and omissions that
            violate these Terms and Conditions, Privacy Policy and any other
            terms in relation to the services provided by the Company.
          </p>
          <p>
            3. The Company reserves the right to terminate Your account and Your
            membership and refuse to provide You with access to the Site if the
            Company discovers that You are under the age of 18 years or
            otherwise not capable of forming binding contracts, as required
            under applicable law. In case of a business entity, You represent
            that You have the legal authority to bind the entity or the
            employees or consultants of such entity, to these Terms and
            Conditions.
          </p>
          <p>
            4. You acknowledge that the Company does not have the responsibility
            to ensure that You conform to the aforesaid eligibility criteria. It
            shall be Your sole responsibility to ensure that You meet the
            required qualification.
          </p>
          <p>
            <strong>D. SERVICES OFFERED</strong>
          </p>
          <p>1. The Site offers the following services:</p>
          <p>
            1.1. An online platform for trading animals (predominantly
            livestock), which allows You to browse a list of animals and
            purchase them online. You can also register on the Site and view the
            list of animals available for purchase. We have no liability for the
            accuracy of the details or information of the animals, provided by a
            user on the Site.
          </p>
          <p>
            1.2. Facilitation of an internet-based service for You, where You
            have an option to book an appointment, either instant appointment or
            pre-scheduled, with a certified veterinary doctor and obtain
            information regarding animal behaviour, diseases and / or symptoms.
          </p>
          <p>
            1.3. An online platform for browsing and purchasing cattle feed. You
            can also register on the Site and view the list of cattle feed from
            different brands available for purchase.
          </p>
          <p>2. No Medical Services&nbsp;</p>
          <p>
            2.1. Notwithstanding anything contained under this Agreement, it is
            hereby clarified that the Company is not a medical service provider,
            nor is it involved in providing any healthcare services nor medical
            advice nor diagnosis. The Company is merely a digital intermediary
            platform which facilitates connecting medical professionals with the
            Users through the provision of or the means of the Site and hence
            the Company shall not be responsible and owns no liability to the
            User for any outcome from the booking or the consultation between
            You and the medical professional. The Site provides an option to
            book instant as well as pre-scheduled consultations with the medical
            professional basis the availability of the medical professional. The
            Company is not responsible for any inaccuracy or incompleteness of
            the data and information provided by the medical professional.
          </p>
          <p>
            2.2. Please note that the Site and Our services do not replace
            traditional veterinary care and We do not directly provide any
            medical service. The User acknowledges and agrees that the User is
            aware of the limitations attached to the online consultation
            services as the medical professional will not be conducting physical
            examination of the Users or their cattle / livestock or their
            medical conditions / symptoms, and that they may not have or be able
            to derive important information that is usually obtained through a
            physical examination. The User agrees to assume the complete risk of
            this limitation.
          </p>
          <p>
            2.3. All consultations / prescriptions / email and hard copy
            correspondences (“Correspondences”) between the Users and the
            medical professional shall be recorded, saved and stored for: (a)
            internal record purposes and (b) in the event such records are
            required to be produced as evidence on the direction of a court of
            law. The Company may review this data from time to time for quality
            evaluation purposes. All such Correspondences shall be subject to
            the Privacy Policy.
          </p>
          <p>
            2.4. Without prejudice to the generality of the above, the User
            understands and agrees that Company will not be liable for,
            including but not limited to the following:
          </p>
          <p>
            a. User interactions and associated issues User has with the medical
            professional including the content shared or generated pursuant to
            the use of the services or provision of the medical services by the
            medical professional;
          </p>
          <p>
            b. the suitability, ability or intent of the medical professional or
            the lack of it, in fulfilling their obligations towards Users
            including delayed responses by the medical professional;
          </p>
          <p>
            c. any wrong medication or quality of treatment being given by the
            medical professional(s) or any medical negligence on part of the
            medical professional(s);
          </p>
          <p>
            d. any misconduct or inappropriate behaviour by the medical
            professional or its staff;
          </p>
          <p>
            e. cancellation or no show by the medical professional or
            rescheduling of booked appointment or any variation in the fees
            charged; or
          </p>
          <p>
            f. sudden escalation of a prior medical condition (including death)
            or medical situations that occur on account of omission of critical
            and material health information by a User and / or in course of
            availing medical services from the practitioners.
          </p>
          <p>
            <strong>E. USER AGREEMENT</strong>
          </p>
          <p>
            1. No User shall be permitted to perform, host, display, upload,
            modify, publish, transmit, store, update, or share any information
            or perform any of the following prohibited activities while using
            the Site and availing Our services:
          </p>
          <p>
            1.1. systematically retrieve data or other content from the Site to
            a compile database or directory without written permission from Us;
          </p>
          <p>
            1.2. make any unauthorized use of the Site, including collecting
            usernames and / or email addresses and / or phone numbers of users
            to send unsolicited email, messages or creating user accounts under
            false pretenses;
          </p>
          <p>
            1.3. use a buying agent or purchasing agent to make purchases on the
            Site;
          </p>
          <p>
            1.4. circumvent, disable, or otherwise interfere with
            security-related features of the Site, including features that
            prevent or restrict the use or copying of any content or enforce
            limitations on the use;
          </p>
          <p>1.5. engage in unauthorised framing of or linking to the Site;</p>
          <p>
            1.6. trick, defraud, or mislead Us and other users in any manner
            including but not limited to any attempt to learn sensitive account
            information such as user passwords;
          </p>
          <p>
            1.7. make improper use of Our support services, or submit false
            reports of abuse or misconduct;
          </p>
          <p>
            1.8. engage in any automated use of the system, such as using
            scripts to send comments or messages, or using any data mining,
            robots, or similar data gathering and extraction tools;
          </p>
          <p>
            1.9. interfere with, disrupt, or create an undue burden on the Site
            or the networks and services connected to the Site;
          </p>
          <p>
            1.10. attempt to impersonate another user or person, or use the
            username of another user;
          </p>
          <p>1.11. sell or otherwise transfer Your profile;</p>
          <p>
            1.12. use any information obtained from the Site in order to harass,
            abuse, or harm another person;
          </p>
          <p>
            1.13. use the Site or Our content as part of any effort to compete
            with Us or to create a revenue-generating endeavor or commercial
            enterprise;
          </p>
          <p>
            1.14. decipher, decompile, disassemble, or reverse engineer any of
            the software comprising or in any way making up a part of the Site.
          </p>
          <p>
            1.15. attempt to access any portions of the Site that You are
            restricted from accessing;
          </p>
          <p>
            1.16. harass, annoy, intimidate, or threaten any of Our employees,
            agents, or other users;
          </p>
          <p>
            1.17. delete the copyright or other proprietary rights notice from
            any of the content;
          </p>
          <p>
            1.18. copy or adapt the Site's software, including but not limited
            to content and any form of code;
          </p>
          <p>
            1.19. upload or transmit (or attempt to upload or to transmit)
            viruses, trojan horses, any computer coder or file or program, or
            other material that interferes with any user's uninterrupted use and
            enjoyment of the Site, or any material that acts as a passive or
            active information collection or transmission mechanism;
          </p>
          <p>
            1.20. use, launch, or engage in any automated use of the system,
            such as using scripts to send comments or messages, robots,
            scrapers, offline readers, or similar data gathering and extraction
            tools;
          </p>
          <p>
            1.21. disparage, tarnish, or otherwise harm, in Our opinion, Us and
            / or the Site;
          </p>
          <p>
            1.22. use the site in a manner inconsistent with any applicable laws
            or rules or regulations;
          </p>
          <p>
            1.23. threaten other users / Our employees with negative feedback;
            1.24. misrepresent experience, skills, or information about
            Yourself;
          </p>
          <p>
            1.25. falsely imply a relationship with Us or another company with
            whom You do not have a relationship;
          </p>
          <p>
            1.26. use the services for purposes that are not permitted by: (i)
            these Terms and Conditions and; (ii) any applicable law, regulation
            or generally accepted practices or guidelines in the relevant
            jurisdiction; or 1.27. violate any of the terms specified under
            these Terms and Conditions for the use of the Site / services /
            products.
          </p>
          <p>
            2. Further, no User shall be permitted to perform, host, display,
            upload, modify, publish, transmit, store, update, or share any
            information:
          </p>
          <p>
            2.1. which belongs to another person and to which the User does not
            have any right;
          </p>
          <p>
            2.2. is misleading, unlawful, harmful, threatening, abusive, vulgar,
            defamatory, lewd, lascivious, profane, obscene, pornographic,
            paedophilic, invasive of another's privacy, including bodily
            privacy, insulting or harassing on the basis of gender, libellous,
            racially, ethnically or otherwise objectionable, relating or
            encouraging money laundering or gambling, or otherwise inconsistent
            with or contrary to the laws in force;
          </p>
          <p>2.3. is harmful to a child;</p>
          <p>
            2.4. infringes any patent, trademark, copyright or other proprietory
            rights;
          </p>
          <p>2.5. impersonates another person;</p>
          <p>
            2.6. deceives or misleads the addressee about the origin of the
            message or knowingly and intentionally communicates any information
            which is patently false or misleading in nature but may reasonably
            be perceived as a fact;
          </p>
          <p>
            2.7. stalking, intimidating and / or harassing another and / or
            inciting other to commit violence;
          </p>
          <p>
            2.8. threatening the unity, integrity, defence, security or
            sovereignty of India, friendly relations with foreign states, or
            public order, or causes incitement to the commission of any
            cognizable offence or prevents investigation of any offence or is
            insulting other nation;
          </p>
          <p>
            2.9. is patently false and untrue, and is written or published in
            any form, with the intent to mislead or harass a person, entity or
            agency for financial gain or to cause any injury to any person;
          </p>
          <p>
            3. By submitting content on or through the Site (“Your Material”),
            You grant Us a worldwide, non-exclusive, royalty-free license (with
            the right to sublicense) to use, copy, reproduce, process, adapt,
            modify, publish, transmit, display and distribute Your Material in
            any and all media or distribution methods (now known or later
            developed) and to associate Your Material with You. You agree that
            others may use Your Material in the same way as any other content
            available through the services. If You delete Your account, Your
            Material and name may remain available on the Site.
          </p>
          <p>
            4. The Company reserves the right at its sole discretion to remove,
            review, edit or delete any content. We will not be responsible or
            liable for any content uploaded by You or any other users directly
            on the Site. We would not be responsible to verify whether any
            content placed by any You or any other user contains infringing
            materials or not.
          </p>
          <p>
            5. The Company (including but not limited to its subsidiaries /
            affiliates) may, based on any form of access to the Site (including
            free download / trials) or services or registrations through any
            source whatsoever, contact the User through SMS, email and call, to
            give information about their offerings and products as well as
            notifications on various important updates and / or to seek
            permission for demonstration of its products. The User expressly
            grants such permission to contact him / her through telephone, SMS,
            e-mail and holds the Company (including but not limited to its
            subsidiaries/affiliates) indemnified against any liabilities
            including financial penalties, damages, expenses in case the User's
            mobile number is registered with Do not Call / Do not Disturb
            database. By registering Yourself, You agree to make Your contact
            details available to Our employees, associates, subsidiaries,
            affiliates and partners so that You may be contacted for
            information, offerings and promotions through telephone, SMS, email
            etc.
          </p>
          <p>
            6. The User acknowledges that he / she has not been induced by any
            statements or representations of any person with respect to the
            quality or conditions of the products or services and that User has
            relied solely on the investigations, examinations and inspections as
            the User has chosen to make and that the&nbsp;Company has afforded
            the User the opportunity for full and complete investigations,
            examinations and inspections.
          </p>
          <p>
            7. The Site and content therein, are compatible only with certain
            devices / tablets / instruments / hardware. The Company reserves the
            right to upgrade the table / type of compatible devices as required
            from time to time. The User agrees that the Company bears no
            responsibility if the User’s device is not compatible with the
            specifications as required by the Site.
          </p>
          <p>
            8. The Company shall have no responsibility for any loss or damage
            caused to tablet or any other hardware and / or software and / or
            instrument and / or device, including loss of data or effect on the
            processing speed, resulting from Your use of Our Site.
          </p>
          <p>
            <strong>F. YOUR ACCOUNT</strong>
          </p>
          <p>
            1. In order to access the services and to avail the use of the Site
            and the services, You shall be required to register Yourself with
            the Site, and maintain an account on the Site. You will be required
            to furnish certain information and details, including Your name,
            mobile number, e-mail address, residential address, grade / class of
            the student, school name, payment information (credit / debit card
            details) if required, and any other information deemed necessary by
            the Site. With respect to the provision of information, the
            following may be noted: -
          </p>
          <p>
            1.1. It is Your sole responsibility to ensure that the account
            information provided by You is accurate, complete and latest.
          </p>
          <p>
            1.2. You shall be responsible for maintaining the confidentiality of
            the account information and for all activities that occur under Your
            account. You agree to:
          </p>
          <p>
            1.2.1 ensure that You successfully log out from Your account at the
            end of each session; and
          </p>
          <p>
            1.2.2 immediately notify the Company of any unauthorized use of Your
            account. If there is reason to believe that there is likely to be a
            breach of security or misuse of Your account, We may request You to
            change the password or We may suspend Your account without any
            liability to the Company, for such period of time as We deem
            appropriate in the circumstances. We shall not be liable for any
            loss or damage arising from Your failure to comply with this
            provision.&nbsp;
          </p>
          <p>
            1.3. You acknowledge that Your ability to use Your account is
            dependent upon external factors such as internet service providers
            and internet network availability and the Company cannot guarantee
            accessibility to the Site at all times. In addition to the
            disclaimers set forth in these Terms and Conditions, the Company
            shall not be liable to You for any damages arising from Your
            inability to log into Your account and access the services / the
            Site at any time.
          </p>
          <p>
            <strong>G. PAYMENT</strong>
          </p>
          <p>
          1. Online Payment Acceptance: 
          <p className="margin-left-20">
            a. The online payment facility is exclusively available for the purchase of cattle feed products.
          </p>
            <p className="margin-left-20">
            b. Online payments are not accepted for cattle / livestock related transactions.
            </p>
          </p>
          <p>
            2. All and any payments on the Site shall be through the payment
            mechanism put in place by the Company and You shall be responsible
            for paying all fees and applicable taxes in a timely manner. Users
            may be given various options to choose and proceed with the payment
            for the Services via payment mechanisms including but not limited to
            card payment facilities, EMI payment, online bank transfer and
            wallet payment. The payment gateway mechanisms are governed by the
            terms and conditions of the third-party providers as listed on their
            website and the User agrees to be bound by those terms. The Company
            shall not be responsible for any claims, error, malfunction,
            liabilities, damages or defect in the service provided to You by
            such third-party platforms.
          </p>
          <p>
            3. Valid credit / debit / cash card / UPI / wallet / online bank
            transfers and other payment instruments are processed using a credit
            card payment gateway or appropriate payment system infrastructure
            and the same will also be governed by the terms agreed to between
            the Users and the respective issuing bank and payment instrument
            issuing company. We shall not be liable for any unauthorized use,
            fraud, payment refunds, lost amount etc. in the transaction. 3. We
            reserve the right to change any fees at any time at Our sole
            discretion and any change, update, or modification in the fee shall
            become effective immediately upon the same being posted / uploaded
            or notified on Our Site and Your continued use shall be deemed to be
            an acknowledgement of the same.
          </p>
          <p>
            <strong>H. REFUND AND CANCELLATION POLICY</strong>
          </p>
          <p>
            1. At MeraPashu360, Our motto is:
            <strong>
              <span>&nbsp;</span>Swastha Pashu, Samruddh Kissan
            </strong>
          </p>
          <p>
            2. In the event, You are displeased with Our products or services,
            We will, at Our sole discretion, refund Your money if the reasons
            provided by You are genuine to Our satisfaction and proven after
            investigation. In case of dissatisfaction with Our services, You
            have the liberty to cancel the orders and request a refund from Us.
            Our Policy for the cancellation and refund will be as follows:
          </p>
          <p>
            2.1. You may cancel Your order free of charge up to 1 (one) day
            before the order is dispatched for delivery. You will be refunded
            the amount paid for such an order, if any. Processing of the refund
            may take up to 14 (fourteen) working days.
          </p>
          <p>
            2.2. Your refund will be made to the same account from which the
            payment was originally made.
          </p>
          <p>
            2.3. For ‘Cash on Delivery’ products, no refund will be provided.
          </p>
          <p>
            2.4 To initiate the refund, please contact our customer care at our
            customer care number
            <strong>
              <span>&nbsp;</span>
              {PHONE_NUMBER}
            </strong>
          </p>
          <p>
            <strong>I. DISCLAIMER / LIMITATION OF LIABILITY</strong>
          </p>
          <p>
            1. All information contained on the Site is for general information
            purposes only. The information is provided by Us and while We try on
            a best effort basis to keep the information up to date and correct,
            We make no representations or warranties of any kind, express or
            implied, about the completeness, accuracy, reliability, suitability
            or availability with respect to the Site or the information,
            products, services, or related graphics contained on the Site for
            any purpose. Any reliance You place on such information is therefore
            strictly at Your own risk and We shall not be liable for the same.
            In the preparation of the Site and contents therein, every effort
            has been made to offer the most current, correct, and clearly
            expressed information possible. Nevertheless, inadvertent errors may
            occur. In particular, but without limiting anything herein, the
            Company disclaims any responsibility for any errors and accuracy of
            the information that may be contained in the Site. Any feedback from
            User is most welcome so as to make the Site and contents thereof
            error free and user friendly. Company also reserves the right and
            discretion to make any changes / corrections or withdraw / add
            contents at any time without notice. Neither the Company nor any
            third parties provide any warranty or guarantee as to the accuracy,
            timeliness, performance, completeness or suitability of the
            information and materials found or offered on the Site for any
            particular purpose. You acknowledge that such information and
            materials may contain inaccuracies or errors and We expressly
            exclude liability for any such inaccuracies or errors to the fullest
            extent.
          </p>
          <p>
            2. From time to time the Site may also include links to other
            websites. These links are provided for Your convenience to provide
            further information. They do not signify that We endorse the
            website(s). We have no responsibility for the content of the linked
            website(s). You may not create a link to the Site from another
            website or document without the Company's prior written consent.
          </p>
          <p>
            3. In no event will We be liable for any loss or damage including
            without limitation, indirect or consequential loss or damage, or any
            loss or damage whatsoever arising from loss of data or profits arise
            out of, or in connection with, the use of the Site.
          </p>
          <p>
            4. In respect of certain of our services, We are just an online
            platform where We facilitate the engagement between a potential
            buyer and seller. Any and all of the terms and conditions in
            relation to the sale shall be governed by individual contracts or
            arrangements agreed between the parties therein and the Company
            bears no responsibility towards the same. Further, the Company bears
            no responsibility towards the quality, description, information of
            the products posted on Our Site by other users.
          </p>
          <p>
            5. We make commercially reasonable endeavors to keep the Site up and
            running smoothly. However, We take no responsibility for, and will
            not be liable for, the Site being temporarily being unavailable due
            to technical issues.
          </p>
          <p>
            6. In no event shall the total aggregate liability of the Company,
            or any of its directors, officers, employees, agents or content or
            service providers, to a User for all damages, losses, and causes of
            action (whether in contract or tort, including, but not limited to,
            negligence or otherwise) arising from these Terms and Conditions,
            Privacy Policy or a User’s use of the Site or the services exceed,
            in the aggregate Rs. 1000/- (Rupees One Thousand Only).
          </p>
          <p>
            <strong>
              J. GENERAL TERMS AND CONDITIONS FOR SELLERS AND MEDICAL
              PROFESSIONALS
            </strong>
          </p>
          <p>
            1. As a registered seller or medical professional, you are allowed
            to list item(s) and / or provide certain services for sale on the
            Site in accordance with the Terms and Conditions, Privacy Policy and
            other terms which is agreed between such seller / medical
            professional and Us. You must be legally able to sell the item(s)
            and / or provide the services which you list for sale on our Site.
            You must ensure that the listed items do not infringe upon the
            intellectual property, trade secret or other proprietary rights or
            rights of publicity or privacy rights of third parties. Listings may
            only include text descriptions, graphics and pictures that describe
            Your item and or Your service for sale. All services / listed items
            must be listed in an appropriate category on the Site. All listed
            items for sale by the seller must be kept in stock for successful
            fulfilment of sales.
          </p>
          <p>
            2. The listing description of the item and / or the service must not
            be misleading and must describe actual condition of the product and
            / or the actual service which shall be provided. If the item and /
            or service description does not match the actual condition of the
            item and / or the actual service rendered by You, You agree to
            refund any amounts that You may have received from the buyer of the
            item and / or the service. You agree not to list a single product in
            multiple quantities across various categories on the Site. The
            Company reserves the right to delete such multiple listings of the
            same product listed by You in various categories.
          </p>
          <p>
            <strong>K. TERMINATION</strong>
          </p>
          <p>
            1. The Company reserves the right to suspend or terminate a User’s
            access to the Site and the services with or without notice and to
            exercise any other remedy available under law, where:
          </p>
          <p>1.1. such User breaches these Terms and Conditions;</p>
          <p>
            1.2. a third party reports violation of any of its rights as a
            result of your use of the Terms and Conditions and the services;
          </p>
          <p>
            1.3. the Company is unable to validate any information provided by a
            User;
          </p>
          <p>
            1.4. the Company has grounds to believe that the User is breaching
            the Terms and Conditions and / or doing any illegal, fraudulent or
            abusive activity on part of such User; or
          </p>
          <p>
            1.5. the Company has ground to believe that the User is acting
            contrary to the interest of the Company and / or the Site.
          </p>
          <p>
            2. Once temporarily suspended, indefinitely suspended or terminated,
            the User may not continue to use the Site under the same account, a
            different account or reregister under a new account. On termination
            of an account due to the reasons mentioned herein, such User shall
            no longer have access to data, messages, files and other material
            kept on the Site by such User.
          </p>
          <p>
            <strong>L. INTELLECTUAL PROPERTY RIGHTS</strong>
          </p>
          <p>
            1. Except as mentioned below, all information, content, material,
            trademarks, services marks, trade names, and trade secrets including
            but not limited to the software, text, images, graphics, video,
            script and audio, contained in the Site, Services and products are
            proprietary property of the Company (“Company’s Proprietary
            Information”).
          </p>
          <p>
            2. Company’s Proprietary Information shall not be copied,
            downloaded, reproduced, modified, republished, uploaded, posted,
            transmitted or distributed in any way without obtaining a prior
            written consent from the Company and nothing on the Site shall be or
            products deemed to confer a license of or any other right, interest
            or title to or in any of the intellectual property rights belonging
            to the Company, to the User.
          </p>
          <p>
            3. You may own the medium on which the information, content or
            materials resides,&nbsp;but the Company shall at all times retain
            full and complete title to the information, content or materials and
            all intellectual property rights inserted by the Company on such
            medium. Certain contents on the Site may belong to third parties.
            Such contents have been reproduced after taking prior consent from
            said party and all rights relating to such content will remain with
            such third party.
          </p>
          <p>
            4. The Company may from time-to-time upload videos, audios / sound
            recordings, content and other materials on the Site which shall be
            the exclusive property of the Company. You undertake not reproduce,
            transmit, retransmit, distribute, publish, post, share or make
            available the said videos, audios / sound recordings, content and
            other materials or any part thereof which are available on the Site
            in any manner whatsoever.
          </p>
          <p>
            5. Any recordings, videos, sound / audio recordings, screen shots,
            pictures, images, material or content which is obtained in any
            manner without the prior written consent of the Company shall amount
            to breach of the Terms and Conditions and You shall be solely liable
            for the said breach under the applicable laws. All other rights are
            reserved.
          </p>
          <p>
            6. All rights, ownership, and intellectual property in the ‘User
            Content’ created by You on or by using the Site, shall vest in the
            Company. You hereby explicitly consent and transfer all such rights,
            ownership, and intellectual property in the ‘User Content’ to the
            Company for a worldwide, royalty-free, and perpetual use. The
            Company shall have the sole and exclusive right to use, copy,
            modify, adapt, prepare derivative works from, distribute, perform,
            and display any / all such ‘User Content’.
          </p>
          <p>
            7. You undertake to use the Company’s Site and intellectual property
            for only the purposes envisaged in the instant Terms and Conditions
            and shall not use the same for any unauthorized or unlawful purpose.
            Should You use the Company’s Site or Company’s Proprietary
            Information with any third party’s intellectual property in any
            manner, then You will be solely responsible to obtain all necessary
            permissions, authorizations and licenses from the said third party.
            In the event You use the Company’s Site, and Company’s Proprietary
            Information beyond the limited rights granted to You under these
            Terms and Conditions, then You shall be solely responsible for the
            same and shall indemnify the Company for all losses, liabilities,
            claims and damages in this regard.
          </p>
          <p>
            8. You will be solely responsible to ensure that any content or
            intellectual property created by You shall not contain any bugs,
            virus and malware or infringe any third party’s intellectual
            property rights or violate any applicable laws (including
            data&nbsp;protection and privacy laws) in any manner.
          </p>
          <p>
            9. Further, You recognize and acknowledge that the ownership of all
            trademarks, copyright, logos, service marks and other intellectual
            property owned by any third party shall continue to vest with such
            party and You are not permitted to use the same without the express
            written consent of the respective third party.
          </p>
          <p>
            <strong>M. INDEMNITY</strong>
          </p>
          <p>
            1. You agree to defend, indemnify and hold harmless the Company, its
            officers, directors, employees and agents, from and against any and
            all claims, damages, obligations, losses, liabilities, costs or
            debt, and expenses (including but not limited to attorney's fees)
            arising from:
          </p>
          <p>1.1. Your use of and access of the Site / services / products;</p>
          <p>
            1.2. Your violation of any of the terms and conditions of these
            Terms and Conditions, Privacy Policy or any other policy of the
            Company;
          </p>
          <p>
            1.3. Your violation of any third party right, including without
            limitation, any copyright, property, or privacy right; or
          </p>
          <p>
            1.4. any claim that Your use of the Site has caused damage to a
            third party. This defense and indemnification obligation will
            survive these Terms and Conditions.
          </p>
          <p>
            2. In no event shall the Company, its officers, directors,
            employees, partners or agents be liable to You or any third party
            for any damages (including special, incidental, indirect,
            consequential or punitive damages) including those resulting from
            loss of use, data or profits or any other claim arising out, of or
            in connection with, Your use of, or access to, the Site.
          </p>
          <p>
            <strong>N. FEEDBACK</strong>
          </p>
          <p>
            1. Any feedback You provide with respect to the Site shall be deemed
            to be nonconfidential. We shall be free to use such information on
            an unrestricted basis. Further, by submitting the feedback, You
            represent and warrant that: (i) Your feedback does not contain
            confidential or proprietary information of You or of third parties;
            (ii) the Company is not under any obligation of confidentiality,
            express or implied, with respect to the feedback; and (iii) You are
            not entitled to any&nbsp;compensation or reimbursement of any kind
            from the Company for the feedback under any circumstances, unless
            specified.
          </p>
          <p>
            2. Under no circumstances shall the Company be held responsible in
            any manner for any content provided by other users even such content
            is offensive, hurtful or offensive. Please exercise caution while
            accessing the Site.
          </p>
          <p>
            3. If You post any offensive language against the Company or any
            other user or by Your act You try to harm the Company’s reputation,
            on Our Site, on any social media platforms, at any discussion
            forums, or anywhere on the internet, the Company reserves the sole
            right to cancel and terminate Your account without any consequence
            to the Company. Notwithstanding, the Company reserves its sole right
            to take appropriate action or initiate appropriate legal proceedings
            against You in this connection.
          </p>
          <p>
            <strong>O. EQUITABLE RELIEF</strong>
          </p>
          <p>
            In the event You breach these Terms and Conditions, You agree that
            the Company will be irreparably harmed and may not have an adequate
            remedy in money or damages. The Company, therefore, shall be
            entitled in such event to obtain an injunction against such a breach
            from any court of competent jurisdiction. The Company's right to
            obtain such relief shall not limit its right to obtain other
            remedies in law or in contract.
          </p>
          <p>
            <strong>P. JURISDICTION AND GOVERNING LAW</strong>
          </p>
          <p>
            1. Any dispute or difference either in interpretation or otherwise,
            of the Terms Conditions and other policies of the Company on the
            site, between the parties hereto, shall be referred to an
            independent arbitrator who will be appointed by mutually and his
            decision shall be final and binding on the parties hereto. The above
            arbitration shall be in accordance with the Arbitration and
            Conciliation Act, 1996 as amended from time to time. The seat and
            venue of arbitration shall be held in New Delhi and the language
            shall be in English.
          </p>
          <p>
            2. Please note that these Terms and Conditions, their subject matter
            and their formation, are governed by the laws of India. You and We
            both agree that the courts of New Delhi, India will have exclusive
            jurisdiction.
          </p>
          <p>
            <strong>Q. MISCELLANEOUS</strong>
          </p>
          <p>
            1. Any violation by You of the terms and conditions of these Terms
            and Conditions may result in immediate suspension or termination of
            Your account(s) apart from&nbsp;any legal remedy that the Company
            can avail. In such instances, the Company may also disclose Your
            account Information if required by any Governmental or legal
            authority. You understand that the violation of these Terms and
            Conditions could also result in civil or criminal liability under
            applicable laws.
          </p>
          <p>
            2. The Company has the right to change modify, suspend, or
            discontinue and / or eliminate any aspect(s), features or
            functionality of the Site or the services or products as it deems
            fit at any time without prior notice.
          </p>
          <p>
            3. Further, the Company has the right to amend these Terms from time
            to time without prior notice. The Company makes no commitment,
            express or implied, to maintain or continue any aspect of the Site.
            You agree that the Company shall not be liable to You or any third
            party for any modification, suspension or discontinuance of the
            Site.
          </p>
          <p>
            4. Notice: All notices served by the Company shall be provided via
            email to Your account or as a general notification on the Site. Any
            notice to be provided to the Company should be sent to :
            complaints@merapashu360.com
          </p>
          <div className="mUsKed">
            <div className="oEr2hb"></div>
          </div>
          <p>
            5. Entire Agreement: The Terms and Conditions, along with the
            Privacy Policy, and any other guidelines made applicable to the Site
            from time to time, constitute the entire agreement between the
            Company and You with respect to Your access to or use of the Site
            and the services thereof.
          </p>
          <p>
            6. Assignment: You cannot assign or otherwise transfer Your
            obligations under the Terms and Conditions, or any right granted
            hereunder to any third party. The Company’s rights under the Terms
            and Conditions are freely transferable by the Company to any third
            parties without the requirement of seeking Your consent.
          </p>
          <p>
            7. Severability: If, for any reason, a court of competent
            jurisdiction finds any provision of the Terms and Conditions, or
            portion thereof, to be unenforceable, that provision shall be
            enforced to the maximum extent permissible so as to give effect to
            the intent of the parties as reflected by that provision, and the
            remainder of the Terms and Conditions shall continue in full force
            and effect.
          </p>
          <p>
            8. Waiver: Any failure by the Company to enforce or exercise any
            provision of the Terms and Conditions, or any related right, shall
            not constitute a waiver by the Company of that provision or right.
          </p>
          <p>
            9. Relationship: You acknowledge that Your participation on the
            Site, does not make You an employee or agency or partnership or
            joint venture or franchise of the Company&nbsp;
          </p>
          <p>
            10. The Company provides these Terms and Conditions so that You are
            aware of the terms that apply to Your use of the Site and the
            services and products. You acknowledge that, the Company has given
            You a reasonable opportunity to review these Terms and Conditions
            and that You have agreed to them. 11. The Company has its
            headquarters located at Darbaripur, Gurgaon
          </p>
          <p>R. GRIEVANCE OFFICER</p>
          <p>
            For any further queries and complaints related to these Terms and
            Conditions or services or products on the Site, or exercising Your
            rights, You could reach Us at:
          </p>
          <p>Name of the Grievance Officer: V Verma&nbsp;</p>
          <p>Contact Email Address: complaints@merapashu360.com</p>
          <div className="mUsKed">
            <div className="oEr2hb"></div>
          </div>
          <p>S. CUSTOMER CARE</p>
          <p>
            We make all best endeavours to provide You with a pleasant
            experience. In the unlikely event that You face any issues, please
            contact Us at complaints@merapashu360.com
          </p>
        </div>
      </div>
    </>
  );
}

export default Terms;
