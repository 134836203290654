import React, { useRef } from "react";
import AboutUs from "./Home/sections/AboutUs";
import LinkedInIcon from "../assets/linkedin_icon.svg";
import Button from "../components/Button/Button";
import { useQuery } from "@apollo/client";
import loader from "../assets/loader.json";
import Lottie from "lottie-react";
import kilo from "../assets/kilo.png";
import masti from "../assets/masti.png";
import pogo from "../assets/pogo.png";
import kanupriya from "../assets/knnu.jpg";
import prachi from "../assets/prachi.png";
import amit from "../assets/amit.png";
import tumul from "../assets/tumul.png";
import rupish from "../assets/rupish.png";
import investorsImg from "../assets/investor.webp";
import teamPic from "../assets/teamPic.webp";
import news from "../assets/news.jpeg"
import newsArticle from "../assets/newsThumbnail.jpg"
// import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import leftArrow from '../assets/left-arrow.svg';
// import rightArrow from '../assets/right-arrow.svg';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { GET_DATA } from '../gql/gql';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import leftArrow from "../assets/left-arrow.svg";
import rightArrow from "../assets/right-arrow.svg";
import { Carousel } from "react-responsive-carousel";
import ImpactSection from "./ImpactSection";
import AajTak from "../assets/AajTak.png";


function AboutUsPage() {
  let { t } = useTranslation();
  function LeaderCard({
    img,
    name,
    post,
    desc,
    linkedInUrl,
  }: {
    img: string;
    name: string;
    post: string;
    desc?: string;
    linkedInUrl: string;
  }) {
    return (
      <div className="leadership__card">
        <div className="leadership__card--image">
          <img src={img} alt="leader" />
        </div>
        <div className="leadership__card--details">
          <h1>{name}</h1>
          <p>{post}</p>
          <p>{desc}</p>
          <a href={linkedInUrl} target={"_blank"} rel="noreferrer">
            <img
              src={LinkedInIcon}
              alt="linkedIn"
              className="leadership__card--linkedin"
            />
          </a>
        </div>
      </div>
    );
  }

  const {
    loading: dataLoading,
    error: dataError,
    data: staticData,
  } = useQuery(GET_DATA);

  if (dataError) {
    console.log(dataError);
  }

  /** ----------------- SWIPE CAROUSAL FEATURE ---------------------- */
  // const [touchStart, setTouchStart] = React.useState(null);
  // const [touchEnd, setTouchEnd] = React.useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  // const minSwipeDistance = 1;

  // const onTouchStart = (e: any) => {
  //   setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
  //   setTouchStart(e.targetTouches[0].clientX);
  // };

  // const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  // const onTouchEnd = () => {
  //   if (!touchStart || !touchEnd) return;
  //   const distance = touchStart - touchEnd;
  //   const isLeftSwipe = distance > minSwipeDistance;
  //   const isRightSwipe = distance < -minSwipeDistance;
  //   if (isLeftSwipe || isRightSwipe) {
  //     if (isLeftSwipe) {
  //       next();
  //     } else {
  //       prev();
  //     }
  //   }
  // };

  /** --------------------------------------------------------------------------------------- */

  const createCarouselItemImage = (data: any, index: any, options = {}) => (
    <div
      key={index}
      className="youtube__container-slide"
      // onTouchStart={onTouchStart}
      // onTouchMove={onTouchMove}
      // onTouchEnd={onTouchEnd}
    >
      <div className="youtube__container-article">
        <img src={data.image} alt="sd" />
        <p className="hindi">{data.title}</p>
        <a target={"_blank"} rel="noopener noreferrer" href={data.link}>
          View Full Article
        </a>
      </div>
    </div>
  );

  let data = [
    {
      image: newsArticle,
      title: "गाय और भैंस की होगी Home Delivery, करोड़ों का पैकेज छोड़ 4 युवाओं ने शुरू की Mera Pashu 360 नाम की कंपनी",
      link: "https://www.gnttv.com/business/story/home-delivery-cow-and-buffalo-4-youth-left-crores-of-package-started-company-mera-pashu-360-930339-2024-03-13"
    },
    {
      image: "https://www.dailypioneer.com/uploads/2024/story/images/big/merapashu360--building-a-doorway-to-rural-e-commerce-with-cattle-feed-2024-02-24.jpg",
      title: "MeraPashu360: Building a doorway to rural e-commerce with cattle feed",
      link: "https://www.dailypioneer.com/2024/state-editions/merapashu360--building-a-doorway-to-rural-e-commerce-with-cattle-feed.html"
    },
    {
      image: news,
      title: "Online Cattle Purchase And Home Delivery Made Possible By Indian Start-up",
      link: "https://www.youtube.com/watch?v=sNGwxnRrQ9Y&ab_channel=IndiaToday"
    },
    {
      image:"https://feeds.abplive.com/onecms/images/uploaded-images/2024/05/07/fc921dd6b4d11e4e1ab06e8256eae60d171509061219225_original.jpg?impolicy=abp_cdn&imwidth=1200&height=675",
      title: "Now, An App For Home Delivery Of Cattle: How MeraPashu360 By IIT Madras Alumni Aims To Help Dairy Farmers",
      link: "https://news.abplive.com/agriculture/merapashu360-startup-meeting-rural-dairy-farming-needs-with-doorstep-delivery-of-cattle-and-feed-1685839"
    },
    {
      image:AajTak,
      title: "Mera Pashu 360: गाय और भैंस की होगी Home Delivery, करोड़ों का पैकेज छोड़ शुरू की कंपनी || AI Sana",
      link: "https://www.youtube.com/watch?v=wpUPZchv6lE&ab_channel=AajtakAI"
    },

    /*/  - Not required as of now. Consult Varun before reactivating it.
    {
      image: "https://klubworks.ghost.io/content/images/2023/07/mera-pashu-blog-banner--1-.png",
      title: "Unveiling India's $24 Billion Agri-Tech Empire: 3 Key Insights You Can't Miss",
      link: "https://klubworks.com/blog/unveiling-indias-24-billion-agri-tech-empire-3-key-insights-you-cant-miss"
    },
    {
      image:
        "https://static.langimg.com/thumb/msid-96098014,imgsize-160180,width-700,height-525,resizemode-75/mera-pashu-360-96098014.jpg",
      title:
        "Success Story: गुड़ खिलाकर दूध बढ़ाने या सरसों तेल पिलाकर फैट बढ़ाने जैसे धोखे से किसानों को बचाने की पहल, मेरा पशु 360 के दिव्यांशु तांबे की कहानी जानिए",
      link: "https://hindi.economictimes.com/sme/success-story-of-mera-pashu-360-divyanshu-tambe-know-details/articleshow/96097982.cms",
    },
    {
      image:
        "https://static.langimg.com/thumb/msid-96021491,imgsize-48430,width-700,height-525,resizemode-75/96021491.jpg",
      title:
        "मुर्रा, भदावारी, सुरती या मेहसाना, भैंस खरीदने के लिए लांच हुआ एप, मेरा पशु 360 की सेवा लेकर हो जाएं निश्चिंत",
      link: "https://hindi.economictimes.com/sme/mera-pashu-360-buy-sell-buffalo-cattlefeed-know-details/articleshow/96020906.cms",
    },
    {
      image: "https://inc42.com/wp-content/uploads/2023/04/ftr-760x570.png",
      title:
      "Meet The Five Social Impact Tech Startups From 9Unicorns’ DDay 3 Cohort",
      link: "https://inc42.com/buzz/meet-the-five-social-impact-tech-startups-from-9unicorns-dday-3-cohort/",
    },
    //*/
    {
      image: "https://cdn.techinasia.com/wp-content/uploads/2023/04/1681112564_Impact-article.png",
      title:
        "Startups Driving Environmental and Socio-Economic Impact in India",
      link: "https://www.techinasia.com/startups-driving-environmental-socioeconomic-impact-india",
    },
  ];
 
  const baseChildren = (
    <div>
      {data.map((item, index) => {
        return createCarouselItemImage(item, index);
      })}
    </div>
  );

  let [currentSlide, setCurrentSlide] = React.useState<any>(0);
  // let [count, setCount] = React.useState<any>(1);

  // const prev = () => {
  //   let isMobile = window.matchMedia(
  //     "only screen and (max-width: 480px)"
  //   ).matches;
  //   let slider = Array.from(
  //     document.getElementsByClassName("slider") as HTMLCollectionOf<HTMLElement>
  //   )[0];

  //   if (count > 1) {
  //     setCount(count - 1);
  //     if (isMobile) {
  //       slider.style.transform = `translateX( ${(count - 2) * -109}%)`;
  //     } else {
  //       slider.style.transform = `translateX( ${(count - 1) * -30.5}%)`;
  //     }
  //   } else if (count === 1) {
  //     if (isMobile) {
  //       slider.style.transform = `translateX(0)`;
  //     } else {
  //       slider.style.transform = `translateX(0%)`;
  //     }
  //   }
  // };

  // const next = () => {
  //   let isMobile = window.matchMedia(
  //     "only screen and (max-width: 480px)"
  //   ).matches;
  //   let slider = Array.from(
  //     document.getElementsByClassName("slider") as HTMLCollectionOf<HTMLElement>
  //   )[0];

  //   if (isMobile) {
  //     if (count >= 5) {
  //       // 5 to be replace by slide array length
  //     } else {
  //       slider.style.transform = `translateX( ${count * -111}%)`;
  //       setCount(count + 1);
  //     }
  //   } else {
  //     if (count <= Math.floor(5 / 3) + 1) {
  //       // 5 to be replace by slide array length
  //       slider.style.transform = `translateX( ${(count + 1) * -30.5}%)`;
  //       setCount(count + 1);
  //     }
  //   }
  // };

  const updateCurrentSlide = (index: any) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };
  const leftHandler = useRef(() => {});
  const rightHandler = useRef(() => {});
  return (
    <>
      <Helmet>
        <title>About Us | MeraPashu360</title>

        <meta
          name="description"
          content="Merapashu360 provides every dairy farmer with the convenience of ordering quality cattle feed and numerous options of high-quality cattle from the comfort of their home through the app."
        />
        <link rel="canonical" href="https://merapashu360.com/about-us/" />
      </Helmet>
      {dataLoading ? (
        <Lottie animationData={loader} loop={true} />
      ) : (
        <>
          <AboutUs
            sub={t("who_are_we")}
            heading={t("know_us")}
            desc={t("about_us_desc")}
            watchFilm
            stats={staticData.siteData.stats}
          />
          <section className="news">
            <div className="news__container">
              <div className="subheading green">{t("featured")} </div>
              <div className="heading">{t("media_coverage")}</div>

              <div className="youtube__container">
                <Carousel
                  className="carousel__aboutus"
                  showArrows={true}
                  showThumbs={false}
                  interval= {6000}
                  infiniteLoop={true}
                  swipeable={true}
                  selectedItem={currentSlide}
                  onChange={updateCurrentSlide}
                  showIndicators={false}
                  showStatus={false}
                  autoPlay={true}
                  renderArrowPrev={(clickHandler, hasNext, label) => {
                    leftHandler.current = clickHandler;
                    return (
                      <button
                        className="youtube__container__button youtube__container__button--left"
                        onClick={clickHandler}
                      >
                        <img src={leftArrow} alt="Left Arrow" />
                      </button>
                    );
                  }}
                  renderArrowNext={(clickHandler, hasPrev, label) => {
                    rightHandler.current = clickHandler;
                    return (
                      <button
                        className="youtube__container__button youtube__container__button--right"
                        onClick={clickHandler}
                      >
                        <img src={rightArrow} alt="Right Arrow" />
                      </button>
                    );
                  }}
                >
                  {data && baseChildren.props.children}
                </Carousel>
              </div>

                <div className="youtube__buttons-mobile">
                  <div className="youtube__button--mobile" onClick={leftHandler.current}>
                    <img src={leftArrow} alt="Right Arrow" />
                  </div>
                  <div className="youtube__button--mobile" onClick={rightHandler.current}>
                    <img src={rightArrow} alt="Right Arrow" />
                  </div>
                </div>

              {/* <div className="youtube__carousel news__carousel"> */}
              {/* <button
                  className="youtube__button youtube__button--left"
                  onClick={prev}
                >
                  <img src={leftArrow} alt="Left Arrow" />
                </button> */}
              {/* <Carousel
                  showArrows={false}
                  showThumbs={false}
                  selectedItem={currentSlide}
                  onChange={updateCurrentSlide}
                  showIndicators={false}
                  showStatus={false}
                  swipeable={false}
                >
                  {baseChildren.props.children}
                </Carousel> */}
              {/*
                <button
                  className="youtube__button youtube__button--right"
                  onClick={next}
                >
                  <img src={rightArrow} alt="Right Arrow" />
                </button> */}
              {/* </div> */}
              {/* <div className="youtube__container">
                {baseChildren.props.children}
              </div> */}
            </div>
          </section>
          <ImpactSection />
          <section className="leadership">
            <div className="subheading green">{t("force_behind")}</div>
            <div className="heading">{t("meet_founding_team")}</div>

            <div className="leadership__cards">
            
              <LeaderCard
                name={t("niket")}
                post={t("niket_post")}
                linkedInUrl="https://www.linkedin.com/in/agrawalniket/"
                img={pogo}
                desc={t("niket_desc")!}
              />
              <LeaderCard
                name={t("varun")}
                post={t("varun_post")}
                linkedInUrl="https://www.linkedin.com/in/varunvermaiitm/"
                img={masti}
                desc={t("varun_desc")!}
              />
              <LeaderCard
                name={t("kanu")}
                post={t("kanu_post")}
                linkedInUrl="https://www.linkedin.com/in/kanupriya-saldi-6a28a815/"
                img={kanupriya}
                desc={t("kanu_desc")!}
              />
              <LeaderCard
                name={t("divyanshu")}
                post={t("divyanshu_post")}
                linkedInUrl="https://www.linkedin.com/in/divyanshu-tambe-kilo-9892444/"
                img={kilo}
                desc={t("divyanshu_desc")!}
              />
            </div>

            <div className="heading leadership__management">
              {t("meet_leadership_team")}
            </div>
            <div className="leadership__cards">
         
              
              <LeaderCard
                name={t("tumul")}
                post={t("tumul_post")}
                linkedInUrl="https://www.linkedin.com/in/tumul-singh-4aa54b1/"
                desc={t("tumul_desc")!}
                img={tumul}
              />
              <LeaderCard
                name={t("amit")}
                post={t("amit_post")}
                linkedInUrl="https://www.linkedin.com/in/amitunc/"
                desc={t("amit_desc")!}
                img={amit}
              />

              <LeaderCard
                name={t("prachi")}
                post={t("prachi_post")}
                desc={t("prachi_desc")!}
                linkedInUrl="https://www.linkedin.com/in/prachi-madan-08198410/"
                img={prachi}
              />
              <LeaderCard
                name={t("rupish")}
                post={t("rupish_post")}
                desc={t("rupish_desc")!}
                linkedInUrl="https://www.linkedin.com/in/rupishsaldi/"
                img={rupish}
              />
            </div>
          </section>

          <section className="join">
            <div className="join__left">
              <p className="subheading green">{t("we_are_hiring")}</p>
              <p className="heading">{t("join_us")}</p>

              <p className="join__desc">{t("we_are_growing")}</p>

              <div className="hub__locations--card--available join__mail">
                {t("mail_us")}:{" "}
                <a href="mailto:ir@merapashu360.com">
                  careers@merapashu360.com
                </a>
              </div>

              <Button
                outline
                text={t("see_job_openings")}
                color="#2CAC4C"
                to="https://www.linkedin.com/company/merapashu360/jobs/"
              />
            </div>

            <div className="join__media">
              <img src={teamPic} alt="temPic" />
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default AboutUsPage;
