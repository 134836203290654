import starIcon from '../../../assets/start_nobg.svg';
import { useTranslation, Trans } from 'react-i18next';

type Prop = {
  data: {
    url: string;
    rating: string;
    name: string;
    text: string;
    village: string;
    title: string;
  };
};

function Testimonials({ data }: Prop) {
  const { t } = useTranslation();
  return (
    <section className="testimonial">
      <div className="testimonial__header">
        <div className="subheading green">{t('know')}</div>
        <div className="heading">
          {
            <Trans
              i18nKey="customers_say"
              components={{
                span: <span className="green" />,
              }}
            />
          }
        </div>
      </div>
      <div className="testimonial__container">
        <div className="testimonial__card">
          <p className="testimonial__card--title">{data.title}</p>
          <div className="testimonial__card--text">
            <div className="testimonial__card--stars">
              {[1, 2, 3, 4, 5].map((i: any, index: number) => (
                <img
                  key={index}
                  src={starIcon}
                  alt="Star Icon"
                  className="testimonial__card--star"
                />
              ))}
            </div>

            <div className="testimonial__card--quote">{data.text}</div>

            <div className="testimonial__card--sign-name">{data.name}</div>
            <div className="testimonial__card--sign-location">
              {data.village}
            </div>
          </div>
        </div>
        <div className="testimonial__media">
          <iframe
            width={'100%'}
            height={'100%'}
            src={data.url}
            title="YouTube video player"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
