import React, { useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Button from '../../../components/Button/Button';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import leftArrow from '../../../assets/left-arrow.svg';
import rightArrow from '../../../assets/right-arrow.svg';
import { useTranslation } from 'react-i18next';

type Prop = {
  data: [
    {
      text: string;
      url: string;
    }
  ];
};

function Youtube({ data }: Prop) {
  /** ----------------- SWIPE CAROUSAL FEATURE ---------------------- */
  const { t } = useTranslation();
  /** --------------------------------------------------------------------------------------- */

  const createCarouselItemImage = (data: any, index: any, options = {}) => (
    <div
      key={index}
      className="youtube__slide"
    >
      <div className="youtube__slide--image">
        <iframe
          width={'100%'}
          height={'100%'}
          src={data.url}
          title="YouTube video player"
        ></iframe>
      </div>
      <p className="youtube__slide--text">{data.title}</p>
    </div>
  );

  const baseChildren = (
    <div>
      {data.map((item, index) => {
        return createCarouselItemImage(item, index);
      })}
    </div>
  );

  let [currentSlide, setCurrentSlide] = React.useState<any>(0);

  const updateCurrentSlide = (index: any) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };
  const leftHandler = useRef(()=>{});
  const rightHandler = useRef(()=>{});

  return (
    <div className="youtube">
      <div className="youtube__header">
        <div className="youtube__heading">
          <div className="youtube__heading--subheading subheading white">
            {t('videos')}
          </div>
          <div className="youtube__heading--main heading white">
            {t('asked_answered')}
          </div>
        </div>
        <div className="youtube__cta">
          {/* <a
            href="https://www.youtube.com/@MeraPashu360"
            target={'_blank'}
            rel="noreferrer"
          > */}
            <Button
              text={t('go_to_youtube')}
              to="https://www.youtube.com/@MeraPashu360"
              outline
              color="#ffffff"
              rightArrow
            />
          {/* </a> */}
        </div>
      </div>
      <div className="youtube__carousel">
        <Carousel
          className='carousel__youtube'
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          swipeable= {true}
          selectedItem={currentSlide}
          onChange={updateCurrentSlide}
          showIndicators={false}
          showStatus={false}
          autoPlay={true}
          renderArrowPrev={(clickHandler, hasNext, label) => {
            leftHandler.current = clickHandler
            return (
              <button
                className="youtube__button youtube__button--left"
                onClick={clickHandler}
              >
                <img src={leftArrow} alt="Left Arrow" />
              </button>
            )
          }}
          renderArrowNext={
            (clickHandler, hasPrev, label) => {
              rightHandler.current = clickHandler
              return (
                <button
                  className="youtube__button youtube__button--right"
                  onClick={clickHandler}
                >
                  <img src={rightArrow} alt="Right Arrow" />
                </button>
              )
            }
          }
        >
          {data && baseChildren.props.children}
        </Carousel>
      </div>
      <div className="youtube__buttons-mobile">
        <div className="youtube__button--mobile" onClick={leftHandler.current}>
          <img src={leftArrow} alt="Right Arrow" />
        </div>
        <div className="youtube__button--mobile" onClick={rightHandler.current}>
          <img src={rightArrow} alt="Right Arrow" />
        </div>
      </div>
    </div>
  );
}

export default Youtube;
