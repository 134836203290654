import DOCUMENT from "../../../assets/document.svg";
import APPSCREEN from "../../../assets/phone.webp";
import APPSCREENPASHU from "../../../assets/phone_pashu.png";
import TRUCK from "../../../assets/truck_icon.svg";
import Button from "../../../components/Button/Button";
import binola from "../../../assets/Binola-Khal.webp";
import chaana_chilkaa from "../../../assets/chaana_chilkaa.webp";

import goli_khal from "../../../assets/Goli-Khal.webp";
import mineral from "../../../assets/Mineral-Mix.webp";
import silage from "../../../assets/Silage.webp";
import tickIcon from "../../../assets/tick.svg";
import { useTranslation, Trans } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Fragment } from "react";
type StepPropType = {
  icon: string;
  action: string;
  desc: string;
  link?:string;
  
};

let feeds = [
 
  {
    title: "khal",
    sub: "binola_khal_sub",
    icon: binola,
    link:"https://merapashu360.com/feed/khal",
    
  },
  {
    title: "goli_khal",
    sub: "goli_khal_sub",
    icon: goli_khal,
    link:"https://merapashu360.com/feed/goli_khal",
  },
  {
    title: "silage",
    sub: "silage_sub",
    icon: silage,
    link:"https://merapashu360.com/feed/silage",
  },
  {
    title: "mineral_mix",
    sub: "mineral_mix_sub",
    icon: mineral,
    link:"https://merapashu360.com/feed/mineral_mix",
  },
  {
    title: "traditional_khal",
    sub: "traditional_khal_sub",
    icon: chaana_chilkaa,
    link:"https://merapashu360.com/feed/traditional_khal",
  },
];

function Step({ icon, action, desc,link }: StepPropType) {
  return (
    <>
      <div className="syc__info--step">
        <img src={icon} alt="Document Icon" className="syc__info--step--icon" />
        <p className="syc__info--step--action ">{action}</p>
        <p className="syc__info--step--desc">{desc}</p>
      </div>
    </>
  );
}

function StepFeed({ icon, action, desc, link }: StepPropType) {
  let { t } = useTranslation();
  return (
    <div className="syc__info--step-feed">
      <a href={link || '/'} style={{ textDecoration: 'none', color: 'inherit' }}>
        <img
          src={icon}
          alt="Feed Icon"
          className="syc__info--step-feed--icon"
        />
        <p className="syc__info--step-feed--action">{t(action)}</p>
        <p className="syc__info--step-feed--desc">{t(desc)}</p>
      </a>
    </div>
  );
}


const settings = {
  showArrows: true,
  interval: 3500,
  dynamicHeight: false,
  stopOnHover: false,
  infiniteLoop: true,
  showStatus: false,
  transitionTime: 900,
  showThumbs: false,
  showIndicators: true,
  swipeable: true,
  emulateTouch: true,
  autoPlay: true,
};

function SYC() {
  let buttonSectionIdMap: any = {
    sellCattle: "sell-cattle",
    buyCattle: "buy-cattle",
    buyFeed: "buy-feed",
  };
  let { t } = useTranslation();

  let handleClick = (e: any) => {
    // change background to golden yellow for selected button.
    let alreadySelected = document.querySelectorAll(".syc__button--selected");
    alreadySelected?.forEach((el) => {
      el.classList.remove("syc__button--selected");
    });
    let targetBtn = document.getElementById(e.target.id);
    targetBtn?.classList.add("syc__button--selected");

    // display

    let alreadySelectedSection = document.querySelectorAll(
      ".syc__content--selected"
    );
    alreadySelectedSection.forEach((el) => {
      el.classList.remove("syc__content--selected");
    });

    let targetSection = document.getElementById(
      buttonSectionIdMap[e.target.id]
    ) as HTMLElement;
    if (targetSection?.style) {
      targetSection.classList.add("syc__content--selected");
    }
  };
  return (
    <div className="syc">
      <div className="syc__overlay">
        <div className="syc__container ">
          {/*--------------------------- SELL CATTLE -------------------------------------------*/}
          <div className="syc__content" id="sell-cattle">
            <div className="syc__info">
              <div className="syc__info--subheading subheading">
                {t("cattle_price")}
              </div>

              <div className="syc__info--heading heading">
                SELL YOUR BHAINS IN ONE CLICK AND
                <span className="green"> GET BEST PRICE</span>
              </div>

              <div className="syc__info--steps">
                <Step
                  icon={DOCUMENT}
                  action="Fill Form"
                  desc="Enter the basic details"
                  link={""}
                />
                <Step
                  icon={DOCUMENT}
                  action="Get Price"
                  desc="Know  your cattle price in 10 min"
                  link={""}
                />
                <Step
                  icon={TRUCK}
                  action="Cattle Pickup"
                  desc="Pickup of pashu after QC"
                  link={""}
                />
                <Step
                  icon={DOCUMENT}
                  action="Get Payment"
                  desc="Get money same day on pickup"
                  link={""}
                />
              </div>
              <div className="syc__info--button">
                <a href="https://merapashu360.com/sell-cattle">
                  <Button primary text="SELL YOUR CATTLE" rightArrow />
                </a>
                <Button text="FAQ" secondary to="#faq"/>
              </div>
            </div>
            <img src={APPSCREEN} alt="App Screen" className="syc__phone" />
          </div>

          {/*--------------------------- BUY CATTLE FEED -------------------------------------------*/}
          <div className="syc__content syc__content--selected" id="buy-feed">
            <div className="syc__info">
              <div className="syc__info--subheading subheading">
                {t("buy_cattle_feed_sub")}
              </div>

              <div className="syc__info--heading heading">
                <Trans
                  i18nKey="buy_cattle_feed_title"
                  components={{
                    a: (
                      <a
                        href="https://mp360.app/home"
                        className="green"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                      />
                    ),
                  }}
                />
              </div>

              <div className="syc__info--steps white">
                {feeds.map((feed, index: number) => (
                  <Fragment key={index}>
                    <StepFeed
                      icon={feed.icon}
                      action={feed.title}
                      desc={feed.sub}
                      link={feed.link}
                    />
                  </Fragment>
                ))}
              </div>
              <div className="syc__info--button">
                <Button
                  primary
                  text={t("browse_all_cattle_feed")}
                  rightArrow
                  to="/feed"
                />

                <Button text="FAQ" secondary to="#faq" />
              </div>
            </div>
            <img src={APPSCREEN} alt="App Screen" className="syc__phone" />
          </div>

          {/*--------------------------- BUY CATTLE -------------------------------------------*/}
          <div className="syc__content" id="buy-cattle">
            <div className="syc__info">
              <div className="syc__info--subheading subheading">
                {t("purachase_cattle")}
              </div>

              <div className="syc__info--heading heading">
                <Trans
                  i18nKey={"browse_bhains"}
                  components={{
                    a: (
                      <a
                        href="https://mp360.app/buffalo"
                        className="green"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                      />
                    ),
                  }}
                />
              </div>

              <ol className="download__feats" style={{ color: "white" }}>
                <li className="download__feats--feat">
                  <img
                    src={tickIcon}
                    alt=""
                    className="download__feats--feat--icon"
                  />
                  <p className="download__feats--feat--text">{t("new_lac")}</p>
                </li>

                <li className="download__feats--feat">
                  <img
                    src={tickIcon}
                    alt=""
                    className="download__feats--feat--icon"
                  />
                  <p className="download__feats--feat--text">
                    {t("milk_yeild")}
                  </p>
                </li>
                <li className="download__feats--feat">
                  <img
                    src={tickIcon}
                    alt=""
                    className="download__feats--feat--icon"
                  />
                  <p className="download__feats--feat--text">{t("checked")}</p>
                </li>
              </ol>
              {/* </div> */}
              <div className="syc__info--button">
                <Button
                  primary
                  text={t("browse_all_bhains")}
                  rightArrow
                  to="/buy-cattle"
                />

                {/* <Button text="BUY IN 3 EASY STEPS" secondary to="#faq" /> */}
              </div>
            </div>
            <img src={APPSCREENPASHU} alt="App Screen" className="syc__phone" />
          </div>

          {/*--------------------------- BUTTONS -------------------------------------------*/}
          <div className="syc__buttons">
            <button
              className="syc__button syc__button--selected"
              onClick={(e) => handleClick(e)}
              id="buyFeed"
            >
              {t("buy_cattle_feed")}
            </button>
            <button
              className="syc__button"
              onClick={handleClick}
              id="buyCattle"
            >
              {t("buy_cattle")}
            </button>
            <button
              className="syc__button"
              onClick={(e) => handleClick(e)}
              id="sellCattle"
            >
              {t("get_estimated_price")}
            </button>
          </div>
        </div>
        <div className="syc__container--mobile">
          <Carousel {...settings}>
            <div className="syc__content--mobile" id="buy-feed">
              <div className="syc__info">
                <div className="syc__info--subheading subheading">
                  {t("buy_cattle_feed_sub")}
                </div>

                <div className="syc__info--heading heading">
                  <Trans
                    i18nKey="buy_cattle_feed_title"
                    components={{
                      a: (
                        <a
                          href="https://mp360.app/home"
                          className="green"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        />
                      ),
                    }}
                  />
                </div>
                <br></br>
                <div className="syc__info--steps white">
                  {feeds.map((feed, index: number) => (
                    <StepFeed
                      key={index}
                      icon={feed.icon}
                      action={feed.title}
                      desc={feed.sub}
                      link={feed.link}
                    />
                  ))}
                </div>
                <div className="syc__info--button">
                  <Button
                    className="syc__info--button__btn carouselbtn1"
                    primary
                    text={t("browse_all_cattle_feed")}
                    to="/feed"
                  />
                </div>
              </div>
            </div>

            <div className="syc__content--mobile" id="buy-cattle">
              <div className="syc__info">
                <div className="syc__info--subheading subheading">
                  {t("purachase_cattle")}
                </div>

                <div className="syc__info--heading heading">
                  <Trans
                    i18nKey={"browse_bhains"}
                    components={{
                      a: (
                        <a
                          href="https://mp360.app/buffalo"
                          className="green"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        />
                      ),
                    }}
                  />
                </div>

                <ol className="download__feats" style={{ color: "white" }}>
                  <br></br>
                  <li className="download__feats--feat">
                    <img
                      src={tickIcon}
                      alt=""
                      className="download__feats--feat--icon"
                    />
                    <p className="download__feats--feat--text">
                      {t("new_lac")}
                    </p>
                  </li>

                  <li className="download__feats--feat">
                    <img
                      src={tickIcon}
                      alt=""
                      className="download__feats--feat--icon"
                    />
                    <p className="download__feats--feat--text">
                      {t("milk_yeild")}
                    </p>
                  </li>
                  <li className="download__feats--feat">
                    <img
                      src={tickIcon}
                      alt=""
                      className="download__feats--feat--icon"
                    />
                    <p className="download__feats--feat--text">
                      {t("checked")}
                    </p>
                  </li>
                </ol>
                {/* </div> */}
                <div className="syc__info--button carouselbtn2">
                  <Button
                    primary
                    text={t("browse_all_bhains")}
                    to="/buy-cattle"
                  />
                </div>
              </div>
            </div>
            <div className="syc__content--mobile" id="sell-cattle">
              <div className="syc__info">
                <div className="syc__info--subheading subheading">
                  {t("cattle_price").toUpperCase()}
                </div>

                <div className="syc__info--heading heading">
                  <span className="green"> KNOW YOUR CATTLE PRICE </span>
                  IN 2 EASY STEPS
                </div>

                <div className="syc__info--steps">
                  <Step
                    icon={DOCUMENT}
                    action="Fill Form"
                    desc="Enter the basic details"
                    link={""}
                  />
                  <Step
                    icon={DOCUMENT}
                    action="Get Price"
                    desc="Know  your cattle price in 10 min"
                    link={""}
                  />
                  <Step
                    icon={TRUCK}
                    action="Cattle Pickup"
                    desc="Pickup of pashu after QC"
                    link={""}
                  />
                  <Step
                    icon={DOCUMENT}
                    action="Get Payment"
                    desc="Get money same day on pickup"
                    link={""}
                  />
                </div>
                <div className="syc__info--button">
                  <Button
                    className="syc__info--button__btn"
                    primary
                    text="Get Estimated Price"
                    to="https://merapashu360.com/sell-cattle"
                  />
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default SYC;
