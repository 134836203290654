import tickIcon from "../../../assets/tick.svg";
import phone from "../../../assets/appInstallBannerImage.webp";
import Button from "../../../components/Button/Button";
import { useTranslation, Trans } from "react-i18next";

function DownloadMP360() {
  let { t } = useTranslation();
  return (
    <section className="download">
      <div className="download__content">
        <div className="download__subheading subheading">
          {t("merapashu_app")}
        </div>
        <div className="download__heading heading">
          <Trans
            i18nKey="download_app_title"
            components={{
              a: (
                <a
                  href="https://mp360.app/home"
                  className="green"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                />
              )
            }}
          />
        </div>
        <br></br>
        <ol className="download__feats">
          <li className="download__feats--feat">
            <img
              src={tickIcon}
              alt=""
              className="download__feats--feat--icon"
            />
            <div className="download__feats--feat--text hindi">
              <Trans
                i18nKey="browse_cattle"
                components={{
                  a: (
                    <a
                      href="https://merapashu360.com/buy-cattle"
                      rel="noreferrer"
                      style={{ textDecoration: "none" ,
                      color:"black"
                    }}
                    />
                  ),
                }}
              />
            </div>
          </li>
          <li className="download__feats--feat">
            <img
              src={tickIcon}
              alt=""
              className="download__feats--feat--icon"
            />
            <div className="download__feats--feat--text hindi">
            <Trans
                i18nKey="buy_feed"
                components={{
                  a: (
                    <a
                      href="https://merapashu360.com/feed"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color:"black" }}
                    />
                  ),
                }}
              />
              
            </div>
          </li>
          <li className="download__feats--feat">
            <img
              src={tickIcon}
              alt=""
              className="download__feats--feat--icon"
            />
            <div className="download__feats--feat--text hindi">
            <Trans
                i18nKey="sell_cattle"
                components={{
                  a: (
                    <a
                      href="https://merapashu360.com/sell-cattle"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color:"black" }}
                    />
                  ),
                }}
              />
            </div>
          </li>
          <li className="download__feats--feat">
            <img
              src={tickIcon}
              alt=""
              className="download__feats--feat--icon"
            />
            <div className="download__feats--feat--text hindi">
            <Trans
                i18nKey="get_vet"
                components={{
                  a: (
                    <a
                      href="https://merapashu360.com/contact-us"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color:"black" }}
                    />
                  ),
                }}
              />
            </div>
          </li>
        </ol>
        <div className="download__ctas">
          <div className="download__cta">
            <Button
              primary
              text={t("download_app")}
              playStore={true}
              to="https://play.google.com/store/search?q=merapashu360&c=apps"
            />
          </div>
          <div className="download__cta">
            <Button
              outline
              text={t("contact_us")}
              color="#2CAC4C"
              to="/contact-us"
            />
          </div>
        </div>
      </div>
      <div className="download__media">
        <img src={phone} alt="MP360 App" />
      </div>
    </section>
  );
}

export default DownloadMP360;
