import { Helmet } from 'react-helmet-async';
function Privacy() {
  return (
    <>
      <Helmet>
        <title>Privacy and Policy | MeraPashu360</title>

        <meta
          name="description"
          content="At MeraPashu360, we take privacy seriously. Our privacy policy outlines how we collect, use & protect personal information. Learn more about our privacy & policy."
        />
        <link rel="canonical" href="https://merapashu360.com/privacy-policies/" />
      </Helmet>
      <div className="terms--root">
        <h1 className="template-page--heading" data-item="section-heading">
          Privacy Policy
        </h1>

        <div data-item="rte-content">
          <p>
            <strong>A. INTRODUCTION</strong>
          </p>
          <p>
            1. MeraPashu360 Private Limited on behalf of itself and its
            affiliates / group companies under the brand ‘MeraPashu360’,
            (hereinafter referred to as “MeraPashu360”, “Us” or “We” or “Our” or
            “Company”), is the author and publisher of the website - 
            <a href='https://merapashu360.com'> https://merapashu360.com</a>, and the mobile application titled as -
            ‘MeraPashu360’ (together the “Site”). MeraPashu360 owns and operates
            the Site and the services provided through the Site.
          </p>
          <p>
            2. This privacy policy (“Privacy Policy”) constitutes a legal
            binding agreement between its users (“You” or “Your” or “User”) and
            the Company. Capitalized terms used but not defined herein, shall
            have the meaning assigned to such terms in the terms and conditions
            available at - <a href='https://merapashu360.com/terms-of-service'>https://merapashu360.com/terms-of-service</a> (“Terms”).
          </p>
          <p>
            3. The Company has adopted this Privacy Policy to set out the manner
            in which the Company handles the Information provided by You for the
            purposes in relation to Your use of the Site.
          </p>
          <p>
            4. This Privacy Policy also specifies the manner in which Your
            Information is collected, received, stored, processed, disclosed,
            transferred, dealt with and handled by the Company in relation to
            Your use of the Site. We reserve the right, in Our sole discretion,
            to terminate the access to the Site and / or other mediums at any
            time, without notice to You, for general maintenance or for any
            reason whatsoever.
          </p>
          <p>
            5. You are hereby advised to read this Privacy Policy carefully and
            fully understand the nature and purpose of gathering and / or
            collecting Your Information and other data or information and the
            usage, disclosure and sharing of such information. This Privacy
            Policy does not cover information you may choose to share with other
            users of the Site. This Privacy Policy applies to all Your personal
            data and Information with Us, whether in physical or electronic
            mode. This Privacy Policy is meant to help You understand what
            information We collect, why We collect it, and how You can update,
            manage, and delete Your Information and shall be read together with
            the Terms. Use of Our Site and services is governed by the Terms.
          </p>
          <p>
            6. We may update this Privacy Policy to reflect changes to Our
            practices. We encourage You to periodically review this page for the
            latest information on Our privacy practices. We are not obliged to
            inform You specifically of any such updates, whether material or
            otherwise and it shall be Your responsibility to keep yourself
            updated on any changes to this Privacy Policy. The changes to the
            Privacy Policy will be effective immediately upon being posted on
            the Site, and Your continued use of the Site after the changes are
            effective will constitute Your acknowledgement and acceptance of
            terms of the revised Privacy Policy.
          </p>
          <p>
            7. Your use of the Site, Our services or otherwise giving Your
            information, constitutes consent to the terms contained in this
            Privacy Policy and You will be deemed to have read, understood and
            agreed to the practices and policies in this Privacy Policy. If you
            do not agree with the terms in this Privacy Policy, we request that
            You should exit the Site immediately and discontinue the use of Our
            services. By voluntarily providing Us with Your information, You are
            consenting to Our use, transfer, collection, storing, processing,
            handling, disclosing, deletion of it in accordance with applicable
            laws and this Privacy Policy.
          </p>
          <p>
            8. By accessing or using Our Site, You give Your express consent for
            collection, usage, storage, deletion, processing, handling,
            disclosing, transferring, and sharing Your Information with Us and
            with third parties or service providers for the purposes set out in
            this Privacy Policy. We may use such Information You provide
            (including any changes thereto as provided by You) for any of the
            products or services that We offer and for Our internal records and
            other purposes as mentioned in the Privacy Policy.
          </p>
          <p>
            9. If You reside outside India Your Information will be collected,
            used, stored, deleted, processed, handled, disclosed, transferred,
            and shared in accordance with the applicable laws of India.
          </p>
          <p>
            10. This Privacy Policy constitutes an electronic record in
            accordance with the provisions of the Information Technology Act,
            2000.
          </p>
          <p>
            <strong>B. DEFINITIONS</strong>
          </p>
          <p>
            1. “Personal Information” shall have the meaning ascribed to it
            under Section 2(i) of the Information Technology (Reasonable
            Security Practices and Procedures and Sensitive Personal Data or
            Information Rules), 2011.
          </p>
          <p>
            2. “Sensitive Personal Information” shall have the meaning ascribed
            to it under Section 3 of the Information Technology (Reasonable
            Security Practices and Procedures and Sensitive Personal Data or
            Information Rules), 2011.
          </p>
          <p>
            3. “Information” shall mean collectively, Your Personal Information
            and Sensitive Personal Information or data or any other information
            collected from You.
          </p>
          <p>
            <strong>C. APPLICABILITY</strong>
          </p>
          <p>
            1. This Privacy Policy describes Our policies and procedures on the
            collection, use, storage and disclosure of any information
            including, business or personal information provided by You while
            using Our Site. This Privacy Policy specifically governs the
            collection and use of Your Information provided by You and the
            processing of Information provided by You while using the Site.
          </p>
          <p>
            2. During the course of Your association with Us, You may be
            required to execute certain other agreements and such agreements and
            this Privacy Policy shall, together with the Terms unless explicitly
            specified to the contrary, govern Your relationship with Us.
          </p>
          <p>
            <strong>D. INFORMATION WE COLLECT</strong>
          </p>
          <p>
            1. Information That You Provide: We collect personally identifiable
            information about You which You affirmatively choose to submit to
            Us, including Your Personal Information and Your Sensitive Personal
            Information. When You engage in certain activities on the Site such
            as creating an account, using Our Services for discovering animals,
            purchasing any animal feed, filling out a survey, requesting
            information about Our services, booking veterinary consultation,
            participating in competitions organized by Us, We may ask You to
            provide certain information about yourself. Engaging in these
            activities is optional and if You engage in the aforementioned
            activities, We may ask You to provide certain Personal Information
            including first and last name, email address, pin-code, address,
            telephone or mobile number, date of birth, age, identity number,
            photograph etc.
          </p>
          <p>
            2. From time to time we may automatically collect certain
            information that may be considered Personal Information or Sensitive
            Personal Information as the case may be, such as the IP address of
            Your computer or network connection, Your location, information
            about Your internet connection and mobile device, cookie
            information, web beacon information and details of the web pages You
            visit, about Your preferences and settings such as time zone and
            language, Your searches and the results You selected from the
            searches etc. We may also collect information from You during phone
            calls, or via e-mail correspondences with Our representative. We may
            also collect any content that You create or upload when using Our
            Services. This may include, but is not limited to, data like
            pictures, cattle related information (or other personal documents
            that You choose to upload), calendar reminders, and services
            requests to be performed by a third-party provider.
          </p>
          <p>
            3. Payment Processing Information: We may collect information
            related to the manner and mode of payments usually adopted by You on
            the Site, including details of Your bank account, and details of
            other e-wallet accounts or any other payment information.
          </p>
          <p>
            4. Usage Information: We may collect other information such as the
            time and date of Your visit, Your location, the pages and other
            content that You access, and the number of times You return to the
            website. This information is used in the aggregate for Our internal
            marketing and similar purposes. We may also integrate with the
            website technology provided by third parties (e.g., Google
            Analytics) to accomplish this information collection. In addition,
            We may use a variety of other&nbsp;technologies that collect similar
            information for security and fraud detection purposes.
          </p>
          <p>
            5. Partner Information: We partner with certain third-party vendors
            to offer You some services, and We may receive certain information
            directly from those third parties. We will not collect Sensitive
            Personal Information from You unless the collection of such
            information or data is considered necessary for the purpose for
            which it is being collected.
          </p>
          <p>
            <strong>E. HOW WE USE YOUR INFORMATION</strong>
          </p>
          <p>
            1. We use the information that We collect to provide You with Our
            services and to coordinate your requests for related third-party
            vendor services. We use Your Information as necessary to facilitate
            communication between You and Us regarding the services, as well as
            to improve Our Site and to learn about how it is used and accessed.
            We may use Your Information to contact You in the future to tell You
            about new services, promotions, opportunities, and other general
            information about Us that we believe will be of interest to You. We
            may also use information that We collect to analyze user behaviour
            as a measure of interest in and use of the Site and may disclose
            such analyses to advertisers or other third parties in the form of
            aggregate data, such as overall patterns or demographic reports that
            do not describe or identify any individual user.
          </p>
          <p>
            2. As necessary to provide the services, We share pertinent Personal
            Information with select affiliates and partners for the purposes of
            customer support, account management, and similar work. We do not
            sell, trade, share, or rent your Personal Information provided by
            You to Us through our Site, to third parties who provide no
            affiliated services or support.
          </p>
          <p>
            3. We may disclose information about You to third parties if we have
            a good-faith belief that We are required to do so by law or legal
            process, either to respond to legal claims, or to protect Our
            rights, property, or safety, or the rights, property, or safety of
            others. We may disclose information about You to a successor
            organization in the event of a merger, acquisition, bankruptcy, or
            other sale or disposition of all or a portion of Our assets or prior
            to such event in the case of any diligence requirement. The
            successor organization's use and disclosure of Your Personal
            Information and Sensitive Personal Information will continue to be
            subject to this Privacy Policy unless: (i) a court orders otherwise;
            or (ii) the successor organization gives You notice that Your
            Personal Information and Sensitive Personal Information will be
            subject to the successor organization's own policy, along with an
            opportunity for You to opt-out (which may cause You not to be able
            to continue to use the website). If You submit Personal Information
            after such a transfer, that information may be subject to the
            successor entity's policy.
          </p>
          <p>
            4. Your Information will be collected, used, stored, deleted,
            processed, handled, disclosed, transferred, and shared in accordance
            with the applicable laws of India and subject to the terms in this
            Privacy Policy.
          </p>
          <p>
            5. We will ask You when We need more information that personally
            identifies You or allows Us to contact You. We will not
            differentiate between who is using the device to access the Site, so
            long as the log in / access credentials match with Yours. In order
            to make the best use of the Site and enable Your Information to be
            captured accurately on the Site, it is essential that You have
            logged in using Your own credentials. We will, at all times, provide
            the option to You to not provide the Information which We seek from
            You. Further, You shall, at any time while using the Site, also have
            an option to withdraw Your consent given earlier to Us to use such
            Information. Such withdrawal of the consent is required to be sent
            in writing to Us at the contact details provided in this Privacy
            Policy below. In such event, however, the Company fully reserves the
            right not to allow further usage of the Site or discontinue to
            provide any services / products thereunder to You.
          </p>
          <p>
            <strong>F. UPDATING YOUR INFORMATION</strong>
          </p>
          <p>
            We will provide You with the means to ensure that Your Information
            is correct and current. If You have filled out a user profile, We
            will provide a way for You to access, review and change Your profile
            / account Information on Our Site with reasonable restrictions. We
            are not responsible for the authenticity of the Information provided
            by You and it is Your sole responsibility to provide accurate and
            correct information to Us.
          </p>
          <p>
            <strong>G. STORAGE AND SECURITY</strong>
          </p>
          <p>
            1. Currently, We plan to retain user data while an account is active
            and for a reasonable period thereafter. We may alter this practice
            according to legal and business requirements. For example, We may
            lengthen the retention period for some data if needed to comply with
            any applicable law or voluntary codes of conduct. Unless otherwise
            prohibited in law, We may shorten the retention period for some
            types of data if needed to free up storage space.
          </p>
          <p>
            2. We adopt reasonable security measures to protect Your Information
            in a manner that is compliant with applicable laws. The security of
            Your Information is important to us, but remember that no method of
            transmission over the Internet, or method of electronic storage, is
            100% secure. While we strive to use commercially acceptable means to
            protect Your Information, we cannot guarantee its absolute security.
          </p>
          <p>
            <strong>H. CONFIDENTIALITY</strong>
          </p>
          <p>
            1. We are concerned about safeguarding the confidentiality of Your
            Information. We provide physical, electronic, and procedural
            safeguards to protect Information We process and maintain. For
            example, We limit access to this Information to authorized employees
            only who need to know that Information in order to operate, develop
            or improve Our Site. Please be aware that, although We endeavor to
            provide reasonable security for information We process and maintain,
            no security system can prevent all potential security breaches.
          </p>
          <p>
            2. We do not accept responsibility or liability for the
            confidentiality, security or integrity of Your Information in
            connection with its transmission over the Internet.
          </p>
          <p>
            <strong>I. SOCIAL FEATURES OF OUR WEBSITE</strong>
          </p>
          <p>
            When You use certain features on Our Site like the discussion forums
            and You post or share Your personal information such as comments,
            messages, files, photos, will be available to all users, and will be
            in the public domain. All such sharing of information is done at
            Your own risk. Please keep in mind that if You disclose personal
            information in Your profile or when posting on Our forums this
            information may become publicly available.
          </p>
          <p>
            <strong>J. GOVERNING LAW AND DISPUTE RESOLUTION</strong>
          </p>
          <p>
            Any claims arising out of the use of the information from this Site
            shall be governed by the laws of India and only the Courts in New
            Delhi, shall have the exclusive jurisdiction over the same. By
            accessing this Site, the User accepts this Privacy Policy. K. Log
            Files When You access Our Site, Our servers automatically record
            information that Your browser sends whenever You visit a website.
            These server logs may include information such as Your web request,
            internet protocol address, browser type, browser language, the date
            and time of Your request and one or more cookies that may uniquely
            identify Your browser.
          </p>
          <p>
            <strong>L. COOKIES AND WEB BEACONS</strong>
          </p>
          <p>
            1. Cookies are small text files that are placed on Your computer by
            websites that You visit. They are widely used in order to make
            websites work, or work more efficiently, as well as to provide
            information about Your actions to the owners of the website. We send
            cookies (small files containing a string of characters) to Your
            computer, thereby uniquely identifying Your browser. Cookies are
            used to track Your preferences, help You login faster, and
            aggregated to determine user trends. This data is used to improve
            Our offerings, such as providing more content in areas of greater
            interest to a majority of users. Most browsers are initially set up
            to accept&nbsp;cookies, but You can reset Your browser to refuse all
            cookies or to indicate when a cookie is being sent. Some of Our
            features and services may not function properly if Your cookies are
            disabled.
          </p>
          <p>
            2. Web Beacons - On certain web pages or e-mails, We may utilize a
            common internet technology called a ‘Web beacon’. Web beacons help
            analyze the effectiveness of Web sites by measuring, for example,
            the number of visitors to a site or how many visitors clicked on key
            elements of a site. 3. Web beacons, cookies and other tracking
            technologies do not automatically obtain personally identifiable
            information about You. Only if You voluntarily submit personally
            identifiable information, such as by registering or sending e-mails,
            can these automatic tracking technologies be used to provide further
            information about Your use of the Site and/or interactive e-mails to
            improve their usefulness to You.
          </p>
          <p>
            <strong>M. ADVERTISING PARTNERS</strong>
          </p>
          <p>
            We use third-party advertising companies to serve ads when You visit
            Our Site. These companies may use information (not including your
            name, address, email address, or telephone number) about Your visits
            to this and other websites in order to provide advertisements about
            goods and services of interest to You.
          </p>
          <p>
            <strong>N. THIRD-PARTY PRIVACY POLICIES</strong>
          </p>
          <p>
            We may present links in a format that enables Us to keep track of
            whether these links have been followed. We use this information to
            improve Our customized content. Clicking on links may take You to
            sites outside Our domain. We are not responsible for the privacy
            practices of other websites / applications. We encourage Our users
            to be aware when they leave Our site to read the privacy statements
            of each and every website / application that collects personally
            identifiable information. This Privacy Policy applies solely to
            Information collected by Our Site.
          </p>
          <p>
            <strong>O. GRIEVANCE OFFICER</strong>
          </p>
          <p>
            For any further queries and complaints related to privacy, or
            exercising Your rights, You could reach Us at:
          </p>
          <p>Name of the Grievance Officer:&nbsp;V Verma</p>
          <p>Contact Email Address: complaints@merapashu360.com</p>
          <div className="mUsKed">
            <div className="oEr2hb"></div>
          </div>
          <p>
            <strong>P. MISCELLANEOUS</strong>
          </p>
          <p>
            1. Any violation by You of the terms of this Privacy Policy may
            result in immediate&nbsp;
          </p>
        </div>
      </div>
    </>
  );
}

export default Privacy;
