import { t } from "i18next";
import Lottie from "lottie-react";
import loader from '../assets/loader.json';
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import defaultImg from "../assets/heroBanner.webp";

interface Post {
  id: string;
  title: string;
  content: string;
  images?: { url: string }[];
  url: string;
  published: string;
}

const BlogPost = ({
  title,
  date,
  content,
  postUrl,
  img,
  id,
}: {
  title: string;
  date: string;
  content: string;
  postUrl: string;
  img?: any;
  id: any;
}) => {
  let { t } = useTranslation();
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const tempElement = document.createElement("div");
  tempElement.innerHTML = content;
  const firstTwoLines = tempElement.innerText
    .trim()
    .split("\n")
    .slice(0, 2)
    .join("\n");
  return (
    <div className="blog__card" id={id}>
      <div className="blog__card--image">
        <img src={img} alt="leader" />
      </div>
      <div className="blog__card--details">
        <div className="blog__card--post-title">
          <a href={`/blog/${id}`}>{title}</a>
        </div>
        <div className="blog__card--post-date">{formattedDate}</div>
        <p>{firstTwoLines}</p>
        <a className="blog_button" href={`/blog/${id}`}>
          {t("read_more")}
        </a>
      </div>
    </div>
  );
};

const BlogPage = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [nextPageToken, setNextPageToken] = useState("");

  const postsPerPage = 9;

  useEffect(() => {
    fetchPosts();
  }, [currentPage]);

  const fetchAllPosts = async () => {
    let allPosts: Post[] = [];
    let nextPageToken = null;

    while (true) {
      let url = `https://www.googleapis.com/blogger/v3/blogs/5147180660044942768/posts?key=${process.env.REACT_APP_BLOG_API_KEY}&fetchImages=true`;

      if (nextPageToken) {
        url += `&pageToken=${nextPageToken}`;
      }

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch posts");
        }
        const data = await response.json();
        const { items, nextPageToken: next } = data;
        allPosts = [...allPosts, ...items];
        nextPageToken = next;

        if (!nextPageToken) {
          break;
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
        break;
      }
    }

    return allPosts;
  };

  const fetchPosts = async () => {
    try {
      const allPosts = await fetchAllPosts();
      console.log("All posts:", allPosts);
      setPosts(allPosts);
      const totalPosts = allPosts.length;
      const totalPages = Math.ceil(totalPosts / postsPerPage);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={currentPage === i ? "page-number active" : "page-number"}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };

  const startIndex = (currentPage - 1) * postsPerPage;
  const endIndex = startIndex + postsPerPage;
  const currentPosts = posts.slice(startIndex, endIndex);

  return (
    <div>
      <section className="blog">
        <div className="blog__breadcrumb">
          <a href="/">{t("home")}</a>
          {" > "}
          <span> {t("blog")} </span>
        </div>
        {posts.length === 0 && <Lottie animationData={loader} loop={true} />}
        <div className="blog__cards">
          {currentPosts.map((post: any, index) => (
            <BlogPost
              id={post.id}
              title={post.title}
              content={post.content}
              img={post.images ? post.images[0].url : defaultImg}
              postUrl={post.url}
              date={post.published}
            />
          ))}
        </div>
        <div className="pagination">
          <button
            className="pagination-button"
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            ‹
          </button>
          {renderPageNumbers()}
          <button
            className="pagination-button"
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            ›
          </button>
        </div>
      </section>
    </div>
  );
};

export default BlogPage;
