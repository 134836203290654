import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_FEED, GET_PRODUCT_BY_SKU } from "../../gql/gql";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Button from "../../components/Button/Button";
import Lottie from "lottie-react";
import loader from "../../assets/loader.json";
import premium from "../../assets/mp360-premium.png";
import truck from "../../assets/truck-icon-v2.svg";
import greenTick from "../../assets/green-tick.svg";
import indiaMap from "../../assets/villDelivered.svg";
import bulb from "../../assets/bulb.svg";
import thumb from "../../assets/thumbsup-outline.svg";
import cod from "../../assets/cod.svg";
import PlayButton from "../../assets/playButton.jpg";
import DownloadMP360 from "../Home/sections/DownloadMP360";

type VideoStates = {
  [key: string]: boolean;
};

function FeedDetail() {
  const { t, i18n } = useTranslation();
  const [feedDetail, setFeedDetail] = useState<any>({});
  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>(0);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number>(0);
  const [selectedVariantName, setSelectedVariantName] = useState<string>("");
  const [selectedVariantImage, setSelectedVariantImage] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState("composition");
  const [videoStates, setVideoStates] = useState<VideoStates>({});
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const { slug } = useParams<{ slug: string }>();
  const query = new URLSearchParams(useLocation().search);
  const sku = query.get("sku");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [
    getFeedBySku,
    { loading: skuLoading, error: skuError, data: skuData },
  ] = useLazyQuery(GET_PRODUCT_BY_SKU, {
    variables: { sku, language: i18n.language },
    fetchPolicy: "no-cache",
  });

  const [getFeed, { loading: feedLoading, error: feedError, data: feedData }] =
    useLazyQuery(GET_FEED, {
      variables: { language: i18n.language, take: 1200 },
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    if (sku) {
      console.log("Fetching data by SKU:", sku);
      getFeedBySku().finally(() => setIsLoading(false));
    } else {
      console.log("Fetching data by slug:", slug);
      getFeed().finally(() => setIsLoading(false));
    }
  }, [sku, slug, getFeedBySku, getFeed]);

  useEffect(() => {
    const data = sku ? skuData?.feed : feedData?.feedAll;
    console.log("Fetched data:", data);

    if (sku && !data && !isLoading) {
      navigate("/404");
      return;
    }

    if (data) {
      const fd = sku
        ? data
        : data.filter(
            (item: { seo: { urlSlug: string | undefined } }) =>
              item.seo.urlSlug === slug
          );

      console.log("Filtered data:", fd);
      if (fd.length === 0) {
        navigate("/404");
      } else {
        const firstFeedItem = sku ? fd : fd[0];
        console.log("First feed item:", firstFeedItem);

        const firstVariant = firstFeedItem.variants
          ? firstFeedItem.variants[0]
          : null;
        console.log("First variant:", firstVariant);

        if (firstVariant) {
          setFeedDetail({
            ...firstFeedItem,
            selectedVariantPrice: firstVariant.price.amount,
            selectedVariantMRP: firstVariant.mrp.amount,
          });
          setSelectedVariantName(firstVariant.name);
          setSelectedVariantImage(
            firstVariant.media && firstVariant.media[0]
              ? firstVariant.media[0].url
              : ""
          );
        } else {
          console.error("No variants found for the feed detail");
        }
      }
    } else {
      console.log("No data found");
    }
    window.scrollTo(0, 0);
  }, [skuData, feedData, sku, slug, isLoading, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleVideoStateChange = (videoId: string, isPlaying: boolean) => {
    setVideoStates((prevStates) => ({
      ...prevStates,
      [videoId]: isPlaying,
    }));
  };

  const handleClick = (tab: string) => {
    setSelectedTab(tab);
  };

  const switchPhotoView = (index: number, url?: string) => {
    setSelectedPhotoIndex(index);
    if (url) {
      setSelectedVariantImage(url);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const switchVariant = (index: number) => {
    setSelectedVariantIndex(index);
    const selectedVariant = feedDetail.variants[index];
    setFeedDetail((prevFeedDetail: any) => ({
      ...prevFeedDetail,
      selectedVariantPrice: selectedVariant.price.amount,
      selectedVariantMRP: selectedVariant.mrp.amount,
      selectedVariantImage: selectedVariant.media[0]?.url,
    }));
    setSelectedVariantName(selectedVariant.name);
    setSelectedVariantImage(selectedVariant.media[0]?.url);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <>
      {feedLoading ? (
        <Lottie animationData={loader} loop={true} />
      ) : (
        feedDetail && (
          <>
            <Helmet>
              {feedDetail.defaultName && (
                <title>{feedDetail.defaultName} | MeraPashu360</title>
              )}

              {feedDetail.benefits && feedDetail.benefits.description && (
                <meta
                  name="description"
                  content={feedDetail.benefits.description}
                />
              )}
              <link
                rel="canonical"
                href={`https://merapashu360.com/feed/${slug}/`}
              />
            </Helmet>
            <section className="feedD">
              <div className="feedD__breadcrumb">
                <a href="/">
                  {t("home")} {">"}{" "}
                </a>
                <a href="/feed"> {t("buy_cattle_feed")}</a> {">"}
                <span> FEED DETAIL </span>
              </div>
              <div className="feedD__container">
                <div className="feedD__images">
                  <div className="feedD__image" onClick={openModal}>
                    <img src={selectedVariantImage} alt="mainn" />
                  </div>

                  <div className="feedD__thumbnails">
                    {feedDetail.media &&
                      feedDetail.media.map(
                        (photo: { url: string | undefined }, index: number) => (
                          <div
                            key={index}
                            className={`feedD__thumbnail ${
                              index === selectedPhotoIndex
                                ? "feedD__thumbnail--selected"
                                : ""
                            }`}
                            style={{ width: "120px" }}
                            onClick={() => switchPhotoView(index, photo.url)}
                          >
                            <img src={photo.url} alt={`thumbnail${index}`} />
                          </div>
                        )
                      )}
                  </div>
                </div>

                <div className="feedD__details">
                  <div className="feedD__caption">
                    <p className="hindi">{feedDetail.vendor}</p>

                    {feedDetail.qualityTier === "PREMIUM" && (
                      <img src={premium} alt="primium" />
                    )}
                  </div>

                  <div className="feedD__title">
                    {feedDetail.defaultName} - {selectedVariantName}
                  </div>

                  <div className="feedD__features">
                    {feedDetail.tags &&
                      feedDetail.tags.map((item: any) => {
                        return (
                          <div className="feedD__feature">
                            <p>{item.key}</p>
                            <p>{item.value}</p>
                          </div>
                        );
                      })}
                  </div>
                  <div className="feedD__price">
                    <h1>₹{feedDetail.selectedVariantPrice}</h1>
                    <p>
                      (प्रति किलो: ₹
                      {feedDetail.selectedVariantPrice &&
                        feedDetail.variants[selectedVariantIndex] &&
                        (
                          feedDetail.selectedVariantPrice /
                          feedDetail.variants[selectedVariantIndex].weight.value
                        ).toFixed(1)}
                      )
                    </p>
                  </div>

                  <div className="feedD__mrp">
                    <p className="feedD__mrp--crossed">
                      ₹{feedDetail.selectedVariantMRP}
                    </p>
                    <p className="feedD__mrp--saving">
                      बचाए ₹
                      {feedDetail.selectedVariantPrice &&
                        feedDetail.selectedVariantMRP &&
                        feedDetail.selectedVariantMRP -
                          feedDetail.selectedVariantPrice}
                    </p>
                  </div>

                  <div className="feedD__cta">
                    <Button
                      primary
                      text="ऐप से खरीदे"
                      to="https://mp360.app/home"
                    />

                    <div className="feedD__cta--free-delivery">
                      <img src={truck} alt="del-truck" />
                      <a
                        href="https://mp360.app/home"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <p className="hindi">निशुल्क डिलीवरी : 1 दिन के भीतर</p>
                      </a>
                    </div>
                  </div>

                  <div className="feedD__varieties">
                    {feedDetail.variants &&
                      feedDetail.variants.map((variant: any, index: number) => (
                        <div
                          key={index}
                          className={`feedD__variety ${
                            index === selectedVariantIndex
                              ? "feedD__variety--selected"
                              : ""
                          }`}
                          onClick={() => switchVariant(index)}
                        >
                          <div className="feedD__variety--header">
                            <p>{variant.name}</p>
                            {index === selectedVariantIndex && (
                              <img src={greenTick} alt="selected" />
                            )}
                          </div>
                          <div className="feedD__variety--price">
                            <h1>₹{variant.price.amount}</h1>
                            <p className="hindi">
                              ( प्रति किलो मूल्य: ₹
                              {(
                                variant.price.amount / variant.weight.value
                              ).toFixed(1)}
                              )
                            </p>
                          </div>
                          <div className="feedD__variety--mrp">
                            <p className="feedD__variety--mrp--crossed">
                              ₹{variant.mrp.amount}
                            </p>
                            <p className="feedD__variety--mrp--saving hindi">
                              बचाए ₹{variant.mrp.amount - variant.price.amount}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="feedD__info">
                    <img src={bulb} alt="bulb" />

                    <p className="hindi">
                      {feedDetail.benefits &&
                        feedDetail.benefits.description &&
                        feedDetail.benefits.description}
                    </p>
                  </div>
                  <div className="feedD__info">
                    <img src={indiaMap} alt="bulb" />

                    <p>Country of Origin: India</p>
                  </div>

                  <div className="feedD__benefits">
                    <div className="feedD__benefit">
                      <img src={truck} alt="truck" />

                      <p className="hindi">फ्री होम डिलीवरी</p>
                    </div>

                    <div className="feedD__benefit">
                      <img src={cod} alt="truck" />

                      <p className="hindi">कैश ऑन डिलीवरी</p>
                    </div>

                    <div className="feedD__benefit">
                      <img src={thumb} alt="truck" />

                      <p className="hindi">प्रोडक्ट सीधे कारखाने से</p>
                    </div>
                  </div>

                  <div className="feedD__infoGraphic">
                    {isMobileView && (
                      <div className="feedD__info-buttons">
                        <button
                          className={selectedTab === "benefits" ? "active" : ""}
                          onClick={() => handleTabClick("benefits")}
                        >
                          <div>
                            <p>{t("benefits")}</p>
                          </div>
                        </button>
                        <button
                          className={
                            selectedTab === "composition" ? "active" : ""
                          }
                          onClick={() => handleTabClick("composition")}
                        >
                          <div>
                            <p>{t("composition")}</p>
                          </div>
                        </button>
                        <button
                          className={selectedTab === "dosage" ? "active" : ""}
                          onClick={() => handleTabClick("dosage")}
                        >
                          <div>
                            <p>{t("dosage")}</p>
                          </div>
                        </button>
                      </div>
                    )}

                    {isMobileView ? (
                      <>
                        {/* Content */}
                        {feedDetail.composition &&
                          (selectedTab === "composition" ||
                            window.innerWidth > 768) && (
                            <React.Fragment>
                              {feedDetail.composition.mediaCollection[0].contentType.startsWith(
                                "image/"
                              ) ? (
                                <div className="content-container">
                                  <img
                                    src={
                                      feedDetail.composition.mediaCollection[0]
                                        .url
                                    }
                                    alt="Infographic"
                                    className="feedD__infoGraphic--composition"
                                  />
                                </div>
                              ) : (
                                <div
                                  className="content-container"
                                  onTouchStart={() => {
                                    const video =
                                      document.querySelector<HTMLVideoElement>(
                                        ".feedD__infoGraphic--composition"
                                      );
                                    if (video) {
                                      if (video.paused) {
                                        video.play();
                                      } else {
                                        video.pause();
                                      }
                                    }
                                  }}
                                >
                                  <video
                                    controls
                                    className="feedD__infoGraphic--composition"
                                    onPlay={() =>
                                      handleVideoStateChange(
                                        "composition",
                                        true
                                      )
                                    }
                                    onPause={() =>
                                      handleVideoStateChange(
                                        "composition",
                                        false
                                      )
                                    }
                                    id="video"
                                  >
                                    <source
                                      src={
                                        feedDetail.composition
                                          .mediaCollection[0].url
                                      }
                                      type={
                                        feedDetail.composition
                                          .mediaCollection[0].contentType
                                      }
                                    />
                                  </video>

                                  {!videoStates["composition"] && (
                                    <button
                                      className="play-button"
                                      onClick={() => {
                                        const video =
                                          document.querySelector<HTMLVideoElement>(
                                            ".feedD__infoGraphic--composition"
                                          );
                                        if (video) {
                                          video.play();
                                        }
                                      }}
                                    ></button>
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          )}

                        {feedDetail.dosage &&
                          (selectedTab === "dosage" ||
                            window.innerWidth > 768) && (
                            <React.Fragment>
                              {feedDetail.dosage.mediaCollection[0].contentType.startsWith(
                                "image/"
                              ) ? (
                                <div className="content-container">
                                  <img
                                    src={
                                      feedDetail.dosage.mediaCollection[0].url
                                    }
                                    alt="Infographic"
                                    className="feedD__infoGraphic--composition"
                                  />
                                </div>
                              ) : (
                                <div
                                  className="content-container"
                                  onTouchStart={() => {
                                    const video =
                                      document.querySelector<HTMLVideoElement>(
                                        ".feedD__infoGraphic--composition"
                                      );
                                    if (video) {
                                      if (video.paused) {
                                        video.play();
                                      } else {
                                        video.pause();
                                      }
                                    }
                                  }}
                                >
                                  <video
                                    controls
                                    className="feedD__infoGraphic--composition"
                                    onPlay={() =>
                                      handleVideoStateChange("dosage", true)
                                    }
                                    onPause={() =>
                                      handleVideoStateChange("dosage", false)
                                    }
                                  >
                                    <source
                                      src={
                                        feedDetail.dosage.mediaCollection[0].url
                                      }
                                      type={
                                        feedDetail.dosage.mediaCollection[0]
                                          .contentType
                                      }
                                    />
                                  </video>

                                  {!videoStates["dosage"] && (
                                    <button
                                      className="play-button"
                                      onClick={() => {
                                        const video =
                                          document.querySelector<HTMLVideoElement>(
                                            ".feedD__infoGraphic--composition"
                                          );
                                        if (video) {
                                          video.play();
                                        }
                                      }}
                                    ></button>
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          )}

                        {feedDetail.benefits &&
                          (selectedTab === "benefits" ||
                            window.innerWidth > 768) && (
                            <React.Fragment>
                              {feedDetail.benefits.mediaCollection[0].contentType.startsWith(
                                "image/"
                              ) ? (
                                <div className="content-container">
                                  <img
                                    src={
                                      feedDetail.benefits.mediaCollection[0].url
                                    }
                                    alt="Infographic"
                                    className="feedD__infoGraphic--composition"
                                  />
                                </div>
                              ) : (
                                <div
                                  className="content-container"
                                  onTouchStart={() => {
                                    const video =
                                      document.querySelector<HTMLVideoElement>(
                                        ".feedD__infoGraphic--composition"
                                      );
                                    if (video) {
                                      if (video.paused) {
                                        video.play();
                                      } else {
                                        video.pause();
                                      }
                                    }
                                  }}
                                >
                                  <video
                                    controls
                                    className="feedD__infoGraphic--composition"
                                    onPlay={() =>
                                      handleVideoStateChange("benefits", true)
                                    }
                                    onPause={() =>
                                      handleVideoStateChange("benefits", false)
                                    }
                                  >
                                    <source
                                      src={
                                        feedDetail.benefits.mediaCollection[0]
                                          .url
                                      }
                                      type={
                                        feedDetail.benefits.mediaCollection[0]
                                          .contentType
                                      }
                                    />
                                  </video>

                                  {!videoStates["benefits"] && (
                                    <button
                                      className="play-button"
                                      onClick={() => {
                                        const video =
                                          document.querySelector<HTMLVideoElement>(
                                            ".feedD__infoGraphic--composition"
                                          );
                                        if (video) {
                                          video.play();
                                        }
                                      }}
                                    ></button>
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          )}
                      </>
                    ) : (
                      <>
                        <div className="feedD__info-text">
                          <p className="straight-line">{t("composition")}</p>
                        </div>

                        {feedDetail.composition && (
                          <React.Fragment>
                            {feedDetail.composition.mediaCollection[0].contentType.startsWith(
                              "image/"
                            ) ? (
                              <img
                                src={
                                  feedDetail.composition.mediaCollection[0].url
                                }
                                alt="Infographic"
                                className="feedD__infoGraphic--composition"
                              />
                            ) : (
                              <div className="content-container">
                                <video
                                  controls
                                  className="feedD__infoGraphic--composition"
                                  onPlay={() =>
                                    handleVideoStateChange("composition", true)
                                  }
                                  onPause={() =>
                                    handleVideoStateChange("composition", false)
                                  }
                                >
                                  <source
                                    src={
                                      feedDetail.composition.mediaCollection[0]
                                        .url
                                    }
                                    type={
                                      feedDetail.composition.mediaCollection[0]
                                        .contentType
                                    }
                                  />
                                </video>
                                {!videoStates["composition"] && (
                                  <button
                                    className="play-button"
                                    onClick={() => {
                                      const video =
                                        document.querySelector<HTMLVideoElement>(
                                          ".feedD__infoGraphic--composition"
                                        );
                                      if (video) {
                                        video.play();
                                      }
                                    }}
                                  ></button>
                                )}
                              </div>
                            )}
                          </React.Fragment>
                        )}
                        {/* Dosage */}
                        <div className="feedD__info-text">
                          <p className="straight-line"> {t("dosage")}</p>
                        </div>
                        {feedDetail.dosage && (
                          <React.Fragment>
                            {feedDetail.dosage.mediaCollection[0].contentType.startsWith(
                              "image/"
                            ) ? (
                              <img
                                src={feedDetail.dosage.mediaCollection[0].url}
                                alt="Infographic"
                                className="feedD__infoGraphic--composition"
                              />
                            ) : (
                              <div className="content-container">
                                <video
                                  controls
                                  className="feedD__infoGraphic--dosage"
                                  onPlay={() =>
                                    handleVideoStateChange("dosage", true)
                                  }
                                  onPause={() =>
                                    handleVideoStateChange("dosage", false)
                                  }
                                >
                                  <source
                                    src={
                                      feedDetail.dosage.mediaCollection[0].url
                                    }
                                    type={
                                      feedDetail.dosage.mediaCollection[0]
                                        .contentType
                                    }
                                  />
                                </video>

                                {!videoStates["dosage"] && (
                                  <button
                                    className="play-button"
                                    onClick={() => {
                                      const video =
                                        document.querySelector<HTMLVideoElement>(
                                          ".feedD__infoGraphic--dosage"
                                        );
                                      if (video) {
                                        video.play();
                                      }
                                    }}
                                  ></button>
                                )}
                              </div>
                            )}
                          </React.Fragment>
                        )}
                        {/* Benefits */}
                        <div className="feedD__info-text">
                          <p className="straight-line">{t("benefits")}</p>
                        </div>
                        {feedDetail.benefits && (
                          <React.Fragment>
                            {feedDetail.benefits.mediaCollection[0].contentType.startsWith(
                              "image/"
                            ) ? (
                              <img
                                src={feedDetail.benefits.mediaCollection[0].url}
                                alt="Infographic"
                                className="feedD__infoGraphic--composition"
                              />
                            ) : (
                              <div className="content-container">
                                <video
                                  controls
                                  className="feedD__infoGraphic--benefits"
                                  onPlay={() =>
                                    handleVideoStateChange("benefits", true)
                                  }
                                  onPause={() =>
                                    handleVideoStateChange("benefits", false)
                                  }
                                >
                                  <source
                                    src={
                                      feedDetail.benefits.mediaCollection[0].url
                                    }
                                    type={
                                      feedDetail.benefits.mediaCollection[0]
                                        .contentType
                                    }
                                  />
                                </video>

                                {!videoStates["benefits"] && (
                                  <button
                                    className="play-button"
                                    onClick={() => {
                                      const video =
                                        document.querySelector<HTMLVideoElement>(
                                          ".feedD__infoGraphic--benefits"
                                        );
                                      if (video) {
                                        video.play();
                                      }
                                    }}
                                  ></button>
                                )}
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
            {isModalOpen && (
              <div className="modal" onClick={closeModal}>
                <div className="modal-content">
                  <span className="modal-close" onClick={closeModal}>
                    &times;
                  </span>
                  <img
                    src={
                      selectedPhotoIndex === 0
                        ? selectedVariantImage
                        : feedDetail.media[selectedPhotoIndex].url
                    }
                    alt=""
                  />
                </div>
              </div>
            )}

            <DownloadMP360 />
          </>
        )
      )}
    </>
  );
}

export default FeedDetail;
