import { useLazyQuery } from "@apollo/client";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import GET_CATTLE from "../gql/gql";
import CattleCard from "./CattleCard";

function EmptyStateCattle({ clearFilter }: any) {
  const { t } = useTranslation();
  const [
    getCattle,
    { loading: cattleLoading, error: cattleError, data: cattleData },
  ] = useLazyQuery(GET_CATTLE);

  useEffect(() => {
    getCattle({
      variables: {
        take: 500,
      },
    });
  }, [getCattle]);

  if(cattleError) console.log(cattleError);
  

  return (
    <>
      <div className="feed__emptyBg" />
      <div className="feed__notFound">
        <div className="feed__notFound--text">
          इस प्रकार की भैंस अभी उपलब्ध नहीं है
        </div>
        <button onClick={clearFilter} className="feed__notFound--button">
          फ़िल्टर बदलें
        </button>
      </div>

      <p className="feed__title">{t("all_cattle")}</p>
      <div className="feed__list">
        {!cattleLoading &&
          cattleData &&
          cattleData.animals &&
          cattleData.animals.map((cattle: any, index: number) => {
            return (
              <Fragment key={index}>
              <CattleCard
                id={cattle.id}
                animalType={cattle.animalType}
                breed={cattle.breed}
                mrp={cattle.mrp.amount}
                price={cattle.price.amount}
                calfGender={cattle.accompanyingChildren[0].gender}
                tag={cattle.tag}
                calving={cattle.pregnancyInfo.calving}
                fat={cattle.averageMilkQuality.fat}
                averageMilkYield={cattle.averageMilkYield.weight.value}
                expectedMilkYield={cattle.expectedMilkYield.weight.value}
                parturitionDate={cattle.parturitionDate}
                location={cattle.currentLocation.address.district}
                imageUrl={cattle.mediaRecords[0].url}
              />
              </Fragment>
            );
          })}
      </div>
    </>
  );
}

export default EmptyStateCattle;
