import { Helmet } from 'react-helmet-async';

const Breadcrumb = ({ breadcrumbs }:any) => {
  const breadcrumbList = breadcrumbs.map((breadcrumb:any, index:number) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: breadcrumb.name,
    item: breadcrumb.url, // URL of the breadcrumb item (optional)
  }));

  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbList,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};

export default Breadcrumb;
//first commit