import Button from '../../../components/Button/Button';
import { useTranslation, Trans } from 'react-i18next';

function Support() {
  const { t } = useTranslation();
  return (
    <div className="support">
      <div className="support__container">
        <img alt="" className="support__image" />

        <div className="support__content">
          <p className="support__subheading subheading hindi">{t('vet_sub')}</p>
          <p className="support__heading heading hindi">
            <Trans
              i18nKey="vet_title"
              components={{
                span: <span className="green" />,
              }}
            />
          </p>

          <div className="support__usps">
            <div className="support__usp">
              <div className="support__usp--icon" />
              <p className="support__usp--text hindi">{t('vet_oncall')}</p>
            </div>
            <div className="support__usp">
              <div className="support__usp--icon" />
              <p className="support__usp--text hindi">{t('vet_atHome')}</p>
            </div>
          </div>

          <div className="support__cta">
            <Button
              primary
              playStore = {true}
              to = "https://play.google.com/store/search?q=merapashu360&c=apps"
              text={t('download_app')}
              className="support__cta--1"
            />


            <Button outline text={t('contact_us')} color="#2cac4c" to='/contact-us'/>
            {/* <button className="support__cta--2">CONTACT US</button> */}
          </div>

          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Support;
