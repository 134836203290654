const _hiTranslations = {
  nav_language: 'भाषा',
  nav_subtext : '27K+ किसानों का पसंदीदा ऐप',
  nav_call: 'सुबह 8 बजे से शाम 7 बजे के बीच हमें कॉल करें',
  nav_search_placeholder: 'खोजें भैंस, बिनोला खल, साइलेज',
  nav_get: 'पहले ऑर्डर पर ₹100 की छूट',
  nav_download_app: 'ऐप डाउनलोड पर',
  lakh_downloads: 'लाख से ज्यादा डाउनलोड्स',
  we_offer_sub: 'हमारी सर्विसेज',
  we_offer_title: 'हमसे क्यों खरीदें',
  downloads: 'डाउनलोडस',
  download_mob: 'डाउनलोड करें, पहले ऑर्डर पर ₹100 की छूट पाएं',
  rating: 'रेटिंग',
  rating_on_google_playStore: 'गूगल प्ले स्टोर रेटिंग',
  home: 'होम',
  we_offer_one_title: 'फास्ट होम डिलीवरी',
  we_offer_one_desc:
    'हमारे  नजदीकी केन्द्रों से 50 कि.मी. तक 24 घंटे में डिलीवरी।',
  we_offer_two_title: 'गुणवत्ता जांच',
  we_offer_two_desc:
    'नियमित गुणवत्ता जांच के साथ अनुभवी टीमों द्वारा बनाए गए सर्वोत्तम गुणवत्ता वाले उत्पाद।',
  we_offer_three_title: 'गुणवत्ता वाले उत्पाद',
  we_offer_three_desc:
    'प्रीमियम गुणवत्ता वाले ताजे पशु आहार को सीधे कारखाने से आपके घर पर पहुंचाया जाता है।',
  we_offer_four_title: 'कैश ऑन डिलीवरी',
  we_offer_four_desc:
    'बिना किसी परेशानी प्रोडक्ट घर पर डिलीवर होने के बाद  नकद भुगतान करें।',
  buy_cattle_feed_sub: 'पशु आहार खरीदें',
  buy_cattle_feed_title:
    'आपके पशु के लिए <a>प्रीमियम क्वालिटी फीड</a> <br/> शुरुआत- मात्र <a>₹260</a> से',
  buy_bhains: 'पशु आहार और भैंस खरीदें',
  purachase_cattle:'पशु खरीदें',
  explore_categories:"पशु आहार खरीदें",
  explore_categories_one:"पशु देखें",
  combo_offer: "कोम्बो ऑफर",
  combo_sub:"अधिकतम बचत के लिए संयोजन",
  khal: 'खल',
  binola_khal: 'बिनोला खल',
  binola_khal_sub: 'प्रोटीन और फैट का सबसे अच्छा स्रोत',
  sarso_khal: 'सरसों खल',
  sarso_khal_sub: 'उच्च प्रोटीन आहार',
  goli_khal: 'गोली खल',
  goli_khal_sub: 'पूर्ण संतुलित पशु आहार',
  mineral_mix: 'सप्लीमेंट्स',
  mineral_mix_sub: 'प्रजनन स्वास्थ्य और विकास के लिए सहायक',
  silage: 'साइलेज',
  All:'सभी',
  silage_sub: 'स्वादिष्ट और पोषक',
  traditional_khal: 'पारंपरिक पशु आहार',
  traditional_khal_sub:'फाइबर और ऊर्जा का सर्वोत्तम स्रोत',
  browse_all_cattle_feed: 'सभी पशु आहार देखें',
  vet_sub: 'क्या आपका पशु किसी समस्या से परेशान है?',
  vet_title: 'हमारे <span>अनुभवी पशु चिकित्सक</span> से समाधान प्राप्त करें',
  vet_oncall: '24x7 उपलब्ध, कॉल करें',
  vet_atHome: 'घर पर परामर्श उपलब्ध',
  download_app: 'मेरापशु360 ऐप डाउनलोड करें',
  contact_us: 'संपर्क करें',
  videos: 'वीडियोज',
  asked_answered: 'आपने पूछा हमने जवाब दिया',
  go_to_youtube: 'हमारे यूट्यूब चैनल पर जाएं',
  know: 'जानें',
  address:"गांव धूमसपुर, सेक्टर 63 के पास, गुड़गांव खेवट संख्या 50, खतौनी संख्या 52, मस्तील नंबर 6, किला नंबर 3, गुड़गांव- 122102",
  customers_say: '<span>हमारे ग्राहकों</span> <br/> का क्या कहना है',
  who_are_we: 'हम कौन हैं?',
  know_us: 'जानिए हमारे बारे मे',
  about_us_desc:
    '130 लोगों की एक टीम डेयरी किसानों के जीवन को बेहतर बनाने के लिए एक प्रौद्योगिकी-सह-भौतिक मंच बनाने के लिए तैयार की गई है, जो हर छोटे डेयरी किसान को अच्छी गुणवत्ता वाले पशु , पोषण, पशु चिकित्सा सेवाओं और स्वास्थ्य सलाह तक सब चीजों की पहुंच प्रदान करती है और डेयरी उद्योग का गठन कर के उन्हें तैयार करती हैI',
  know_more: 'और जानें',
  kisan_served: 'किसानों की सेवा की',
  pin_code_served: 'ऑर्डर डिलीवर किए',
  katte_sold: 'टन में पशु आहार',
  buffalo_sold: 'भैंस और गाय बेची गई',
  app_downloads: 'ऐप डाउनलोडस',
  merapashu_app: 'मेरापशु360 ऐप',
  download_app_title:
    'आज ही डाउनलोड करें <a>मेरापशु360 ऐप</a><br/> और <a>पाएं ₹100</a> की छूट पहले ऑर्डर पर',
  browse_cattle: '<a>देखें 75+ पैमानों पर जांची गई गाय/मुर्रा भैंस</a>',
  buy_feed: '<a>खरीदें संतुलित पशु आहार</a>',
  fat: 'फैट',
  sell_cattle: '<a>पशु मूल्य</a>',
  check_cattle_price: 'पशु मूल्य',
  get_vet: '<a>पशु चिकित्सक परामर्श प्राप्त करें</a>',
  company: 'कंपनी',
  about_us: 'हमारे बारे में',
  career: 'करियर',
  blog: 'ब्लॉग',
  meet_with_us: 'हमारे साथ मिले',
  faq: 'ज्यादातर पूछे जाने वाले समान',
  explore: 'पता करें',
  buy_cattle_feed: 'पशु आहार खरीदें',
  buy_cattle: 'पशु देखें',
  buy_cattle_sub:"मुर्रा भैंस, एचएफ गाय, साहीवाल",
  check_cattle_price_sub:"अपने पशु का बाजार मूल्य जांचें",
  Download_Button_sub:"गूगल प्लेस्टोर पर <span>निःशुल्क</span> उपलब्ध है",
  sell_your_cattle: 'पशु मूल्य',
  hub_loc: 'फार्म लोकेशन',
  gurgaon: 'गुड़गांव',
  jind: 'जींद',
  matan: 'मातन',
  ganganagar: 'श्री गंगानगर',
  sirsa: 'सिरसा',
  delivering: 'पशु आहार की डिलीवरी पूरे',
  delhi: 'दिल्ली',
  haryana: 'हरियाणा',
  punjab: 'पंजाब',
  rajasthan: 'राजस्थान',
  up: 'उत्तर प्रदेश',
  privacy_policy: 'गोपनीयता नीति',
  cancellation_policy: 'रद्दकरण नीति',
  commerce_policy:'वाणिज्य नीति',
  copyright: ' कॉपीराइट {{year}} मेरापशु360 प्राइवेट लिमिटेड',
  terms: 'उपयोग की शर्तें',
  media_coverage: 'मीडिया कवरेज',
  force_behind: 'मेरापशु360 के पीछे की ताकत',
  meet_founding_team: 'संस्थापक टीम से मिलें',
  divyanshu: 'दिव्यांशु तांबे',
  divyanshu_post: 'सह-संस्थापक और मुख्य व्यवसाय अधिकारी',
  divyanshu_desc:
    'मेरापशु360 डेयरी किसानों को अपना व्यवसाय सफलतापूर्वक चालने में मदद कर रहा हैI उनकी महत्वपूर्ण और बड़ी जरूरतों के समाधान के लिए मेरापशु360 प्रत्येक स्टार्ट-अप का समर्थन करता हैI',
  niket: 'निकेत अग्रवाल',
  niket_post: 'सह-संस्थापक और सीईओ',
  niket_desc:
    'भारत के सबसे हाशिए वाले वर्ग के 30% बड़े कृषि उद्योग में मूल्य निर्माण को औपचारिक बनाने और ड्राइव करने की यात्रा परI',
  varun: 'वरुण वर्मा',
  varun_post: 'सह-संस्थापक और मुख्य तकनीकी अधिकारी (सीटीओ)',
  varun_desc:
    'उत्पाद विकास और स्टार्टअप के लिए प्रौद्योगिकी प्रचारक और सलाहकार।',
  meet_leadership_team: 'नेतृत्व टीम से मिलें',
  kanu: 'कनुप्रिया सालदी',
  kanu_post: 'सह-संस्थापक और सीओओ',
  kanu_desc:
    'प्रत्यक्ष और अप्रत्यक्ष खर्च में उपभोक्ता वस्तुओं के उद्योग में काम करने के प्रदर्शन के इतिहास के साथ क्रय निदेशक।',
  tumul: 'तुमुल सिंह',
  tumul_post: 'डायरेक्टर (एच आर)',
  tumul_desc:
    'परिणाम उन्मुख पेशेवर, विशेषज्ञ मानव संसाधन कार्मिक और प्रेरक नेता 12 से अधिक वर्षों के अनुभव के साथ।',
  amit: 'अमित अग्रवाल',
  amit_post: 'मुख्य डेटा वैज्ञानिक',
  amit_desc:
    'बड़े पैमाने पर वास्तविक दुनिया की समस्याओं को हल करने के लिए अपार जुनून के साथ बतौर एक अनुभवी लीडर।',
  prachi: 'प्राची मदान',
  prachi_post: 'लीड डिजिटल मार्केटिंग व कस्टमर सक्सेस',
  prachi_desc:
    'डिजिटल मार्केटिंग और रिटेल रणनीति में-14 साल के कार्य अनुभव के साथ एक कुशल लीडर।',
  rupish: 'रूपिश सालदी',
  rupish_post: 'प्रमुख- बिक्री और खरीद',
  rupish_desc:
    'सेल्स प्रमुख की भूमिका में "स्वस्थ पशु समृद्ध किसान" मिशन की सफलता के लिए \'मेरापशु360\' प्रोडक्ट के मजबूत वितरण नेटवर्क का निर्माण कर रहे हैंI',
  we_are_hiring: 'हम हमेशा नियुक्ति निकालते रहेंगे',
  see_job_openings: 'सभी नियुक्तियों को देखें',
  we_are_growing:
    'हम हमेशा नई और इच्छुक प्रतिभाओं के साथ बातचीत करने के लिए उत्सुक हैं। यदि आप हमारे मिशन में अपना योगदान देना चाहते हैं और हमारे डेयरी किसानों के लिए एक बेहतर इकोसिस्टम बनाने पर काम करना चाहते हैं, तो हमसे संपर्क करें।',
  join_us: 'हमसे जुड़ें',
  mail_us: 'मेल करें',
  browse_bhains: '75+ पैमानों पर जांची गयी <a>गाय/मुर्रा भैंस</a> देखें',
  new_lac: 'नयी ब्याई',
  milk_yeild: '8-16 लीटर दूध की उपज',
  checked: 'अनुभवी पशु चिकित्सक द्वारा जांची गयी',
  browse_all_bhains: 'सभी गाय/मुर्रा भैंस देखें',
  apply_filter: 'फ़िल्टर लगायें ',
  clear_filter: 'फिल्टर हटाएं',
  milk: 'दूध',
  price: 'कीमत',
  byayi: 'ब्याई',
  hub: 'हब',
  calving: 'ब्यात',
  litre: 'लीटर',
  more_than_20_litre: '20 लीटर से ज्यादा',
  day: 'दिन',
  all_cattle: 'सभी पशु',
  syc_title: 'मात्र 2 दिनों में अपना पशु बेचें',
  sycfeat1: 'तुरंत मूल्य जानें ',
  sycfeat2: 'मुफ्त गुणवत्ता मूल्यांकन',
  sycfeat3: 'पशु का पिकअप',
  syc_step_title: 'अपने पशु को 2 आसान प्रक्रिया के साथ बेचें',
  cattle_price: 'पशु मूल्य ',
  syc_step_one: 'पशु की सामान्य जानकारी दर्ज करें ',
  syc_step_two: 'अनुमानित दाम जानें ',
  syc_basic_detail: 'सामान्य जानकारी ',
  syc_type: 'पशु की बनावट',

  syc_breed: 'पशु की नस्ल',
  syc_breed_buffalo: 'भैंस',
  syc_byat: 'ब्यांत',
  syc_milk: 'तैयार दूध ',
  syc_byayi: 'कितने दिन की ब्याई',
  syc_child: 'बचड़े का लिंग',
  syc_height: 'पशु की ऊंचाई',
  syc_pincode: 'पिन कोड',
  katda: 'कटड़ा',
  katdi: 'कटड़ी',
  none: 'कोई भी नहीं',
  next: 'आगे बढ़ें',
  get_estimated_price: 'अनुमानित मूल्य जानें',
  estimated_price: 'अनुमानित मूल्य',
  enter_mobile: 'मोबाइल नंबर दर्ज करें',
  other_comments: 'अन्य जवाब',
  inch: 'इंच',
  contact_us_sub: 'संपर्क करें',
  contact_us_title: 'हम आपकी सहायता के लिए उपलब्ध हैं',
  get_query_solved: 'एक कॉल पर अपनी सभी समस्याओं का समाधान पाएं',
  feel_free: 'हमसे बेझिझक संपर्क करें',
  reach_out: 'हमसे संपर्क करें',
  view_on_map: 'गूगल मैप पर देखें',
  email: 'ईमेल',
  mobile_number: 'मोबाइल नंबर ',
  subject: 'विषय',
  message: 'संदेश ',
  submit_form: 'फॉर्म जमा करें',
  submitting: 'प्रतिक्छा करें',
  corp_office: 'हमारा कॉर्पोरेट कार्यालय',
  mp_pvt_ltd: 'मेरापशु360 प्राइवेट लिमिटेड',
  dosage:"केसे खिलाए",
  composition:"कैसे बना",
  benefits:"आहार के लाभ",
  office_add:
    'पहली मंजिल, ए आई एच पी एग्जीक्यूटिव केंद्र, प्लॉट नंबर 48, इंस्टीट्यूशनल एरिया, सेक्टर 32, गुरुग्राम, हरियाणा 122001',
  openfrom: 'खुलने का समय: सुबह 9:00 बजे से शाम 7:00 बजे तक',
  call: 'कॉल करें-  7065360360',
  see_all_bhains: 'सभी भैंस देखें',
  hero_subtitle: '24 घंटो में <span>पशु आहार की फ्री होम डिलीवरी</span>',
  hero_title: 'घर बैठे पाएं <a2>संतुलित आहार</a2> , साथ ही  <a1> दुधारू पशु </a1> उचित दाम में।',
  get_100_app_download: 'पहले ऐप ऑर्डर पर ₹100 की छूट',
  read_more: 'और पढ़ें',
  partnership_page : "पार्ट्नर्शिप",
  impact_heading:"प्रभाव",
  impact_subheading:"मेरापशु360 का प्रभाव",
  impact_text1:"15% तक ",
  impact_sub1:"किसानों की आय में<br/>अनुमानित वृद्धि ",
  impact_text2:"10 लाख+",
  impact_sub2:"किसानों से<br/>जुड़े",
  impact_text3:"30,000+",
  impact_sub3:"गांव तक<br/>पहुंच",
  impact_text4:"20%",
  impact_sub4:"महिला<br/>ग्राहक",
  faq_list:{ 
    "मेरापशु360 आहार कब तक डिलीवर किया जाएगा?":[
      "आपका पशु आहार 24 घंटो के अंदर आपके घर डिलीवर किया जायेगा। यदि 3 बजे से पहले ऑर्डर दिया जाता है तो अन्यथा 48 घंटे के अंदर डिलीवरी होगी।",
      "डिलीवरी टीम, डिलीवरी के दिन आपसे ऑर्डर की पुष्टि के लिए संपर्क करेगी।",
      "कृपया डिलवरी के दिन भुगतान राशि/UPI तैयार रखें या परिजन को सौंप दें।",
      "कृपया सुनिश्चित करें कि परिवार का कोई सदस्य घर पर मौजूद हो, ताकि हम बिना असुविधा के आपका ऑर्डर डिलीवर कर पाएं।"
    ],
    "मेरापशु360 ऐप से खल-बाट का ऑर्डर कैसे करें?":[
      "प्लेस्टोर पर जाकर मेरापशु360 ऐप डाउनलोड करें। ",
      "ऐप को खोलकर साइन-अप  करें, होम स्क्रीन पर जाकर।",
      "खल-बाँट की श्रेणी पर क्लिक करें।",
      "अपने पसंद के अनुसार पशु आहार के विभिन्न विकल्प जैसे- खल, गोली खल, चूरी छिलका, साइलेज, मिनरल  मिक्सचर किसी भी प्रोडक्ट पर क्लिक करें, - इसके बाद आप 1 या 1 से अधिक कट्टे का चयन करें।",
      "फिर नीचे दिए गए गाड़ी पर जाएं बटन को दबाएँ।",
      "इस दौरान आप छूट पाने के लिए बचे हुए कूपन या पॉइंट्स का इस्तेमाल करें।",
      "अब नीचे पता चुनें बटन को दबाकर अपना पता दर्ज करें और पुष्टि बटन को क्लिक करें।",
      "इसके बाद ऑर्डर पूरा करें बटन को दबाकर भुगतान के विकल्प: कैश ऑन डिलीवरी, ऑनलाइन भुगतान में किसी एक विकल्प को चुनें।",
      "ऑर्डर पूरा करें बटन को पुनः दबाएँ इस तरह आपका ऑर्डर सफलतापूर्वक दर्ज हो जायेगा और आपको मोबाइल स्क्रीन पर कुल आइटम और डिलीवरी की तारीख देख सकते है।"
    ],
    "मेरापशु360 से खल-बाट खरीदनें के फायदे ?":[
      "फास्ट एंड फ्री होम डिलीवरी: पशु आहार का 1 कट्टा भी पहुंचेगा आपके घर 24 घंटे में।",
      "कैश ऑन डिलीवरी: बिना किसी परेशानी, ऑर्डर घर पर डिलीवर होने के बाद नकद देकर करें भुगतान।",
      "UPI भुगतान पर छूट: ऐप से पशु आहार के ऑनलाइन भुगतान पर 100 पॉइंट्स की छूट। ",
      "पशु आहार के अनेक विकल्पों वाला एक ऐप: ऑनलाइन खल, गोली खल, पारंपरिक पशु आहार, साइलेज, मिनेरल मिश्रण की श्रेणीयां  एवं लाभ आप ऐप पर देख सकते हैं।"
    ],
    "मेरापशु360 ऐप कितने प्रकार के खल-बाट उपलब्ध हैं?":[
      "ऐप पर पोषक तत्वों से भरपूर प्रीमियम क्वालिटी के पशु आहार जैसे- खल, गोली खल, पारंपरिक पशु आहार, साइलेज, मिनेरल मिश्रण सब मिलाकर 20 से ज्यादा विकल्प उपलब्ध हैं।",
      "उचित दाम में क्वालिटी का पूरा भरोसा।",
      "लैब द्वारा प्रमाणित उच्च गुणवत्ता के खल-बाट।",
      "ताजा फैक्ट्री का बना 100% शुद्ध पशु आहार।"
    ],
    "मैं मेरापशु360 से कैसे गाय/भैंस खरीद सकता हूँ?":[
      "गाय/भैंस खरीदने के लिए आप मेरापशु360 ऐप को खोले होम स्क्रीन पर जाएं और पशु देखें विकल्प को चुनें।",
      "मौजूदा उत्तम क्वालिटी की अनेक गाय/ मुर्रा भैसों की जानकारी - उनकी ब्यात, तैयार दूध,कीमत, लाइव वीडियो, फोटो देखकर अपने पसंद की मुर्रा चुनें। ",
      "हमारे पास 40+ से अधिक उत्तम क्वालिटी की जांची गई गाय/मुर्रा भैंसों के कई विकल्प हब में उपलब्ध है।",
      "पशु देखने के लिए आप ऐप द्वारा फार्म विजिट बुक कर सकते हैं या वीडियो कॉल द्वारा पशु को देख सकते हैं। अन्य जानकरी के लिए आप हमारे प्रतिनिधि से संपर्क- 7065360360 कर सकते हैं।",
      "जांच और देखने की प्रक्रिया पूरी होने के बाद अगर आप सहमत होते हैं, तो 24 घंटो के अंदर आपके घर पशु की डिलीवरी की जाएगी|      ",
      "गया/भैंस के चयन के बाद ₹10 हजार का भुगतान डिलीवरी से पहले देना होगा बाकी का बचा भुगतान डिलीवरी के समय नकद कर सकते हैं। जो भी ग्राहक हब से 50 कि.मी. से अधिक की दूरी पर स्थित रहेंगे, उन्हें डिलीवरी से पहले पूरा भुगतान करना होगा।"
    ],
  
    "दुधारू पशु को मेरापशु360 संतुलित आहार (गोली खल) खिलाने के फायदे ?":[
      "संतुलित 8000: यह पशु के लिए संतुलित आहार हैI इससे पशु को भारी मात्रा में प्रोटीन और फैट मिलता है, जो दुधारू पशु के स्वास्थ्य और अधिक दूध उत्पादन के लिए जरूरी होता है।",
      "संतुलित 11000 एनर्जी पैलेट: ऊर्जा से भरपूर संतुलित 11000 पशुओं की ऊर्जा को बढ़ाता है। इसमें 2700 किलो कैलोरी और 70% टी.डी.एन की मात्रा होती है। 50 किलो के कट्टे में आने वाला इस न्यू संतुलित 11000 में बिनोला खल, सरसों खल, मक्का, पौष्टिक पदार्थ, टोक्सिन बाइंडर, मिनरल मिक्सचर, चोकर शामिल होते हैं।",
      "संतुलित 15000 हाई एनर्जी पैलेट: इसमें है कई बेहतरीन पोषक तत्व जिससे आपके पशुओं को मिलेगी हाई एनर्जी यानि भरपूर ऊर्जा इसमें 2900 किलो कैलोरी और 75% टी.डी.एन. मात्रा पाई जाती है। इससे पशु के दूध उत्पादन में वृद्धि होगी। उनका वजन बढ़ेगा प्रजनन और बेहतर एवं आसान बनेगा।"
    ],
    "क्या मैं मेरापशु360 फार्म पर उपलब्ध गाय/भैंसों के विकल्प देखने के लिए फार्म विजिट निर्धारित कर सकता हूँ?":[
      "मेरापशु360 के मौजूदा गुड़गांव (हरियाणा) डेयरी फार्म पर आप किसी भी समय आ सकते है।",
      "आप अपने निर्धारित समय पर हमारे प्रतिनिधि से संपर्क करके- 7065360360 घर बैठे पशु को और उसकी लाइव मिल्किंग वीडियो को देख सकते है।",
      "ऐप में पशु देखें विकल्प पर क्लिक करके पसंदीदा गाय/भैंस चुनें और हमारे प्रतिनिधि से संपर्क करके उसकी जानकारी दें, आपको सीधे फार्म से कनेक्ट किया जाएगा और वीडियो कालिंग द्वारा पशु को लाइव दिखाया जाएगा।",
      "आप ऐप पर अपने निर्धारित समय अनुसार फार्म विजिट की ऑनलाइन बुकिंग भी कर सकते हैं।"
    ]
},
our_investors: 'हमारे निवेशक',
about_our_investors: "मिशन के दौरान, हम उद्योग जगत के विश्वसनीय लोगों- संस्थापक (27), सीएक्सओ (56), व्यवस्था और कंसल्टेंसी के पार्टनर (30), वीसी (5), पीई और बिग-4 से प्रोत्साहन और समर्थन पाने के लिए भाग्यशाली रहे हैं।खुशी की बात है कि हमारे संस्थापकों (आईआईटी मद्रास) के 25 से ज्यादा कॉलेज के साथी कैप-टेबल का हिस्सा हैं, जबकि कुछ मुख्य अन्य पूर्व-सहकर्मी और कस्टमर हैं।",
investor_footer: "आप और अधिक जानने को इच्छुक है? हम आपको बिज़ अपडेट के लिए हमारी त्रैमासिक मेलिंग की सूची में जोड़ सकते हैं।",
investors_include: "हमारे निवेशकों में शामिल हैं",
celebrating: "जश्न में साथ",
featured: "चर्चा में"
};

export default _hiTranslations;
