function Terms18Dec() {
  return (
    <div className="terms--root">
      <h1 className="template-page--heading" data-item="section-heading">
        Free Pashu Aahar - Terms and Conditions
      </h1>

      <div
        data-item="rte-content"
        style={{
          marginTop: '2rem',
        }}
      >
        {/* <p>
          <strong>A. INTRODUCTION</strong>
        </p> */}
        <p className="hindi">1. यह प्रतियोगिता 31 दिसंबर तक मान्य रहेगी।</p>
        <p className="hindi">2. एक नंबर से एक ही मिस्ड कॉल गिना जाएगा।</p>
        <p className="hindi">
          3. मैसेज प्राप्त होने के 24 घंटे के अंदर होने वाले ऐप डाउनलोड को मान्य
          माना जाएगाI
        </p>
        <p className="hindi">
          4. मिस्ड कॉल देकर आप हमें सहमति देते हैं कि हम आपको किसी भी माध्यम से
          संपर्क कर सकते हैं।
        </p>
        <p className="hindi">
          5. इस प्रतियोगिता के अंतर्गत लकी ड्रा द्वारा चुने गए 10 भाग्यशाली
          विजेताओं को ही चुना जाएगा।
        </p>
        <p className="hindi">6. हर हफ्ते विजेताओं को मुफ्त उपहार दिए जाएंगे।</p>
        <p className="hindi">
          7. हर लकी विजेता को अपने 1 पशु के लिए, महीने भर का ₹10 हजार तक पशु
          आहार मुफ्त मिलगाI
        </p>
        <p className="hindi">
          8. प्रतियोगिता के नतीजें सभी डिजिटल चैनलों पर घोषित किए जाएंगे।
          विजेताओं को सम्मान के साथ उनके घर जाकर उपहार सौंपा जाएगाI
        </p>
        <p className="hindi">
          9. उपहार में मिलने वाले सभी प्रोडक्ट्स को मेरापशु360 टीम सुनिश्चित
          करेगी।
        </p>
        <p className="hindi">
          10. भेजे गए मुफ्त उपहार को किसी अन्य वस्तु, नकद या क्रेडिट के लिए बदला
          नहीं जा सकता।
        </p>
        <p className="hindi">
          11. प्रतियोगिता से संबंधित किसी भी विवादित स्थिति में 'मेरापशु360
          प्राइवेट लिमिटेड' का निर्णय अंतिम होगा।
        </p>
        <p className="hindi">
          12. यह प्रतियोगिता केवल हमारे सेवा क्षेत्र तक सीमित रहेगीI
        </p>
      </div>
    </div>
  );
}

export default Terms18Dec;
