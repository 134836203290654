import Button from './Button/Button';
import truck_icon from '../assets/delivery_truck.svg';

type FeedCardPropType = {
  id: string;
  vendor: string;
  name: string;
  defaultName: string;
  weight: any;
  mrp: any;
  discountedPrice: any;
  tags: any;
  sku: string;
  images: any;
};

function FeedCard({
  id,
  vendor,
  name,
  defaultName,
  weight,
  mrp,
  discountedPrice,
  tags,
  sku,
  images,
}: FeedCardPropType) {
  return (
    <div className="feedCard__card">
      <div className="feedCard__card--images">
        <div className="feedCard__card--image-col1">
          {images && images[0] && <img src={images[0].url} alt="sds" />}
        </div>
        <div className="feedCard__card--image-col2">
          {images && images[1] && <img src={images[1].url} alt="sds" />}
        </div>
      </div>
      <div className="seperator">
        <div className="feedCard__labels">
          <p className="feedCard__labels--sub">{vendor}</p>
          <p className="feedCard__labels--main">{name} - {weight?.value + ' ' + weight?.uom}</p>
        </div>

        <div className="feedCard__feats">
          {tags.map((feat: any, index:number) => (
            <div className="feedCard__feat" key={index}>{feat.key + ' ' + feat.value}</div>
          ))}
        </div>

        <div className="feedCard__pricing">
          <div className="feedCard__price-discounted">
            ₹{discountedPrice?.amount}{' '}
            <span className="feedCard__price-per-kg">
              {'( '}प्रति किलो: ₹
              {(discountedPrice?.amount / weight?.value).toFixed(1)}
              {' )'}
            </span>
          </div>

          <div className="feedCard__price-mrp">
            <span className="feedCard__price-mrp-line">₹{mrp?.amount}</span>
            <span className="feedCard__price-saved">
              बचाए ₹{mrp?.amount - discountedPrice?.amount}
            </span>
          </div>
        </div>
      </div>

      <div className="feedCard__footer">
        <div className="feedCard__free-delivery">
          <img src={truck_icon} alt="truck" />

          <p>निशुल्क डिलीवरी : 1 दिन के भीतर</p>
        </div>
        <div className="feedCard__cta">
          <Button
            playStore
            text="ऐप से खरीदे"
            primary
            to= "https://merapashu360.com/feed"
          ></Button>
        </div>
      </div>
    </div>
  );
}

export default FeedCard;
