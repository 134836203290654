import DownloadMP360 from './Home/sections/DownloadMP360';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';

function NotFound() {
  useEffect(() => {
    window.history.pushState({}, '', `/404`);
    // window.location.reload();
  }, []);

  return (
    <>
      {/* Added no-index meta tag to not index this page */}
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section className="notFound">
        <p>404 - PAGE NOT FOUND!</p>
      </section>
      <DownloadMP360 />
    </>
  );
}

export default NotFound;
